import React, {FC, useEffect} from 'react'
import axios from "axios";
import {LocalStorageHelper} from "../../context/LocalStorageHelper";
import {AuthClient} from "./AuthClient";
import {useAuth} from "../../context/AuthenticationContext";
import {useHistory} from "react-router-dom";
import {SIGN_IN_ROUTER_PATH} from "../../utils/path";


const WithAxiosInterceptors: FC = ({children}) => {
    const {setError} = useAuth();
    const history = useHistory();

    useEffect(() => {
        const requestTokenInterceptor = axios.interceptors.request.use((config) => {
            if (LocalStorageHelper().getToken()) {
                config.headers = {
                    ...config.headers,
                    'Authorization': `Bearer ${LocalStorageHelper().getToken()}`,
                    'Accept': 'application/json',
                }
            }
            return Promise.resolve(config)
        })
        return () => axios.interceptors.request.eject(requestTokenInterceptor)
    }, [])


    useEffect(() => {
        const responseRefreshTokenInterceptor = axios.interceptors.response.use(r => Promise.resolve(r), (axiosError) => {
            if (401 === axiosError.response.status) {
                if (LocalStorageHelper().getRefreshToken()) {
                    console.warn("Token Expired trying to refreash!")
                    LocalStorageHelper().removeAccessToken()
                    AuthClient().refreshToken().then(x => {
                        LocalStorageHelper().setToken(x.data.access_token);
                        LocalStorageHelper().setRefreshToken(x.data.refresh_token)
                        const originalReq = axiosError.config;
                        originalReq.headers = {
                            ...originalReq.headers,
                            'Authorization': `Bearer ${x.data.access_token}`,
                            'Accept': 'application/json',
                        }
                        return axios(originalReq)
                    }).catch((err) => {
                        console.log(err)
                        LocalStorageHelper().logout()
                        history.push(SIGN_IN_ROUTER_PATH)
                    })
                } else {
                    LocalStorageHelper().logout()
                    history.push(SIGN_IN_ROUTER_PATH)
                }
            }
            return Promise.reject(axiosError)
        })
        return () => axios.interceptors.request.eject(responseRefreshTokenInterceptor)
    }, [history])


    useEffect(() => {
        const responseUnauthorizedInterceptor = axios.interceptors.response.use(r => Promise.resolve(r), (axiosError) => {
            if (403 === axiosError.response.status) {
                LocalStorageHelper().logout()
                setError(axiosError.response.data.message)
                history.push(SIGN_IN_ROUTER_PATH)
            }
            return Promise.reject(axiosError)
        })
        return () => axios.interceptors.request.eject(responseUnauthorizedInterceptor)
    }, [history, setError])

    return <>{children}</>
}

export default WithAxiosInterceptors
