import React, {SyntheticEvent} from "react";
import {AssetType, IAsset} from "../../../models/applicationState";
import {strings} from "../../utils/strings";
import {ImageAsset} from "./imageAsset";

export interface IGenericContentSource {
    width: number;
    height: number;
    offsetWidth: number;
    offsetHeight: number;
    offsetTop: number;
    offsetLeft: number;
}

export type ContentSource = HTMLImageElement | HTMLVideoElement | IGenericContentSource;

/**
 * AssetPreview component properties
 */
export interface IAssetProps {
    /** The Asset to preview */
    asset: IAsset;
    /** Set image view */
    thumbnailView: boolean;
    /** Specifies whether the asset controls are enabled */
    controlsEnabled?: boolean;
    /** Event handler that fires when the asset has been loaded */
    onLoaded?: (ContentSource: ContentSource) => void;
    /** Event handler that fires when the asset has been activated (ex. Video resumes playing) */
    onActivated?: (contentSource: ContentSource) => void;
    /** Event handler that fires when the asset has been deactivated (ex. Canvas tools takes over) */
    onDeactivated?: (contentSource: ContentSource) => void;
    /** Event handler that fires when an error occurred loading an asset */
    onError?: (event: React.SyntheticEvent) => void;
    /** Event handler that fires when the loaded asset has changed */
    onAssetChanged?: (asset: IAsset) => void;
    /** Event handler that fires right before an asset has changed */
    onBeforeAssetChanged?: () => boolean;
}

/**
 * Properties for Asset Preview
 * @member asset - Asset for preview
 */
export interface IAssetPreviewProps extends IAssetProps, React.Props<AssetPreview> {
    autoPlay?: boolean;
    agentId?: number;
}

/**
 * State for Asset Preview
 * @member loaded - Asset is loaded
 */
export interface IAssetPreviewState {
    loaded: boolean;
    hasError: boolean;
}

export const agentsWithPreventResize = [991, 1156];

// /**
//  * Settings used by the various asset previews
//  * @member videoSettings - Video settings for this asset
//  */
// export interface IAssetPreviewSettings {
//     videoSettings: IProjectVideoSettings;
// }

/**
 * @name - Asset Preview
 * @description - Small preview of assets for selection in editor page
 */
export class AssetPreview extends React.Component<IAssetPreviewProps, IAssetPreviewState> {
    // /** Default properties for component if not defined */
    // public static defaultProps: IAssetPreviewProps = {
    //     asset: null,
    //     childAssets: [],
    //     autoPlay: false,
    //     controlsEnabled: true,
    // };

    /** The internal state for the component */
    public state: IAssetPreviewState = {
        loaded: false,
        hasError: false,
    };

    public componentDidUpdate(prevProps: Readonly<IAssetPreviewProps>) {
        if (this.props.asset.id !== prevProps.asset.id) {
            this.setState({
                loaded: false,
                hasError: false,
            });

            if (this.props.onAssetChanged) {
                this.props.onAssetChanged(this.props.asset);
            }
        }
    }

    public render() {
        const {loaded, hasError} = this.state;
        const size = this.props.asset.size;
        const classNames = ["asset-preview"];
        const alignNames = ['asset-preview-container']
        if (size) {
            if (size.width > size.height) {
                classNames.push("landscape");
                if (agentsWithPreventResize.includes(Number(this.props.agentId)) && !this.props.thumbnailView) {
                    alignNames.push("align-center-horizontal");
                }
            } else {
                classNames.push("portrait");
                if (agentsWithPreventResize.includes(Number(this.props.agentId)) && !this.props.thumbnailView) {
                    alignNames.push("align-center-vertical");
                }
            }
        }

        return (
            <div className={classNames.join(" ")} style={{height: "100%"}}>
                <div className={alignNames.join(" ")}>
                    {!loaded &&
                    <div className="asset-loading">
                        <div className="asset-loading-spinner">
                            <i className="fas fa-circle-notch fa-spin"/>
                        </div>
                    </div>
                    }
                    {hasError &&
                    <div className="asset-error text-danger">
                        <i className="fas fa-2x fa-exclamation-circle"/>
                        <p className="m-2">{strings.editorPage.assetError}</p>
                    </div>
                    }
                    {!hasError &&
                    this.renderAsset()
                    }
                </div>
            </div>
        );
    }

    private renderAsset = () => {
        const {asset} = this.props;
        const rootAsset = asset.parent || asset;

        if (asset.type === AssetType.Image) {
            return <ImageAsset asset={rootAsset}
                               agentId={this.props.agentId}
                               thumbnailView={this.props.thumbnailView}
                               onLoaded={this.onAssetLoad}
                               onError={this.onError}
                               onActivated={this.props.onActivated}
                               onDeactivated={this.props.onDeactivated}/>;
        } else {
            return <div className="asset-error">{strings.editorPage.assetError}</div>;
        }
    }

    /**
     * Internal event handler for when the referenced asset has been loaded
     * @param contentSource The visual HTML element of the asset (img/video tag)
     */
    private onAssetLoad = (contentSource: ContentSource) => {
        this.setState({
            loaded: true,
        }, () => {
            if (this.props.onLoaded) {
                this.props.onLoaded(contentSource);
            }
        });
    }

    private onError = (e: SyntheticEvent) => {
        this.setState({
            hasError: true,
            loaded: true,
        }, () => {
            if (this.props.onError) {
                this.props.onError(e);
            }
        });
    }
}
