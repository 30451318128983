import React, {createContext, FC, useContext, useEffect, useState} from 'react';
import {LocalStorageHelper} from "./LocalStorageHelper";

export type AuthenticationContextState = {
    isAuthenticated: boolean
    setAuthenticated: (isAuthenticated: boolean) => void
    error: string
    setError: (error: string) => void
    clearError: () => void
}
const AuthenticationContext = createContext<AuthenticationContextState | null>(null);

const AuthenticationProvider: FC = ({children}) => {
    const [isAuthenticated, setAuthenticated] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        const accessToken: string | null = LocalStorageHelper().getToken();
        if (accessToken != null) {
            setAuthenticated(true);
        }
    }, []);

    const clearError = () => {
        setError('')
    }

    return <AuthenticationContext.Provider value={{
        isAuthenticated,
        setAuthenticated,
        error,
        setError,
        clearError
    }}>{children}</AuthenticationContext.Provider>
}

export const useAuth = () => {
    const data = useContext(AuthenticationContext);

    if (data === null) {
        throw new Error('Before use `useAuth` need add `AuthenticationProvider`')
    }

    return data;
}

export default AuthenticationProvider;
