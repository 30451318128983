import {LocalStorageHelper} from "../context/LocalStorageHelper";

export const ROLE_ADMIN = "ROLE_ADMIN"
export const ROLE_USER = "ROLE_USER"

export const isAdmin = (): boolean => {
    return LocalStorageHelper().getRole() === ROLE_ADMIN;
}
export const isUser = (): boolean => {
    return LocalStorageHelper().getRole()=== ROLE_USER || LocalStorageHelper().getRole() === ROLE_ADMIN
}
