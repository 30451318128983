import {GET_USER_DATA_REST_PATH, REFRESH_TOKEN_REST_PATH, SIGN_IN_REST_PATH} from "../../utils/path";
import {LoginUserData} from "../_dto/LoginUserData";
import axios, {AxiosResponse} from "axios";
import {LocalStorageHelper} from "../../context/LocalStorageHelper";

export const AuthClient = () => {

    const getUserData = (): Promise<Response> => {
        return fetch(`${GET_USER_DATA_REST_PATH}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        });
    };
    const loginUser = (userData: LoginUserData): Promise<AxiosResponse> => {
        userData.system = 'trainer';
        return axios.post(`https://auth.carscanner.dev${SIGN_IN_REST_PATH}`, userData, {})
    };
    const refreshToken = (): Promise<AxiosResponse> => {
        LocalStorageHelper().removeAccessToken();
        const  data = {"refresh_token" : String(LocalStorageHelper().getRefreshToken())}
        console.log(data)
        return axios.post(`https://auth.carscanner.dev${REFRESH_TOKEN_REST_PATH}`,data,
            {} )
    }

    return {
        getUserData,
        loginUser,
        refreshToken
    }
}
