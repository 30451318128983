import {
    GET_CREATE_USER_REST_PATH,
    GET_DELETE_USER_REST_PATH,
    GET_EDIT_USER_REST_PATH,
    GET_USERS_REST_PATH
} from "../../utils/path";
import axios, {AxiosResponse} from "axios";

export const UserManagerClient = () => {

    const getUsers = (): Promise<AxiosResponse> => {
        return axios.get(`${GET_USERS_REST_PATH}`)
    }

    const createUser = (newUser: any): Promise<AxiosResponse> => {
        return axios.post(`${GET_CREATE_USER_REST_PATH}`, newUser)
    }

    const deleteUser = (username: string): Promise<AxiosResponse> => {
        return axios.delete(`${GET_DELETE_USER_REST_PATH}${username}/`)
    }
    const editUser = (editUser: any): Promise<AxiosResponse> => {
        return axios.patch(`${GET_EDIT_USER_REST_PATH}`, editUser)
    }

    return {
        getUsers,
        createUser,
        deleteUser,
        editUser
    }
}
