import {Checkbox, FormControlLabel, TablePagination} from "@material-ui/core";
import React from "react";
import {ImageData} from "../../common/_dto/ImageData";
import {ImageCardMark} from "./ImageCardMark";
import {TagInput} from "../../common/tagInput/tagInput";
import {ITag} from "../../../models/applicationState";
import {KeyboardBinding} from "../../common/keyboardBinding/keyboardBinding";
import {strings} from "../../utils/strings";
import {KeyEventType} from "../../common/keyboardBinding/keyboardManager";

export interface IMarkProps {
    checked: boolean,
    count: number,
    page: number,
    rowsPerPage: number,
    data: ImageData[],
    tags: ITag[]
    selectedTag: ITag

    handleCheckedChange(): void

    handlePageChange(page: number): void

    handleRowsPerPageChange(rowsPerPage: number): void

    handleDeleteImage(imageId: number): void

    handleMarkImage(imageId: number): void

    handleSelectedTagChange(tag: ITag): void

    handleTagHotKey(evt: KeyboardEvent): void
}

const Mark = (props: IMarkProps): JSX.Element => {
    return (
        <div className="wrapper">
            {[...Array(10).keys()].map((index) => {
                return (<KeyboardBinding
                    displayName={strings.editorPage.tags.hotKey.apply}
                    key={index}
                    keyEventType={KeyEventType.KeyDown}
                    accelerators={[`${index}`]}
                    icon={"fa-tag"}
                    handler={props.handleTagHotKey}/>);
            })}
            <div className="configuration-list">
                <h3>Configuration:</h3>
                <FormControlLabel
                    control={<Checkbox
                        checked={props.checked}
                        onChange={props.handleCheckedChange}
                        inputProps={{'aria-label': 'primary checkbox'}}
                    />}
                    label="Marked"
                />
                <TagInput tags={props.tags} onTagClick={props.handleSelectedTagChange} alwaysHandleClick={true}/>
            </div>
            <div className="content-list">
                <TablePagination
                    className="paginationBar"
                    component="div"
                    count={props.count}
                    page={props.page}
                    onChangePage={(event, page) => props.handlePageChange(page)}
                    rowsPerPage={props.rowsPerPage}
                    onChangeRowsPerPage={event => props.handleRowsPerPageChange(Number(event.target.value))}
                />
                <div className="imageList">
                    {props.data &&
                    props.data.map((image, index) =>
                        <ImageCardMark
                            key={index}
                            image={image}
                            index={index}
                            tags={props.tags}
                            selectedTag={props.selectedTag}
                            markAsChecked={props.handleMarkImage}
                            deleteImage={props.handleDeleteImage}
                        />)
                    }
                </div>
            </div>
        </div>
    );
}

export default Mark;