import {usePromiseTracker} from "react-promise-tracker";
import React from "react";
import Loader from 'react-loader-spinner';

interface ILoadingIndicatorProps {
    text: string,
    trackPromise: boolean
}

const LoadingIndicator = (props: ILoadingIndicatorProps) => {
    const {promiseInProgress} = usePromiseTracker();
    return (
        (!props.trackPromise || promiseInProgress) &&
        <div className="text-primary" style={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 10,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.7)"
        }}>
            <Loader type="Bars" height="50" width="100" color="#007bff"/>
            {props.text}
        </div>
    );
}

export default LoadingIndicator;