import React, {useEffect, useState} from "react";
import {createStyles, Divider, IconButton, ListItemIcon, makeStyles, Theme} from "@material-ui/core";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import {SystemClient} from "../../common/_clients/SystemClient";
import {AgentTreeDto, ObjectDto, SystemTreeDto} from "../../common/_dto/EditorTreeDto";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {confirmAlert, ReactConfirmAlertProps} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {ObjectClient} from "../../common/_clients/ObjectClient";
import {CreateObjectDialog} from "./CreateObjectDialog";
import {EditObjectDialog} from "./EditObjectDialog";
import {CreateAgentDialog} from "./CreateAgentDialot";
import {AiAgent} from "../../common/_dto/AiAgent";
import {AIAgentsClient} from "../../common/_clients/AIAgentsClient";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            background: theme.palette.background.paper
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
        nestedX2: {
            paddingLeft: theme.spacing(8),
        },
        wrapper: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(2),
                width: "100%",
                border: "1px solid grey"
            }
        },
        paper: {}
    }))
export const ObjectsPage = () => {
    const classes = useStyles();
    const [systemTreeDto, setSystemTreeDto] = useState<SystemTreeDto[] | undefined>(undefined)
    const [state, setState] = useState([]);
    const [openEditObjectDialogState, setOpenEditObjectDialogState] = useState(false)
    const [openCreateObjectDialogState, setOpenCreateObjectDialogState] = useState(false)
    const [openCreateAgentDialogsState, setOpenCreateAgentDialogState] = useState(false)
    const [editedObject, setEditedObject] = useState<ObjectDto | undefined>(undefined)
    const [editedAgent, setEditedAgent] = useState<AgentTreeDto | undefined>(undefined)
    const [editedSystem, setEditedSystem] = useState<SystemTreeDto | undefined>(undefined)

    useEffect(() => {
        getSystemTree()
    }, [])

    const getSystemTree = async () => {
        await SystemClient().getSystemEditorTree().then(x => {
            setSystemTreeDto(x.data);
        })
    }
    useEffect(() => {
        if (openEditObjectDialogState === false && openCreateObjectDialogState === false
        && openCreateAgentDialogsState === false) {
            setEditedAgent(undefined);
            setEditedObject(undefined);
            setEditedSystem(undefined);
        }
    }, [openEditObjectDialogState, openCreateObjectDialogState, openCreateAgentDialogsState])
    const handleClick = (e) => {
        let markers = [...state];
        markers[e] = !markers[e];
        setState(markers);
    };
    const openConfirmAlert = (data: ReactConfirmAlertProps) => {
        confirmAlert({
            title: 'Confirm delete',
            message: data.message,
            buttons: data.buttons
        });
    };
    const openEditObjectDialog = (data: ObjectDto) => {
        setEditedObject(data);
        setOpenEditObjectDialogState(true);
    }
    const openCreateObjectDialog = (agent) => {
        setEditedAgent(agent);
        setOpenCreateObjectDialogState(true);
    }

    function openCreateAgentDialog(system:SystemTreeDto) {
        setEditedSystem(system);
        setOpenCreateAgentDialogState(true);
    }
    const deleteObject = (object: ObjectDto) => {
        const options = {
            message: 'Are you sure to delete ' + object.name + '?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteObjectRest(object)
                },
                {
                    label: 'No',
                    onClick: () => {
                    }

                }
            ]
        } as ReactConfirmAlertProps
        openConfirmAlert(options);
    }
    const deleteObjectRest = (object: ObjectDto) => {
        ObjectClient().deleteObject(object.id).then(x => {
            if (x) {
                const tmp = systemTreeDto;
                for (let system of tmp) {
                    for (let agent of system.agents) {
                        agent.objects = agent.objects.filter(obj => obj.id !== object.id)
                    }
                }
                setSystemTreeDto([...tmp])
            }
        })
    }
    const createObject = (object: ObjectDto, agentId: number) => {
        ObjectClient().createObject(object, agentId).then(x => {
            if (x) {
                const tmp = systemTreeDto;
                for (let system of tmp) {
                    for (let agent of system.agents) {
                        if (agent.id === agentId) {
                            agent.objects.push(x.data)
                        }
                    }
                }
                setSystemTreeDto([...tmp])
                setOpenCreateObjectDialogState(false)
            }
        })
    }
    const editObject = (object: ObjectDto) => {
        console.log(object)
        setOpenEditObjectDialogState(false)
        ObjectClient().editObject(object).then(x => {
            const tmp = systemTreeDto;
            for (let system of tmp) {
                for (let agent of system.agents) {
                    let index = agent.objects.findIndex(obj => obj.id === object.id)
                    agent.objects[index] = x.data;
                }
            }
            setSystemTreeDto([...tmp])
        })
    }
    const createAgent = (agent:AiAgent, systemName:string) => {
        setOpenCreateAgentDialogState(false)
        AIAgentsClient().createAgent(agent, systemName).then(x => {
            getSystemTree();
        })
    }


    return (<div className={classes.wrapper}>
        {systemTreeDto &&
        systemTreeDto.map(system => {
            return (
                <List
                    className={classes.root}
                    key={system.name}
                    subheader={
                        <ListSubheader><i className="fal fa-network-wired"/>System: {system.name}</ListSubheader>
                    }
                >
                    {system.agents.map(agent => {
                        return (
                            <div key={agent.id}>
                                {(
                                    <div key={agent.id}>
                                        <ListItem
                                            button
                                            className={
                                                classes.nested
                                            }
                                            key={agent.id}
                                            onClick={handleClick.bind(
                                                this,
                                                agent.id
                                            )}
                                        >
                                            <ListItemIcon><i className="fal fa-server"/></ListItemIcon>
                                            <ListItemText
                                                primary={"Agent: " + agent.name}
                                                secondary={"Type: " + agent.agentType}
                                            />
                                            {state[agent.id] ? (
                                                <FontAwesomeIcon icon={["fas", "chevron-up"]}/>
                                                // <i className="fas fa-chevron-up"/>
                                            ) : (
                                                <FontAwesomeIcon icon={["fas", "chevron-down"]}/>
                                            )}
                                        </ListItem>
                                        <Collapse
                                            key={agent.id + "agent"}
                                            component="li"
                                            in={state[agent.id]}
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            <List disablePadding>
                                                {agent.objects.map(
                                                    object => {
                                                        return (
                                                            <ListItem
                                                                key={
                                                                    object.id + "obj"
                                                                }
                                                                className={
                                                                    classes.nestedX2
                                                                }
                                                            ><ListItemIcon style={{color: object.color}}>
                                                                <i className="fal fa-object-ungroup"/>
                                                            </ListItemIcon>
                                                                <ListItemText
                                                                    key={
                                                                        object.id + "obj"
                                                                    }
                                                                >
                                                                    {object.name}
                                                                    <IconButton onClick={() => deleteObject(object)}>
                                                                        <i className="fal fa-trash-alt"/>
                                                                    </IconButton>
                                                                    <IconButton
                                                                        onClick={() => openEditObjectDialog(object)}>
                                                                        <i className="fal fa-edit"/>
                                                                    </IconButton>
                                                                </ListItemText>
                                                            </ListItem>
                                                        );
                                                    }
                                                )}
                                                <ListItem
                                                    button
                                                    onClick={() => openCreateObjectDialog(agent)}
                                                    key={
                                                        "createObj"
                                                    }
                                                    className={
                                                        classes.nestedX2
                                                    }
                                                ><ListItemIcon>
                                                    <i className="fal fa-plus"/>
                                                </ListItemIcon>
                                                    <ListItemText
                                                        key={
                                                            "createObj"
                                                        }
                                                    >
                                                        Create new object!
                                                    </ListItemText>
                                                </ListItem>
                                            </List>
                                        </Collapse>{" "}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    <Divider key={system.id} absolute/>
                    <ListItem
                        button
                        onClick={() => openCreateAgentDialog(system)}
                        key={
                            "createAgent"
                        }
                        className={
                            classes.nested
                        }
                    ><ListItemIcon>
                        <i className="fal fa-plus"/>
                    </ListItemIcon>
                        <ListItemText
                            key={
                                "createObj"
                            }
                        >
                            Create new agent!
                        </ListItemText>
                    </ListItem>
                </List>
            );
        })}
        <EditObjectDialog
            open={openEditObjectDialogState}
            setOpen={setOpenEditObjectDialogState}
            handleClose={editObject}
            object={editedObject}/>
        <CreateObjectDialog
            open={openCreateObjectDialogState}
            setOpen={setOpenCreateObjectDialogState}
            handleClose={createObject}
            agent={editedAgent}/>
        <CreateAgentDialog
            open={openCreateAgentDialogsState}
            setOpen={setOpenCreateAgentDialogState}
            handleClose={createAgent}
            system={editedSystem}/>
    </div>);
}
