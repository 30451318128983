import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import "./MoveImagesDialog.scss";
import AgentStore from "../../../common/agentStore";

const MoveImagesDialog = (props) => {
    const { title, subtitle, open, setOpen, onConfirm } = props;
    const agents = AgentStore.getObjects();
    const [selectedAgents, setSelectedAgents] = useState([]);

    const handleCheckboxChange = (agentId) => {
        setSelectedAgents((prevSelected) =>
            prevSelected.includes(agentId)
                ? prevSelected.filter((id) => id !== agentId)
                : [...prevSelected, agentId]
        );
    };

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog"
            className="confirmDialog"
        >
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <p className="subtitle">{subtitle}</p>
            <DialogContent>
                <div className="agents-list">
                    {agents.map((agent) => (
                        <FormControlLabel
                            key={agent.id}
                            control={
                                <Checkbox
                                    checked={selectedAgents.includes(agent.id)}
                                    onChange={() => handleCheckboxChange(agent.id)}
                                    color="primary"
                                />
                            }
                            label={agent.name}
                        />
                    ))}
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={() => setOpen(false)}
                    color="primary"
                >
                    Cancel
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => {
                        setOpen(false);
                        onConfirm(selectedAgents);
                    }}
                    color="primary"
                >
                    Propagate
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MoveImagesDialog;
