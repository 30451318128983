import {Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, TextField} from "@material-ui/core";
import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import {SystemTreeDto} from "../../common/_dto/EditorTreeDto";
import './EditObjectDialog.scss'
import {AgentType} from "../../common/_dto/AgentType";
import {AiAgent} from "../../common/_dto/AiAgent";

interface CreateAgentDialogProps {
    open: boolean,
    setOpen,
    handleClose,
    system: SystemTreeDto
}

export const CreateAgentDialog = (props: CreateAgentDialogProps) => {
    const [formState, setFormState] = useState({
        name: '',
        type: AgentType.BOUNDING_BOX_RECTANGLE
    } as AiAgent);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setFormState(formState => ({
            ...formState,
            [event.target.id]: event.target.value
        }));
    };
    const handleSelectChange = (event) => {
        setFormState(formState => ({
            ...formState,
            type: event.target.value
        }));
    };
    return (<Dialog open={props.open} onClose={() => props.setOpen(false)}>
        <div>
            <DialogTitle id="form-dialog-title">Create Agent</DialogTitle>
            <DialogContent>
                Paste data and save.
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    value={formState?.name}
                    type="text"
                    onChange={handleChange}
                    fullWidth
                />
                <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formState?.type}
                    onChange={handleSelectChange}
                >
                    <MenuItem value={AgentType.BOUNDING_BOX_RECTANGLE}>BOUNDING_BOX_RECTANGLE</MenuItem>
                    <MenuItem value={AgentType.BOUNDING_BOX_POLYGON}>BOUNDING_BOX_POLYGON</MenuItem>
                    <MenuItem value={AgentType.MARKER}>MARKER</MenuItem>
                    <MenuItem value={AgentType.REMOVE_BG}>REMOVE_BG</MenuItem>
                    <MenuItem value={AgentType.TEXT}>TEXT</MenuItem>
                </Select>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setOpen(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => props.handleClose(formState, props.system.name)} color="primary">
                    Save
                </Button>
            </DialogActions>
        </div>
    </Dialog>)
}
