import React, {useEffect, useState} from "react"
import {AIAgentsClient} from "../../common/_clients/AIAgentsClient";
import {createStyles, Divider, IconButton, ListItemIcon, makeStyles, Theme} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ListSubheader from "@material-ui/core/ListSubheader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {copyToClipboard} from "../../utils/utils";
import ConfirmDialog from "../../common/confirmDialog/ConfirmDialog";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            background: theme.palette.background.paper
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
        nestedX2: {
            paddingLeft: theme.spacing(8),
        },
        wrapper: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(2),
                width: "100%",
                border: "1px solid grey"
            }
        },
        paper: {}
    }))
export const FilesPage = () => {
    const [agents, setAgents] = React.useState<any>([])
    const [state, setState] = useState([]);
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
    const [deleteObject, setDeleteObject] = useState<any>({});
    const classes = useStyles();

    const handleClick = (e) => {
        let files = [...state];
        files[e] = !files[e];
        setState(files);
    };

    useEffect(() => {
        AIAgentsClient().getAgentsFiles().then(x => {
            console.log(x.data)
            setAgents(x.data)
        })
    }, [])

    function deleteFile(id: number, filename: string) {
        AIAgentsClient().deleteAgentFile(id, filename).then(x => {
            setAgents(agents.map(x => {
                if (x.id === id) {
                    return {
                        ...x, files: x.files.filter(y => y.filename != filename)
                    }
                }
                return x;
            }))
        })
    }

    return (<div>
        <List
            className={classes.root}
            key="files"
            subheader={
                <ListSubheader><i className="fal fa-network-wired"/>Files!</ListSubheader>
            }
        >
            {agents.map(agent => {
                return (
                    <div key={agent.id}>
                        {(
                            <div key={agent.id}>
                                <ListItem
                                    button
                                    className={
                                        classes.nested
                                    }
                                    key={agent.id}
                                    onClick={handleClick.bind(
                                        this,
                                        agent.id
                                    )}
                                >
                                    <ListItemIcon><i className="fal fa-server"/></ListItemIcon>
                                    <ListItemText
                                        primary={"Agent: " + agent.name}
                                        secondary={"Type: " + agent.type}
                                    />
                                    {state[agent.id] ? (
                                        <FontAwesomeIcon icon={["fas", "chevron-up"]}/>
                                        // <i className="fas fa-chevron-up"/>
                                    ) : (
                                        <FontAwesomeIcon icon={["fas", "chevron-down"]}/>
                                    )}
                                </ListItem>
                                <Collapse
                                    key={agent.id + "agent"}
                                    component="li"
                                    in={state[agent.id]}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <List disablePadding>
                                        {agent.files.map(
                                            file => {
                                                return (
                                                    <ListItem
                                                        key={
                                                            file.fullPath + "obj"
                                                        }
                                                        className={
                                                            classes.nestedX2
                                                        }
                                                    ><ListItemIcon>
                                                        <i className="fal fa-file-alt"/>
                                                    </ListItemIcon>
                                                        <ListItemText
                                                            key={
                                                                file.fullPath + "obj1"
                                                            }
                                                        >
                                                            {file.filename}
                                                        </ListItemText>
                                                        <ListItemText
                                                            key={
                                                                file.fullPath + "obj2"
                                                            }
                                                        >
                                                            FullPath
                                                            <IconButton onClick={() => copyToClipboard(file.fullPath)}>
                                                                <i className="fal fa-copy"/>
                                                            </IconButton>
                                                        </ListItemText>
                                                        <ListItemText
                                                            key={
                                                                file.fullPath + "obj3"
                                                            }
                                                        >
                                                            FileUrl
                                                            <IconButton onClick={() => copyToClipboard(file.url)}>
                                                                <i className="fal fa-copy"/>
                                                            </IconButton>
                                                        </ListItemText>
                                                        <ListItemIcon>
                                                            <IconButton
                                                                onClick={() => {
                                                                    setDeleteObject({
                                                                        id: agent.id,
                                                                        filename: file.filename
                                                                    })
                                                                    setDeleteDialog(true)
                                                                }}>
                                                                <i className="fal fa-trash"/>
                                                            </IconButton>
                                                        </ListItemIcon>
                                                    </ListItem>
                                                );
                                            }
                                        )}
                                    </List>
                                </Collapse>{" "}
                            </div>
                        )}
                    </div>
                );
            })
            }
            <Divider key="files" absolute/>
        </List>
        <ConfirmDialog
            title="Confirm delete"
            open={deleteDialog}
            setOpen={bool => setDeleteDialog(bool)}
            onConfirm={() => deleteFile(deleteObject.id, deleteObject.filename)}
        >
            <h4>Are you sure you want to delete {deleteObject.filename} file?</h4>
        </ConfirmDialog>
    </div>)
}