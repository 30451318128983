import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import "./DeleteDialog.scss"

const DeleteDialog = (props) => {
    const {title, children, open, setOpen, onConfirm} = props;
    const [checked, setChecked] = React.useState(false);

    useEffect(() => {
        if (open) {
            setChecked(false);
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog"
            className="confirmDialog"
        >
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent>
                {children}
                <div className="deleteAllContainer">
                    <input onChange={event => {
                        // console.log(this.checked)
                        console.log(checked)
                        console.log(event.target.checked)
                        setChecked(event.target.checked)
                    }} type={'checkbox'}/>
                    <p>Delete all with same parentUUID</p>
                </div>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={() => setOpen(false)}
                    color="primary"
                >
                    No
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => {
                        setOpen(false);
                        onConfirm(checked);
                    }}
                    color="primary"
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default DeleteDialog;
