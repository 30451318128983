import './App.css';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import NavigationBar from "./components/navigationBar/NavigationBar";
import {RemoveBG} from "./components/pages/removeBG/RemoveBG";
import Login from "./components/pages/login/Login";
import React from "react";
import AuthenticationProvider from './components/context/AuthenticationContext';
import EditorPage from "./components/pages/editorPage/EditorPage";
import Scan from "./components/pages/scan/Scan";
import {Home} from "./components/pages/home/Home";
import {ProtectedRoute} from './components/common/ProtectedRoute';
import {isAdmin, isUser} from "./components/utils/userRoleUtils";
import {KeyboardManager} from "./components/common/keyboardBinding/keyboardManager";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import {ObjectsPage} from "./components/pages/objects/ObjectsPage";
import LoadingProvider from './components/context/LoadingContext';
import {SystemManager} from "./components/pages/adminPanel/SystemManager";
import MarkController from "./components/pages/mark/MarkController";
import Mark from "./components/pages/mark/Mark";
import WithAxiosInterceptors from "./components/common/_clients/WithAxiosInterceptors";
import {FilesPage} from "./components/pages/filesPage/FilesPage";
import UserManagerPage from "./components/pages/userManager/UserManagerPage";
import TextPage from "./components/pages/text/textPage";


function assertAlive(decoded: any) {
    const now = Date.now().valueOf() / 1000;
    return !(typeof decoded.exp !== 'undefined' && decoded.exp < now);

}

function App() {
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#1a74ea'
            },
            secondary: {
                main: '#ffffff'
            }
        }
    });

    return (
        <Router>
            <MuiThemeProvider theme={theme}>
                <AuthenticationProvider>
                    <LoadingProvider>
                        <WithAxiosInterceptors>
                            <KeyboardManager>
                                <NavigationBar>
                                    <Route exact path={["/home", "/"]} component={Home}/>
                                    <ProtectedRoute path="/systemManager" component={SystemManager}
                                                    hasPermissions={isAdmin}/>
                                    <ProtectedRoute path="/files" component={FilesPage}
                                                    hasPermissions={isAdmin}/>
                                    <ProtectedRoute path="/objects" component={ObjectsPage} hasPermissions={isAdmin}/>
                                    <ProtectedRoute path="/userManager" component={UserManagerPage} hasPermissions={isAdmin}/>
                                    <ProtectedRoute path="/removeBG/:agentId" component={RemoveBG}
                                                    hasPermissions={isUser || isAdmin}/>
                                    <ProtectedRoute path="/boundingBox/:agentId" component={EditorPage}
                                                    hasPermissions={isUser || isAdmin}/>
                                    <ProtectedRoute path="/mark/:agentId" hasPermissions={isUser || isAdmin}>
                                        <MarkController render={(props => Mark(props))}/>
                                    </ProtectedRoute>
                                    <ProtectedRoute path="/text/:agentId" component={TextPage}
                                                    hasPermissions={isUser || isAdmin}/>
                                    <Route exact path="/scan" component={Scan}/>
                                    <Route exact path="/auth/login" component={Login}/>
                                </NavigationBar>
                            </KeyboardManager>
                        </WithAxiosInterceptors>
                    </LoadingProvider>
                </AuthenticationProvider>
            </MuiThemeProvider>
        </Router>
    );
}

export default App;
