import {AiAgentCounter} from "../components/common/_dto/AiAgentCounter";

export default class AgentStore {
    private static STORAGE_KEY = 'agentsList';

    public static saveObject(item: AiAgentCounter): void {
        const currentData = this.getObjects();
        currentData.push(item);
        localStorage.setItem(this.STORAGE_KEY, JSON.stringify(currentData));
    }

    public static saveObjects(items: AiAgentCounter[]): void {
        localStorage.setItem(this.STORAGE_KEY, JSON.stringify(items));
    }

    public static getObjects(): AiAgentCounter[] {
        const storedData = localStorage.getItem(this.STORAGE_KEY);
        return storedData ? JSON.parse(storedData) : [];
    }

    public static clearStorage(): void {
        localStorage.removeItem(this.STORAGE_KEY);
    }
}
