import {Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import './objectDialog.scss'
import {SystemDto} from "../../common/_dto/SystemDto";

interface CreateSystemDialogProps {
    open: boolean,
    setOpen,
    handleClose
}

export const CreateSystemDialog = (props: CreateSystemDialogProps) => {
    const [formState, setFormState] = useState({
        name: '',
        restNotify: '',
    } as SystemDto);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setFormState(formState => ({
            ...formState,
            [event.target.id]: event.target.value
        }));
    };
    return (<Dialog open={props.open} onClose={() => props.setOpen(false)}>
        <div>
            <DialogTitle id="form-dialog-title">Create Agent</DialogTitle>
            <DialogContent>
                Paste data and save.
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    value={formState?.name}
                    type="text"
                    onChange={handleChange}
                    fullWidth
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="restNotify"
                    label="restNotify"
                    value={formState?.restNotify}
                    type="text"
                    onChange={handleChange}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setOpen(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => props.handleClose(formState)} color="primary">
                    Save
                </Button>
            </DialogActions>
        </div>
    </Dialog>)
}
