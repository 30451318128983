import {Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import {ObjectDto} from "../../common/_dto/EditorTreeDto";
import ColorPicker from 'material-ui-color-picker'
import './EditObjectDialog.scss'

interface EditObjectDialogProps {
    open: boolean,
    setOpen,
    handleClose,
    object: ObjectDto
}

export const EditObjectDialog = (props: EditObjectDialogProps) => {
    const [formState, setFormState] = useState(undefined);
    useEffect(() => {
        setFormState(props.object)
    }, [props.object])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setFormState(formState => ({
            ...formState,
            [event.target.id]: event.target.value
        }));
    };
    return (<Dialog open={props.open} onClose={() => props.setOpen(false)}>
        {formState &&
        <div>
            <DialogTitle id="form-dialog-title">Edit Object</DialogTitle>
            <DialogContent>
                Modify data and save.
                <TextField
                    autoFocus
                    margin="dense"
                    id="id"
                    label="Id"
                    value={formState?.id}
                    type="text"
                    disabled={true}
                    fullWidth
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    value={formState?.name}
                    type="text"
                    onChange={handleChange}
                    fullWidth
                />
                <ColorPicker
                    style={{background: formState.color, cursor: "pointer"}}
                    name='color'
                    value={formState.color}
                    onChange={color => {
                        setFormState(prevState => ({
                            ...prevState,
                            color: color
                        }))
                    }
                    }
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setOpen(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => props.handleClose(formState)} color="primary">
                    Save
                </Button>
            </DialogActions>
        </div>
        }
    </Dialog>)
}
