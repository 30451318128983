import {AssetPreview} from "../../common/assetPreview/assetPreview";
import {SelectionMode} from "vott-ct/lib/js/CanvasTools/Interface/ISelectorSettings";
import {
    AssetState,
    EditorMode,
    IAsset,
    IAssetMetadata,
    IAssetText,
    IBoundingBox,
    IProject,
    IRegion,
    ISize,
    ITextAssetMetadata
} from "../../../models/applicationState";
import React from "react";
import "./textPage.scss";
import HtmlFileReader from "../../../common/htmlFileReader";
import EditorSideBar from "../editorPage/editorSideBar";
import SplitPane from "react-split-pane";
import {ToolbarItemGroup, ToolbarItemName} from "../../../registerToolbar";
import {ToolbarItem, ToolbarItemType} from "../../toolbar/toolbarItem";
import {IToolbarItemRegistration, ToolbarItemFactory} from "../../../providers/toolbar/toolbarItemFactory";
import {EditorToolbar} from "../editorPage/editorToolbar";
import {ImageClient} from "../../common/_clients/ImageClient";
import {AIAgentsClient} from "../../common/_clients/AIAgentsClient";
import {strings} from "../../utils/strings";
import LoadingIndicator from "../../common/loadingIndicator/LoadingIndicator";
import ConfirmDialog from "../../common/confirmDialog/ConfirmDialog";
import HistoryDialog from "../../common/historyDialog/HistoryDialog";
import {FilterSliders} from "../../common/sliders/sliders";
import Canvas from "../editorPage/canvas";
import {TextField} from "@material-ui/core";

export interface IEditorPageProps {
    agentId: number
}

export type Option = 'brightness' | 'contrast' | 'saturation';


export interface IEditorPageState {
    /** Boolean to indicate loading */
    isLoading: boolean;
    /** Array of assets in project */
    assets: IAssetText[];
    /** The editor mode to set for canvas tools */
    editorMode: EditorMode;
    /** The selection mode to set for canvas tools */
    selectionMode: SelectionMode;
    /** The selected asset for the primary editing experience */
    selectedAsset?: ITextAssetMetadata;
    /** Currently selected region on current asset */
    selectedRegions?: IRegion[];
    /** Most recently selected tag */
    selectedTag: string;
    /** Tags locked for region labeling */
    lockedTags: string[];
    /** Size of the asset thumbnails to display in the side bar */
    thumbnailSize: ISize;
    /**
     * Whether or not the editor is in a valid state
     * State is invalid when a region has not been tagged
     */
    isValid: boolean;
    /** Whether the show invalid region warning alert should display */
    showInvalidRegionWarning: boolean;
    /** Boolean to indicate when to open delete dialog */
    openDeleteDialog: boolean;
    openHistoryDialog: boolean;
    openUncheckSelectedDialog: boolean;
    openUncheckAllDialog: boolean;
    selectedImageLocked: boolean;
    regionsEmpty?: boolean;
    brightness: number;
    contrast: number;
    saturation: number;
}

export default class TextPage extends React.Component<IEditorPageProps, IEditorPageState> {
    public state: IEditorPageState = {
        isLoading: false,
        selectedTag: "TAG_TEST",
        lockedTags: [],
        selectionMode: SelectionMode.NONE,
        assets: [],
        editorMode: EditorMode.None,
        thumbnailSize: {width: 175, height: 155},
        isValid: true,
        showInvalidRegionWarning: false,
        openDeleteDialog: false,
        openUncheckSelectedDialog: false,
        openUncheckAllDialog: false,
        openHistoryDialog: false,
        selectedImageLocked: true,
        brightness: 100,
        contrast: 100,
        saturation: 100
    };
    public project: IProject
    private toolbarItems: IToolbarItemRegistration[] = ToolbarItemFactory.getToolbarItems();
    private lockedImages = []
    private canvasArea: HTMLCanvasElement;
    private inputTextRef: React.RefObject<HTMLDivElement> = React.createRef();

    private handleFilterChange = (id: Option, value: number) => {
        this.setState((prevState) => ({
            ...prevState,
            [id]: value
        }));
    }

    private resetFilters = () => {
        this.setState({
            brightness: 100,
            contrast: 100,
            saturation: 100
        });
    }
    private canvas: any;

    private getFiltersStyle() {
        return `brightness(${this.state.brightness}%) contrast(${this.state.contrast}%) saturate(${this.state.saturation}%)`
    }

    public async componentDidMount() {
        await this.updateProjectAssets();
        this.toggleToolBarItems();
    }

    public async componentDidUpdate(prevProps: IEditorPageProps, prevState: IEditorPageState) {
        if (this.canvasArea) {
            this.canvasArea.style.filter = this.getFiltersStyle()
        }
        // @ts-ignore
        if (this.props.match.params["agentId"] !== prevProps.match.params["agentId"])
            await this.updateProjectAssets();
    }

    public async updateProjectAssets() {
        // @ts-ignore
        const agentId = this.props.match.params["agentId"];
        await this.loadProjectAssets(agentId);
    }

    public render() {
        const {assets, selectedAsset} = this.state;
        if (!this.project || this.state.isLoading) {
            return (<LoadingIndicator text="Loading" trackPromise={false}/>);
        }
        return (
            <div className="text-page">
                {selectedAsset && <HistoryDialog
                    title="Actions history"
                    imageId={selectedAsset.asset.id}
                    open={this.state.openHistoryDialog}
                    setOpen={bool => this.setState({openHistoryDialog: bool})}
                >
                </HistoryDialog>}
                <ConfirmDialog
                    title="Confirm delete one or selected assets"
                    open={this.state.openDeleteDialog}
                    setOpen={bool => this.setState({openDeleteDialog: bool})}
                    onConfirm={() => this.handleDeleteAssets(this.state.selectedAsset as unknown as IAssetMetadata)}
                >
                    <h4>Are you sure you want to delete one or selected assets?</h4>
                </ConfirmDialog>
                <ConfirmDialog
                    title="Confirm uncheck one or selected assets"
                    open={this.state.openUncheckSelectedDialog}
                    setOpen={bool => this.setState({openUncheckSelectedDialog: bool})}
                    onConfirm={() => this.handleUncheckSelected()}
                >
                    <h4>Are you sure you want to set one or all selected assets that are checked into unchecked?</h4>
                </ConfirmDialog>
                <ConfirmDialog
                    title="Confirm uncheck all assets"
                    open={this.state.openUncheckAllDialog}
                    setOpen={bool => this.setState({openUncheckAllDialog: bool})}
                    onConfirm={() => this.handleUncheckAll()}
                >
                    <h4>Are you sure you want to set all checked assets into unchecked?</h4>
                </ConfirmDialog>
                <SplitPane split="vertical"
                           defaultSize={this.state.thumbnailSize.width}
                           minSize={100}
                           maxSize={300}
                           paneStyle={{
                               display: "flex",
                               position: "relative",
                               height: "calc(100vh - 64px)",
                           }}>
                    <div className="text-page-sidebar bg-lighter-1">
                        <EditorSideBar
                            assets={assets as unknown as IAsset[]}
                            selectedAsset={selectedAsset ? selectedAsset.asset as unknown as IAsset : null}
                            onBeforeAssetSelected={this.onBeforeAssetSelected}
                            onAssetSelected={this.selectAsset}
                            onCheckboxClicked={this.onCheckboxClicked}
                            thumbnailSize={this.state.thumbnailSize}
                            page={this.project.page}
                            assetsSize={this.project.assetsSize}
                            checked={this.project.checked}
                            checkboxes={this.project.checkBoxIds}
                        />
                    </div>
                    <div className="text-page-content">
                        <div className="text-page-content-main text-page-canvas">
                            <div className="editor-page-content-main-header text-page-content-main-header">
                                <EditorToolbar project={this.project}
                                               items={this.toolbarItems}
                                               onToolbarItemSelected={this.onToolbarItemSelected}
                                />
                            </div>
                            <div className="editor-page-content-main-body">
                                {this.state.selectedImageLocked && !this.project.checked && this.project.shouldLock && <div className="editor-locker"></div>}
                                {selectedAsset &&
                                <Canvas
                                    ref={this.canvas}
                                    selectedAsset={this.state.selectedAsset as unknown as IAssetMetadata}
                                    regionsEmpty={this.state.regionsEmpty}
                                    onAssetMetadataChanged={this.onAssetMetadataChanged}
                                    // onSaveMetadataClicked={this.onSaveClicked}
                                    onCanvasRendered={this.onCanvasRendered}
                                    onSelectedRegionsChanged={this.onSelectedRegionsChanged}
                                    editorMode={this.state.editorMode}
                                    selectionMode={this.state.selectionMode}
                                    project={this.project}
                                    lockedTags={this.state.lockedTags}
                                >
                                    <AssetPreview
                                        autoPlay={true}
                                        controlsEnabled={this.state.isValid}
                                        onBeforeAssetChanged={this.onBeforeAssetSelected}
                                        asset={this.state.selectedAsset.asset as unknown as IAsset}
                                        // @ts-ignore
                                        agentId={this.props.match.params["agentId"]}
                                        thumbnailView={false}/>
                                </Canvas>
                                }
                            </div>
                            {selectedAsset && <TextField className="text-page-input"
                                                         ref={this.inputTextRef}
                                       value={selectedAsset.asset.imageValues.length != 0 ? selectedAsset.asset.imageValues[0].text : ""}
                                       onChange={e => this.setText(e.target.value)}>
                            </TextField>}
                        </div>
                        <div className="text-page-right-sidebar">
                            <FilterSliders
                                onChange={this.handleFilterChange}
                                onReset={this.resetFilters}>
                            </FilterSliders>
                        </div>
                    </div>
                </SplitPane>
            </div>
        );
    }

    private setFocusOnInput() {
        if (this.inputTextRef.current) {
            const input = this.inputTextRef.current.querySelector('input');
            if (input) {
                input.focus();
            }
        }
    }

    private setText = (text: string) => {
        const asset = this.state.selectedAsset;
        asset.asset.imageValues = [{
            text: text,
            certainty: 1,
            imageValueType: "Text"
        }]
        this.setState({selectedAsset: asset})
    }

    private onChangeChecked = async () => {
        this.project.checked = !this.project.checked;
        this.setState({isLoading: true}, this.getImages);
    }

    private loadProjectAssets = async (projectId: any): Promise<void> => {
        await AIAgentsClient().getAgentsTags(projectId)
            .then(response => {
                this.project = {
                    id: projectId,
                    name: "string",
                    version: "1",
                    useSecurityToken: false,
                    tags: response.data,
                    exportFormat: {providerType: "type"},
                    checked: false,
                    page: 0,
                    checkBoxIds: []
                };
            });
        await AIAgentsClient().getAgentsInfo(projectId)
            .then(response => {
                this.project.shouldLock = response.data.needLockBeforeUpdate
            })
        this.setState({isLoading: true}, this.getImages);
    }

    private getImages = async (): Promise<void> => {
        ImageClient().getImages(this.project.id, this.project.page, 50, this.project.checked).then(x => {
            this.project.assetsSize = x.data.counter;
            const rootAssets: IAssetText[] = x.data.list.map(image => {
                let asset: IAssetText = {
                    id: image.id,
                    type: 1,
                    state: image.checked ? 2 : 0,
                    name: image.uuid,
                    thumbnail: "https://img.vumo.ai/fastResize/image.jpg?path=" + image.fullPath + "@carscanner-trainer&height=600",
                    url: image.url,
                    format: "jpg"
                }
                if (image.imageValueDtos && image.imageValueDtos.length > 0) {
                    asset.imageValues = image.imageValueDtos
                } else {
                    asset.imageValues = []
                }
                return asset
            })
            this.setState({
                isLoading: false,
                assets: rootAssets,
            }, async () => {
                if (rootAssets.length > 0) {
                    await this.selectAsset(rootAssets[0]);
                }
            });
            this.project.checkBoxIds = []
            this.toggleToolBarItems();
        })
    }
    /**
     * Raised when the selected asset has been changed.
     * This can either be a parent or child asset
     */
    private onAssetMetadataChanged = async (assetMetadata: ITextAssetMetadata | IAssetMetadata, save = true): Promise<void> => {
        if (!this.state.selectedAsset) {
            return;
        }

        // Only update asset metadata if state changes or is different
        if (save) {
            assetMetadata.asset.state = assetMetadata.asset.state === AssetState.Saved ? AssetState.Saved : assetMetadata.asset.state === AssetState.Locked ? AssetState.Locked : AssetState.Visited;
            this.setState({isValid: true, regionsEmpty: assetMetadata.regions.length === 0});
        }
    }

    private async saveAsset(assetMetadata: ITextAssetMetadata) {
        const imageValues = assetMetadata.asset.imageValues
        await ImageClient().markImageTextValues(Number(assetMetadata.asset.id),
            imageValues).then(x => {
            assetMetadata.asset.imageValues = x.data
        })
        assetMetadata.asset.state = AssetState.Tagged;
        this.setFocusOnInput();
        const assets = [...this.state.assets];
        const assetIndex = assets.findIndex((asset) => asset.id === assetMetadata.asset.id);
        if (assetIndex > -1) {
            assets[assetIndex] = {
                ...assetMetadata.asset,
            };
        }
        this.setState({assets, isValid: true});
    }

    private async saveAssetWithoutCheck(assetMetadata: ITextAssetMetadata) {
        const imageValues = assetMetadata.asset.imageValues
        await ImageClient().markImageTextValues(Number(assetMetadata.asset.id),
            imageValues, false).then(x => {
            assetMetadata.asset.imageValues = x.data
        })
        assetMetadata.asset.state = AssetState.Saved;
        this.setFocusOnInput();
        const assets = [...this.state.assets];
        const assetIndex = assets.findIndex((asset) => asset.id === assetMetadata.asset.id);
        if (assetIndex > -1) {
            assets[assetIndex] = {
                ...assetMetadata.asset,
            };
        }
        this.setState({assets, isValid: true});
    }

    private handleDeleteAssets = (assetMetadata: IAssetMetadata) => {
        const checkBoxIds = this.project.checkBoxIds
        if (checkBoxIds.length > 0) {
            ImageClient().deleteImages(checkBoxIds)
                .then(async () => {
                    this.state.assets = this.state.assets.filter(asset => {
                        return !checkBoxIds.includes(Number(asset.id));
                    });
                    this.project.assetsSize = this.project.assetsSize - checkBoxIds.length
                    this.project.checkBoxIds = []
                    this.setState({assets: this.state.assets})
                    await this.goToRootAsset(1);
                })
                .catch(err => console.log(err));
            return;
        }
        if (!assetMetadata) {
            return;
        }
        const selectedAssetId = Number(assetMetadata.asset.id);
        ImageClient().deleteImage(selectedAssetId)
            .then(async () => {
                await this.goToRootAsset(1);
                this.state.assets = this.state.assets.filter(x => x.id !== assetMetadata.asset.id);
                this.project.assetsSize = this.project.assetsSize - 1
                this.setState({assets: this.state.assets})
            })
            .catch(err => console.log(err));
    }

    private onToolbarItemSelected = async (toolbarItem: ToolbarItem): Promise<void> => {
        switch (toolbarItem.props.name) {
            case ToolbarItemName.SelectCanvas:
                this.setState({
                    selectionMode: SelectionMode.NONE,
                    editorMode: EditorMode.Select,
                });
                break;
            case ToolbarItemName.PreviousAsset:
                await this.goToRootAsset(-1);
                break;
            case ToolbarItemName.NextAsset:
                await this.goToRootAsset(1);
                break;
            case ToolbarItemName.NextPage:
                await this.goToPage(1);
                break;
            case ToolbarItemName.PreviousPage:
                await this.goToPage(-1);
                break;
            case ToolbarItemName.Checked:
                await this.onChangeChecked();
                this.toggleToolBarItems();
                break;
            case ToolbarItemName.Save:
                const selectedAsset = this.state.selectedAsset;
                await this.saveAsset(selectedAsset);
                await this.goToRootAsset(1);
                if (!this.project.checked) {
                    this.state.assets = this.state.assets.filter(x => x.id !== selectedAsset.asset.id);
                    this.project.assetsSize = this.project.assetsSize - 1;
                    this.setState({assets: this.state.assets})
                }
                break;
            case ToolbarItemName.SaveOnLater:
                if (this.project.checked) {
                    this.setState({openUncheckSelectedDialog: true});
                } else {
                    const assetToSave = this.state.selectedAsset;
                    await this.saveAssetWithoutCheck(assetToSave);
                    await this.goToRootAsset(1);
                }
                break;
            case ToolbarItemName.UnCheckAll:
                this.setState({openUncheckAllDialog: true});
                break;
            case ToolbarItemName.Delete:
                this.setState({openDeleteDialog: true});
                break;
            case ToolbarItemName.History:
                this.setState({openHistoryDialog: true});
                break;
            case ToolbarItemName.Lock:
                const imageId = this.state.selectedAsset.asset.id;
                await ImageClient().lockImage(Number(imageId))
                    .then(()=>{
                        this.toolbarItems = this.toolbarItems.filter(x => x.config.name !== ToolbarItemName.Lock)
                        const currentAsset = this.state.selectedAsset;
                        currentAsset.asset.state = 4;
                        this.state.selectedImageLocked = false;
                        this.lockedImages.push(currentAsset.asset.id)
                        this.setState({selectedAsset: currentAsset})
                })
                    .catch(()=>{
                        this.state.assets = this.state.assets.filter(x => x.id !== imageId)
                        this.project.assetsSize = this.project.assetsSize - 1;
                        this.state.selectedImageLocked = true;
                        this.setState({assets: this.state.assets})
                        this.goToRootAsset(1)
                    })
                break;
        }
    }

    /**
     * Navigates to the previous / next root asset on the sidebar
     * @param direction Number specifying asset navigation
     */
    private goToRootAsset = async (direction: number) => {
        const selectedRootAsset = this.state.selectedAsset.asset.parent || this.state.selectedAsset.asset;
        const currentIndex = this.state.assets
            .findIndex((asset) => asset.id === selectedRootAsset.id);

        if (direction > 0) {
            await this.selectAsset(this.state.assets[Math.min(this.state.assets.length - 1, currentIndex + 1)]);
        } else {
            await this.selectAsset(this.state.assets[Math.max(0, currentIndex - 1)]);
        }
    }
    /**
     * Raised when the asset binary has been painted onto the canvas tools rendering canvas
     */
    private onCanvasRendered = async (canvas: HTMLCanvasElement) => {
        this.canvasArea = canvas;
        const assetMetadata = this.state.selectedAsset;
        await this.onAssetMetadataChanged(assetMetadata);
    }

    private onSelectedRegionsChanged = (selectedRegions: IRegion[]) => {
        this.setState({selectedRegions});
    }
    private onBeforeAssetSelected = (): boolean => {
        if (!this.state.isValid) {
            this.setState({showInvalidRegionWarning: true});
        }

        return this.state.isValid;
    }

    private selectAsset = async (asset: IAssetText): Promise<void> => {
        // Nothing to do if we are already on the same asset.
        if (this.state.selectedAsset && this.state.selectedAsset.asset.id === asset.id) {
            return;
        }
        if (!this.state.isValid) {
            this.setState({showInvalidRegionWarning: true});
            return;
        }
        const assetMetadata: ITextAssetMetadata = {
            asset: asset,
            regions: [],
            version: "1"
        }
        try {
            if (!asset.size) {
                const assetProps = await HtmlFileReader.readAssetAttributes(asset);
                assetMetadata.asset.size = {width: assetProps.width, height: assetProps.height};
            }
        } catch (err) {
            console.warn("Error computing asset size");
        }

        this.setState({
            selectedAsset: {
                asset: asset,
                regions: [],
                version: "1"
            },
            selectedImageLocked: !this.lockedImages.includes(asset.id),
        }, async () => {
            await this.onAssetMetadataChanged(assetMetadata, false);
            this.toggleToolBarItems();
        });
    }

    //CONVERTERS
    private pixelsToPercentage = (imageSize: ISize, bounding: IBoundingBox): IBoundingBox => {
        return {
            left: bounding.left / imageSize.width,
            top: bounding.top / imageSize.height,
            width: bounding.width / imageSize.width,
            height: bounding.height / imageSize.height
        }
    }

    private async goToPage(direction: number) {
        if ((this.project.page+1) * 50 >= this.project.assetsSize && direction === 1) {
            return;
        }
        if (this.project.page + direction >= 0) {
            this.project.page = this.project.page + direction
            this.setState({isLoading: true}, this.getImages);
        }
    }

    private toggleToolBarItems(): void {
        if (this.project.checked === undefined) {
            return;
        }

        let tools = ToolbarItemFactory.getToolbarItems()
        const toolsToFilter = [ToolbarItemName.DrawRectangle, ToolbarItemName.DrawPolygon, ToolbarItemName.RemoveAllRegions, ToolbarItemName.Undo, ToolbarItemName.Redo]
        tools = tools.filter(x => !toolsToFilter.includes(x.config.name))
        ToolbarItemFactory.reset()

        if (this.project.checked === true) {
            tools.forEach(toolBar => {
                if (toolBar.config.name === ToolbarItemName.SaveOnLater) {
                    ToolbarItemFactory.register({
                        name: ToolbarItemName.SaveOnLater,
                        tooltip: strings.editorPage.toolbar.unCheckSelected,
                        icon: "far fa-reply",
                        group: ToolbarItemGroup.Asset,
                        type: ToolbarItemType.Action,
                        accelerators: ["Alt+Shift"]
                    });
                    ToolbarItemFactory.register({
                        name: ToolbarItemName.UnCheckAll,
                        tooltip: strings.editorPage.toolbar.unCheckAll,
                        icon: "far fa-reply-all",
                        group: ToolbarItemGroup.Asset,
                        type: ToolbarItemType.Action
                    });
                } else if (toolBar.config.name !== ToolbarItemName.Lock && toolBar.config.name !== ToolbarItemName.UnCheckAll) {
                    ToolbarItemFactory.register(toolBar.config)
                }
            })
            this.toolbarItems = ToolbarItemFactory.getToolbarItems()
        } else if (this.project.checked === false) {
            tools.forEach(toolBar => {
                if (toolBar.config.name === ToolbarItemName.SaveOnLater) {
                    ToolbarItemFactory.register({
                        name: ToolbarItemName.SaveOnLater,
                        tooltip: strings.editorPage.toolbar.saveOnLater,
                        icon: "far fa-bookmark",
                        group: ToolbarItemGroup.Asset,
                        type: ToolbarItemType.Action,
                        accelerators: ["Alt+Shift"]
                    });
                } else if (toolBar.config.name !== ToolbarItemName.UnCheckAll && toolBar.config.name !== ToolbarItemName.Lock) {
                    ToolbarItemFactory.register(toolBar.config)
                }
            })
            if (this.state.selectedImageLocked && this.project.shouldLock) {
                ToolbarItemFactory.register({
                    name: ToolbarItemName.Lock,
                    tooltip: strings.editorPage.toolbar.lock,
                    icon: "far fa-lock",
                    group: ToolbarItemGroup.Asset,
                    type: ToolbarItemType.Action
                });
            }
            this.toolbarItems = ToolbarItemFactory.getToolbarItems()
        }
    }

    private onCheckboxClicked = (imageId: number): void => {
        if (this.project.checkBoxIds.includes(imageId)) {
            this.project.checkBoxIds = this.project.checkBoxIds.filter(imgId => imgId !== imageId)
        } else {
            this.project.checkBoxIds.push(imageId)
        }

        this.setState({});
    }

    private handleUncheckAll = async (): Promise<void> => {
        const agentId = this.project.id;
        await ImageClient().unCheckImages({agentId: Number(agentId)}).then(()=>{
            this.state.assets = [];
            this.project.page = 0;
            this.project.assetsSize = 0;
            this.setState({assets: this.state.assets})
        })
    }

    private handleUncheckSelected = async (): Promise<void> => {
        if (this.project.checkBoxIds.length > 0) {
            await ImageClient().unCheckImages({imageIds: this.project.checkBoxIds}).then(()=>{
                this.state.assets = this.state.assets.filter(x => !this.project.checkBoxIds.includes(Number(x.id)))
                this.project.assetsSize = this.project.assetsSize - this.project.checkBoxIds.length;
                this.project.checkBoxIds = []
                this.setState({assets: this.state.assets})
                this.goToRootAsset(1)
            })
        } else {
            const assetToSave = this.state.selectedAsset;
            await ImageClient().unCheckImages({imageId: Number(assetToSave.asset.id)}).then(()=>{
                this.state.assets = this.state.assets.filter(x => x.id !== assetToSave.asset.id);
                this.project.assetsSize = this.project.assetsSize - 1;
                this.project.checkBoxIds = []
                this.setState({assets: this.state.assets})
                this.goToRootAsset(1)
            })
        }
    }
}
