export const LocalStorageHelper = () => {

    const getUsername = (): string | null => {
        return localStorage.getItem('username');
    }
    const getRole = (): string | null => {
        return localStorage.getItem('role');
    }
    const getToken = (): string | null => {
        return localStorage.getItem('accessToken');
    }
    const getRefreshToken = (): string | null => {
        return localStorage.getItem('refreshToken');
    }
    const getSystem = (): string | null => {
        return localStorage.getItem('system');
    }

    const setUsername = (username: string) => {
        localStorage.setItem('username', username);
    }
    const setRole = (role: string) => {
        localStorage.setItem('role', role);
    }
    const setToken = (token: string) => {
        localStorage.setItem('accessToken', token);
    }
    const setRefreshToken = (token: string) => {
        localStorage.setItem('refreshToken', token);
    }
    const setSystem = (system: string) => {
        localStorage.setItem('system', system);
    }
    const logout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('username');
        localStorage.removeItem('role');
        localStorage.removeItem('system');
    }

    const removeAccessToken = () => {
        localStorage.removeItem('accessToken');
    }

    return {
        logout, setToken, setRole, setUsername, setSystem, getRole, getToken, getUsername, getSystem,
        getRefreshToken, setRefreshToken, removeAccessToken
    }
}
