import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Select,
    InputLabel, MenuItem, Table, TableHead, TableBody, TableCell, TableContainer, TableRow, Paper,
    CircularProgress
} from '@material-ui/core';
import './UserManagerPage.scss';
import {UserManagerClient} from "../../common/_clients/UserManagerClient";

const UserManagerPage = () => {
    const [users, setUsers] = useState([]);
    const [newUser, setNewUser] = useState({ username: '', password: '', role: 'ROLE_USER', agents: [] });
    const [editUser, setEditUser] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isNewUserDialogOpen, setIsNewUserDialogOpen] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        UserManagerClient().getUsers().then(response => setUsers(response.data)).catch(error => setError('Error fetching users'));
    }, []);

    const handleCreateUser = () => {
        setIsLoading(true);
        setError(null);
        UserManagerClient().createUser(newUser).then(response => {
            const userToAdd = {
                username: response.data.username,
                role: response.data.role,
                agents: response.data.properties.agents || []
            };
            setUsers([...users, userToAdd]);
            setNewUser({username: '', password: '', role: 'ROLE_USER', agents: []});
            setIsNewUserDialogOpen(false);
            setIsLoading(false);
        }).catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    }

    const handleDeleteUser = () => {
        if (userToDelete) {
            setIsLoading(true);
            setError(null);
            UserManagerClient().deleteUser(userToDelete.username).then(() => {
                setUsers(users.filter(user => user.username !== userToDelete.username));
                setIsDeleteDialogOpen(false);
                setUserToDelete(null);
                setIsLoading(false);
            }).catch(error => {
                setError(error.message);
                setIsLoading(false);
            })
        }
    };

    const handleEditUser = () => {
        setIsLoading(true);
        setError(null);
        UserManagerClient().editUser(editUser).then(response => {
            setUsers(users.map(user => (user.username === response.data.username ? response.data : user)));
            setEditUser(null);
            setIsDialogOpen(false);
            setIsLoading(false);
        }).catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    };

    const openDialog = (user) => {
        setEditUser(user);
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setEditUser(null);
        setIsDialogOpen(false);
    };

    const openDeleteDialog = (user) => {
        setUserToDelete(user);
        setIsDeleteDialogOpen(true);
    };

    const closeDeleteDialog = () => {
        setUserToDelete(null);
        setIsDeleteDialogOpen(false);
    };

    const openNewUserDialog = () => {
        setIsNewUserDialogOpen(true);
    };

    const closeNewUserDialog = () => {
        setIsNewUserDialogOpen(false);
    };

    const filteredUsers = users.filter(user =>
        user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.role.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="user-manager">
            <div className="users-list">
                <div className={'users-list__header'}>
                    <h2>Users List</h2>
                    <Button className={"create-user-button"} variant="contained" color="primary" onClick={openNewUserDialog}>
                        Create User
                    </Button>
                </div>
                <TextField
                    className={"search-field"}
                    label="Search by username or role"
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="centered table-header">User</TableCell>
                                <TableCell className="centered table-header">Role</TableCell>
                                <TableCell className="centered table-header">Agents</TableCell>
                                <TableCell className="centered table-header">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredUsers.map(user => (
                                <TableRow key={user.username}>
                                    <TableCell className="centered">{user.username}</TableCell>
                                    <TableCell className="centered">{user.role}</TableCell>
                                    <TableCell className="centered centered__agents">{user.agents.length === 0 ? '-' : user.agents.join(', ')}</TableCell>
                                    <TableCell className="centered__gap">
                                        <Button className="action-buttons__delete" variant="contained" color="secondary" onClick={() => openDeleteDialog(user)}>
                                            Delete
                                        </Button>
                                        <Button className="action-buttons__edit" variant="contained" onClick={() => openDialog(user)}>
                                            Edit
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Dialog open={isNewUserDialogOpen} onClose={closeNewUserDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Create New User</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Username"
                        value={newUser.username}
                        onChange={e => setNewUser({ ...newUser, username: e.target.value })}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Password"
                        type="password"
                        value={newUser.password}
                        onChange={e => setNewUser({ ...newUser, password: e.target.value })}
                        fullWidth
                        margin="normal"
                        inputProps={{ minLength: 6 }}
                        error={newUser.password.length > 0 && newUser.password.length < 6}
                        helperText={
                            newUser.password.length < 6
                                ? "Password must be at least 6 characters long"
                                : ""
                        }
                    />
                    <TextField
                        label="Agents"
                        value={newUser.agents}
                        onChange={e => setNewUser({ ...newUser, agents: e.target.value == "" ? [] : e.target.value.split(',') })}
                        fullWidth
                        margin="normal"
                        helperText={"Agents must be separated by commas. example: 1,2,3"}
                    />
                    <Select
                        className={"role-select"}
                        value={newUser.role}
                        onChange={e => setNewUser({ ...newUser, role: e.target.value.toString() })}
                        fullWidth
                    >
                        <InputLabel>Role</InputLabel>
                        <MenuItem value="ROLE_USER">User</MenuItem>
                        <MenuItem value="ROLE_ADMIN">Admin</MenuItem>
                    </Select>
                </DialogContent>
                <DialogActions>
                    {isLoading ? <CircularProgress size={24} /> : (
                        <>
                            <Button onClick={handleCreateUser} color="primary">
                                Create User
                            </Button>
                            <Button onClick={closeNewUserDialog} color="primary">
                                Cancel
                            </Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>
            <Dialog open={isDialogOpen} onClose={closeDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Edit User</DialogTitle>
                {editUser && (
                    <DialogContent>
                        <TextField
                            label="Username"
                            value={editUser.username}
                            onChange={e => setEditUser({ ...editUser, username: e.target.value })}
                            fullWidth
                            margin="normal"
                            disabled={true}
                        />
                        <TextField
                            label="Password"
                            type="password"
                            value={editUser.password ?? ""}
                            onChange={e => setEditUser({ ...editUser, password: e.target.value })}
                            fullWidth
                            margin="normal"
                            inputProps={{ minLength: 6 }}
                            error={editUser.password && editUser.password.length > 0 && editUser.password.length < 6}
                            helperText={
                                editUser.password && editUser.password.length > 0 && editUser.password.length < 6
                                    ? "Password must be at least 6 characters long"
                                    : "If you don't want to change password leave it empty."
                            }
                        />

                        <TextField
                            label="Agents"
                            value={editUser.agents.join(',')}
                            onChange={e => setEditUser({ ...editUser, agents: e.target.value == "" ? [] : e.target.value.split(',') })}
                            fullWidth
                            margin="normal"
                            helperText={"Agents must be separated by commas. example: 1,2,3"}
                        />
                        <Select
                            className={"role-select"}
                            value={editUser.role}
                            onChange={e => setEditUser({ ...editUser, role: e.target.value.toString() })}
                            fullWidth
                        >
                            <InputLabel>Role</InputLabel>
                            <MenuItem value="ROLE_USER">User</MenuItem>
                            <MenuItem value="ROLE_ADMIN">Admin</MenuItem>
                        </Select>
                    </DialogContent>
                )}
                <DialogActions>
                    {isLoading ? <CircularProgress size={24} /> : (
                        <>
                            <Button onClick={handleEditUser} color="primary">
                                Save Changes
                            </Button>
                            <Button onClick={closeDialog} color="primary">
                                Cancel
                            </Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>
            <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog} aria-labelledby="delete-dialog-title">
                <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to delete user {userToDelete?.username}?</p>
                </DialogContent>
                <DialogActions>
                    {isLoading ? <CircularProgress size={24} /> : (
                        <>
                            <Button onClick={handleDeleteUser} color="primary">
                                Delete
                            </Button>
                            <Button onClick={closeDeleteDialog} color="primary">
                                Cancel
                            </Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UserManagerPage;
