import React from "react";
import {Button, DropdownButton, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import Dropzone, {FileWithPath} from "react-dropzone";
import {AIAgentsClient} from "../../common/_clients/AIAgentsClient";
import DropdownItem from "react-bootstrap/DropdownItem";
import {AiAgent} from "../../common/_dto/AiAgent";
import "./scan.css";
import {ImageClient} from "../../common/_clients/ImageClient";
import LoadingIndicator from "../../common/loadingIndicator/LoadingIndicator";
import {trackPromise} from "react-promise-tracker";


interface IProps {
}

interface IState {
    agentsList: AiAgent[],
    agent: AiAgent,
    check: boolean,
    photos: FileWithPath[]
}

export default class Scan extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            agentsList: [],
            agent: undefined,
            check: false,
            photos: new Array<FileWithPath>()
        };
    }

    componentDidMount() {
        // Load agents
        AIAgentsClient()
            .getAgents()
            .then(response => this.setState({agentsList: response.data}));
    }

    private selectAgent(selectedAgent) {
        this.setState({agent: selectedAgent});
    }

    private deletePhoto(photo: FileWithPath) {
        if (!window.confirm("Are you sure you want to delete this photo?"))
            return;
        let ind: number = this.state.photos.indexOf(photo);
        if (ind > -1) {
            this.state.photos.splice(ind, 1);
            this.setState({photos: this.state.photos});
        } else {
            alert("Error while trying to delete photo");
        }
    }

    private upload() {
        trackPromise(
            ImageClient().uploadImage(this.state.agent.id, this.state.photos).then(() => {
                // If uploaded successfully delete photos
                this.setState({photos: new Array<FileWithPath>()});
            }).catch(err => {
                alert("An error occurred during uploading!");
                console.log(err);
            }));
    }

    public render() {
        if (!this.state.agentsList) {
            return (
                <div>Loading ...</div>
            );
        }
        return (
            <section className="scan-container bg-dark">
                <InputGroup className="mb-3">
                    <DropdownButton as={InputGroup.Prepend} id="dropdown-basic-button" title="Choose agent"
                                    variant="outline-primary">
                        {this.state.agentsList.map(agent =>
                            <DropdownItem key={agent.id} onClick={() => this.selectAgent(agent)}>
                                {`${agent.name}, ${agent.type}`}
                            </DropdownItem>)}
                    </DropdownButton>
                    <FormControl
                        aria-describedby="basic-addon1"
                        disabled
                        placeholder={`${this.state.agent ? `${this.state.agent.name}, ${this.state.agent.type}` : "Please select agent"}`}
                        className="bg-secondary border-dark input-agent"
                    />
                </InputGroup>
                <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                        type="checkbox"
                        label="Check"
                        checked={this.state.check}
                        onChange={() => this.setState({check: !this.state.check})}
                    />
                </Form.Group>
                <Dropzone
                    onDrop={(photos: FileWithPath[]) => this.setState({photos: this.state.photos.concat(photos)})}
                    multiple={true}
                    accept="image/*"
                >
                    {({getRootProps, getInputProps, isDragActive}) => (
                        <div {...getRootProps({className: isDragActive ? "dropzone-active" : "dropzone"})}>
                            <input {...getInputProps()} />
                            {isDragActive ?
                                <p>Drop files here</p> :
                                <p>Drag 'n' drop some files here, or click to select files</p>
                            }
                        </div>
                    )}
                </Dropzone>
                <aside>
                    <Row lg={5} md={4} sm={3} xs={2}>
                        {this.state.photos.map((file, index) =>
                            <div key={index} className="photo-container">
                                <div className="photo-overlay" onClick={() => this.deletePhoto(file)}>&#10006;</div>
                                <img src={URL.createObjectURL(file)} alt={file.name} className="p-3 photo"/>
                            </div>
                        )}
                    </Row>
                </aside>
                <LoadingIndicator text="Uploading" trackPromise={true}/>
                <Button
                    variant="outline-primary"
                    disabled={!(this.state.agent !== undefined && this.state.photos.length > 0)}
                    onClick={() => this.upload()}
                >
                    Upload
                </Button>
            </section>
        );
    }
}
