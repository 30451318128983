import {
    GET_HISTORY_ACTIONS_REST_PATH,
    GET_IMAGES_COUNT_REST_PATH,
    IMAGES_REST_PATH,
    PATCH_IMAGE_OBJECT_VALUE_REST_PATH, POST_LOCK_IMAGE_PATH, POST_UNCHECK_PATH,
    REMOVE_BG_UPLOAD_REST_PATH,
    SET_IMAGES_CHECK_REST_PATH
} from "../../utils/path";
import axios, {AxiosResponse} from "axios";
import {FileWithPath} from "react-dropzone";
import {AImageValue} from "../_dto/AImageValue";

type UnCheckImagesParams = {
    imageId?: number;
    agentId?: number;
    imageIds?: number[];
};

export const ImageClient = () => {
    const getImages = (agentsId: any, page: number, size: number, checked: boolean): Promise<AxiosResponse> => {
        return axios.get(`${IMAGES_REST_PATH}`, {
            params: {
                agentsId: agentsId + '',
                page: page,
                size: size,
                checked: checked
            }
        })
    };
    const getImagesWithFilter = (agentsId: any, page: number, size: number, checked: boolean, filter: any): Promise<AxiosResponse> => {

        let config = {
            params: {
                agentsId: agentsId + '',
                page: page,
                size: size,
                checked: checked,
                properties: undefined
            }
        }

        if(filter !==""){
            config.params.properties = filter
        }
        return axios.get(`${IMAGES_REST_PATH}`, config)
    };
    const getImagesCount = (agentsId: number[], checked: boolean): Promise<AxiosResponse> => {
        return axios.get(`${GET_IMAGES_COUNT_REST_PATH}`, {
            params: {
                agentsId: agentsId + '',
                checked: checked
            }
        })
    };

    const getImageHistory = (imageId: string): Promise<AxiosResponse> => {
        return axios.get(`${GET_HISTORY_ACTIONS_REST_PATH}${imageId}`)
    };

    const setImageCheck = (imageId: number, checked: boolean): Promise<AxiosResponse> => {
        const params = new URLSearchParams();
        params.append('imageId', String(imageId));
        params.append('checked', String(checked));
        return axios.post(`${SET_IMAGES_CHECK_REST_PATH}`, {}, {params: params})
    };
    const deleteImage = (imageId: number, deleteAll?: boolean): Promise<AxiosResponse> => {
        const params = new URLSearchParams();

        if (deleteAll) {
            params.append('deleteAllByParentUuid', String(deleteAll));
        }
        return axios.delete(`${IMAGES_REST_PATH}${imageId}`, {params: params})
    };

    const deleteImages = (imageIds: number[], deleteAll?: boolean): Promise<AxiosResponse> => {
        const params = new URLSearchParams();
        params.append('imageIds', String(imageIds))
        if (deleteAll) {
            params.append('deleteAllByParentUuid', String(deleteAll));
        }
        return axios.delete(`${IMAGES_REST_PATH}`,  {params: params})
    }

    const propagateImages = (imageIds: number[], agentIds: number[]): Promise<AxiosResponse> => {
        const body = {
            imageIds: imageIds,
            aiAgentIds: agentIds
        }
        return axios.post(`${IMAGES_REST_PATH}propagate`, body)
    }

    const markImageValues = (imageId: number, imageValues: AImageValue[], checked = true): Promise<AxiosResponse> => {
        return axios.patch(`${PATCH_IMAGE_OBJECT_VALUE_REST_PATH}${imageId}`, imageValues, {
            params: {
                checked: checked
            }
        })
    }

    const markImageTextValues = (imageId: number, imageValues: any, checked = true): Promise<AxiosResponse> => {
        return axios.patch(`${PATCH_IMAGE_OBJECT_VALUE_REST_PATH}${imageId}`, imageValues, {
            params: {
                checked: checked
            }
        })
    }

    const unCheckImages = (data: UnCheckImagesParams): Promise<AxiosResponse> => {
        const params = new URLSearchParams();
        if (data.imageId) {
            params.append('imageId', String(data.imageId))
        } else if (data.agentId) {
            params.append('aiAgentId', String(data.agentId))
        } else {
            params.append('imageIds', String(data.imageIds))
        }

        params.append('checked', String(false));
        return axios.post(`${POST_UNCHECK_PATH}`, {}, {params: params})
    }

    const lockImage = (imageId: number): Promise<AxiosResponse> => {
        return axios.post(`${POST_LOCK_IMAGE_PATH}${imageId}`, {})
    }

    const uploadRemoveBg = (agentId: number, images: FileWithPath[]): Promise<AxiosResponse> => {
        let formData: FormData = images.reduce((ans, image) => {
            ans.append("files", image);
            return ans;
        }, new FormData());
        return axios.post(`${REMOVE_BG_UPLOAD_REST_PATH}${agentId}`, formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
    }

    const uploadImage = (agentId: number, images: FileWithPath[]): Promise<AxiosResponse> => {
        let formData: FormData = images.reduce((ans, image) => {
            ans.append("files", image);
            return ans;
        }, new FormData());
        return axios.post(`${IMAGES_REST_PATH}${agentId}`, formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
    }

    const uploadMaskAsPng = (photoUuid: string, image: FileWithPath): Promise<AxiosResponse> => {
        return axios.post(`${IMAGES_REST_PATH}${photoUuid}/imageValue/maskFromPng`, image, {
            headers: {
                'content-type': image.type
            }
        })
    }

    return {
        getImages: getImages,
        getImagesWithFilter: getImagesWithFilter,
        getImagesCount: getImagesCount,
        getImageHistory: getImageHistory,
        setImageCheck: setImageCheck,
        deleteImage: deleteImage,
        deleteImages: deleteImages,
        propagateImages: propagateImages,
        markImageValues: markImageValues,
        markImageTextValues: markImageTextValues,
        unCheckImages: unCheckImages,
        lockImage: lockImage,
        uploadImage: uploadImage,
        uploadRemoveBg: uploadRemoveBg,
        uploadMaskAsPng: uploadMaskAsPng
    }
}
