import React from "react";
import ReactDOM from "react-dom";
import {IRegion, ITag} from "../../../models/applicationState";
import "./tagInput.scss";
import "../condensedList/condensedList.scss";
import TagInputItem, {ITagClickProps, ITagInputItemProps} from "./tagInputItem";

export interface ITagInputProps {
    /** Current list of tags */
    tags: ITag[];
    /** Currently selected regions in canvas */
    selectedRegions?: IRegion[];
    /** Tags that are currently locked for editing experience */
    lockedTags?: string[];
    /** Handle click even if no region is selected */
    alwaysHandleClick?: boolean;
    /** Updates to locked tags */
    onLockedTagsChange?: (locked: string[]) => void;
    /** Function to call on clicking individual tag */
    onTagClick?: (tag: ITag) => void;
    /** Function to call on clicking individual tag while holding CTRL key */
    onCtrlTagClick?: (tag: ITag) => void;
}

export interface ITagInputState {
    tags: ITag[];
    selectedTag: ITag;
    portalElement: Element;
    editingTagNode: Element;
}

function defaultDOMNode(): Element {
    return document.createElement("div");
}

export class TagInput extends React.Component<ITagInputProps, ITagInputState> {

    public state: ITagInputState = {
        tags: this.props.tags || [],
        selectedTag: null,
        editingTagNode: null,
        portalElement: defaultDOMNode(),
    };

    private tagItemRefs: Map<string, TagInputItem> = new Map<string, TagInputItem>();
    private portalDiv = document.createElement("div");

    public render() {
        return (
            <div className="tag-input condensed-list">
                <h6 className="condensed-list-header tag-input-header bg-darker-2 p-2">
                    <span className="condensed-list-title tag-input-title">Tags</span>
                </h6>
                <div className="condensed-list-body">
                    <div className="tag-input-items">
                        {this.renderTagItems()}
                    </div>
                </div>
            </div>
        );
    }

    public componentDidMount() {
        document.body.appendChild(this.portalDiv);
        this.setState({
            portalElement: ReactDOM.findDOMNode(this.portalDiv) as Element,
        });
    }

    public componentWillUnmount() {
        document.body.removeChild(this.portalDiv);
    }

    public componentDidUpdate(prevProps: ITagInputProps) {
        if (prevProps.tags !== this.props.tags) {
            this.setState({
                tags: this.props.tags,
            });
        }

        if (prevProps.selectedRegions !== this.props.selectedRegions && this.props.selectedRegions.length > 0) {
            this.setState({
                selectedTag: null,
            });
        }
    }

    private getTagNode = (tag: ITag): Element => {
        const itemRef = tag ? this.tagItemRefs.get(tag.name) : null;
        return (itemRef ? ReactDOM.findDOMNode(itemRef) : defaultDOMNode()) as Element;
    }

    private onLockTag = (tag: ITag) => {
        if (!tag) {
            return;
        }
        let lockedTags = [...this.props.lockedTags];
        if (lockedTags.find((t) => t === tag.name)) {
            lockedTags = lockedTags.filter((t) => t !== tag.name);
        } else {
            lockedTags.push(tag.name);
        }
        this.props.onLockedTagsChange(lockedTags);
    }
    private setTagItemRef = (item: TagInputItem, tag: ITag) => {
        this.tagItemRefs.set(tag.name, item);
        return item;
    }

    private renderTagItems = () => {
        let props = this.createTagItemProps();
        this.tagItemRefs.clear();


        return props.map((prop) =>
            <TagInputItem
                key={prop.tag.name}
                ref={(item) => this.setTagItemRef(item, prop.tag)}
                {...prop}
            />);
    }
    private createTagItemProps = (): ITagInputItemProps[] => {
        const tags = this.state.tags;
        const selectedRegionTagSet = this.getSelectedRegionTagSet();

        return tags.map((tag) => (
            {
                tag,
                index: tags.findIndex((t) => t.name === tag.name),
                isLocked: this.props.lockedTags && this.props.lockedTags.findIndex((t) => t === tag.name) > -1,
                isSelected: this.state.selectedTag && this.state.selectedTag.name === tag.name,
                appliedToSelectedRegions: selectedRegionTagSet.has(tag.name),
                onClick: this.handleClick,
            } as ITagInputItemProps
        ));
    }

    private getSelectedRegionTagSet = (): Set<string> => {
        const result = new Set<string>();
        if (this.props.selectedRegions) {
            for (const region of this.props.selectedRegions) {
                for (const tag of region.tags) {
                    result.add(tag);
                }
            }
        }
        return result;
    }

    private handleClick = (tag: ITag, props: ITagClickProps) => {
        if (props.ctrlKey && this.props.onCtrlTagClick) {
            // Lock tags
            this.props.onCtrlTagClick(tag);
        } else { // Select tag
            const {selectedTag} = this.state;
            const alreadySelected = selectedTag && selectedTag.name === tag.name;

            this.setState({
                selectedTag: (alreadySelected) ? null : tag,
            });

            // Only fire click event if a region is selected
            if (((this.props.selectedRegions &&
                this.props.selectedRegions.length > 0) ||
                this.props.alwaysHandleClick) &&
                this.props.onTagClick) {
                this.props.onTagClick(tag);
            }
        }
    }
}
