import {AssetPreview} from "../../common/assetPreview/assetPreview";
import Canvas from "./canvas";
import {SelectionMode} from "vott-ct/lib/js/CanvasTools/Interface/ISelectorSettings";
import {
    AssetState,
    AssetType,
    EditorMode,
    IAsset,
    IAssetMetadata,
    IBoundingBox,
    IPoint,
    IProject,
    IRegion,
    ISize,
    ITag,
    RegionType
} from "../../../models/applicationState";
import React, {RefObject} from "react";
import "./editorPage.scss";
import HtmlFileReader from "../../../common/htmlFileReader";
import CanvasHelpers from "./canvasHelpers";
import EditorSideBar from "./editorSideBar";
import SplitPane from "react-split-pane";
import registerToolbar, { ToolbarItemGroup, ToolbarItemName } from "../../../registerToolbar";
import { ToolbarItem, ToolbarItemType } from "../../toolbar/toolbarItem";
import { IToolbarItemRegistration, ToolbarItemFactory } from "../../../providers/toolbar/toolbarItemFactory";
import { EditorToolbar } from "./editorToolbar";
import { TagInput } from "../../common/tagInput/tagInput";
import { ImageClient } from "../../common/_clients/ImageClient";
import { AIAgentsClient } from "../../common/_clients/AIAgentsClient";
import { ImageValueTypes } from "../../common/_dto/ImageValueTypes";
import { BoundingBoxRectangleImageValue } from "../../common/_dto/BoundingBoxRectangleImageValue";
import { KeyboardBinding } from "../../common/keyboardBinding/keyboardBinding";
import { KeyEventType } from "../../common/keyboardBinding/keyboardManager";
import { strings } from "../../utils/strings";
import { BoundingBoxPolygonImageValue } from "../../common/_dto/BoundingBoxPolygonImageValue";
import LoadingIndicator from "../../common/loadingIndicator/LoadingIndicator";
import ConfirmDialog from "../../common/confirmDialog/ConfirmDialog";
import HistoryDialog from "../../common/historyDialog/HistoryDialog";
import { FilterSliders } from "../../common/sliders/sliders";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteDialog from "../../common/deleteDialog/DeleteDialog";
import MoveImagesDialog from "../../common/moveImagesDialog/MoveImagesDialog";

export interface IEditorPageProps {
    agentId: number
}

export type Option = 'brightness' | 'contrast' | 'saturation';

export interface IEditorPageState {
    /** Boolean to indicate loading */
    isLoading: boolean;
    /** Array of assets in project */
    assets: IAsset[];
    /** The editor mode to set for canvas tools */
    editorMode: EditorMode;
    /** The selection mode to set for canvas tools */
    selectionMode: SelectionMode;
    /** The selected asset for the primary editing experience */
    selectedAsset?: IAssetMetadata;
    /** Currently selected region on current asset */
    selectedRegions?: IRegion[];
    /** Most recently selected tag */
    selectedTag: string;
    /** Tags locked for region labeling */
    lockedTags: string[];
    /** Size of the asset thumbnails to display in the side bar */
    thumbnailSize: ISize;
    /**
     * Whether or not the editor is in a valid state
     * State is invalid when a region has not been tagged
     */
    isValid: boolean;
    /** Whether the show invalid region warning alert should display */
    showInvalidRegionWarning: boolean;
    /** Boolean to indicate when to open delete dialog */
    openDeleteDialog: boolean;
    openPropagateDialog: boolean;
    openHistoryDialog: boolean;
    openUncheckSelectedDialog: boolean;
    openUncheckAllDialog: boolean;
    selectedImageLocked: boolean;
    regionsEmpty?: boolean;
    brightness: number;
    contrast: number;
    saturation: number;
}

export default class EditorPage extends React.Component<IEditorPageProps, IEditorPageState> {
    public state: IEditorPageState = {
        isLoading: false,
        selectedTag: "TAG_TEST",
        lockedTags: [],
        selectionMode: SelectionMode.NONE,
        assets: [],
        editorMode: EditorMode.None,
        thumbnailSize: {width: 175, height: 155},
        isValid: true,
        showInvalidRegionWarning: false,
        openDeleteDialog: false,
        openPropagateDialog: false,
        openUncheckSelectedDialog: false,
        openUncheckAllDialog: false,
        openHistoryDialog: false,
        selectedImageLocked: true,
        brightness: 100,
        contrast: 100,
        saturation: 100
    };
    public project: IProject
    private canvas: RefObject<Canvas> = React.createRef();
    private toolbarItems: IToolbarItemRegistration[] = ToolbarItemFactory.getToolbarItems();
    private lockedImages = []
    private canvasArea: HTMLCanvasElement;

    private handleFilterChange = (id: Option, value: number) => {
        this.setState((prevState) => ({
            ...prevState,
            [id]: value
        }));
    }

    private resetFilters = () => {
        this.setState({
            brightness: 100,
            contrast: 100,
            saturation: 100
        });
    }

    private getFiltersStyle() {
        return `brightness(${this.state.brightness}%) contrast(${this.state.contrast}%) saturate(${this.state.saturation}%)`
    }

    public async componentDidMount() {
        await this.updateProjectAssets();
        this.toggleToolBarItems();
    }

    public async componentDidUpdate(prevProps: IEditorPageProps, prevState: IEditorPageState) {
        if (this.canvasArea) {
            this.canvasArea.style.filter = this.getFiltersStyle()
        }
        // @ts-ignore
        if (this.props.match.params["agentId"] !== prevProps.match.params["agentId"])
            await this.updateProjectAssets();
    }

    public async updateProjectAssets() {
        // @ts-ignore
        const agentId = this.props.match.params["agentId"];
        await this.loadProjectAssets(agentId);
    }

    public render() {
        const {assets, selectedAsset} = this.state;
        if (!this.project || this.state.isLoading) {
            return (<LoadingIndicator text="Loading" trackPromise={false}/>);
        }
        return (
            <div className="editor-page">
                <ToastContainer />
                {[...Array(10).keys()].map((index) => {
                    return (<KeyboardBinding
                        displayName={strings.editorPage.tags.hotKey.apply}
                        key={index}
                        keyEventType={KeyEventType.KeyDown}
                        accelerators={[`${index}`]}
                        icon={"fa-tag"}
                        handler={this.handleTagHotKey}/>);
                })}
                {[...Array(10).keys()].map((index) => {
                    return (<KeyboardBinding
                        displayName={strings.editorPage.tags.hotKey.lock}
                        key={index}
                        keyEventType={KeyEventType.KeyDown}
                        accelerators={[`Alt+${index}`]}
                        icon={"fa-lock"}
                        handler={this.handleCtrlTagHotKey}/>);
                })}
                {selectedAsset && <HistoryDialog
                    title="Actions history"
                    imageId={selectedAsset.asset.id}
                    open={this.state.openHistoryDialog}
                    setOpen={bool => this.setState({openHistoryDialog: bool})}
                >
                </HistoryDialog>}
                <DeleteDialog
                    title="Confirm delete one or selected assets"
                    open={this.state.openDeleteDialog}
                    setOpen={bool => this.setState({openDeleteDialog: bool})}
                    onConfirm={(deleteAll) => this.handleDeleteAssets(this.state.selectedAsset, deleteAll)}
                >
                    <h4>Are you sure you want to delete one or selected assets?</h4>
                </DeleteDialog>
                <MoveImagesDialog
                    title="Propagate one or selected photos"
                    subtitle="Select the agents to which you want to propagate images"
                    open={this.state.openPropagateDialog}
                    setOpen={bool => this.setState({openPropagateDialog: bool})}
                    onConfirm={(agentsList) => this.propagateImages(this.state.selectedAsset, agentsList)}
                >
                </MoveImagesDialog>
                <ConfirmDialog
                    title="Confirm uncheck one or selected assets"
                    open={this.state.openUncheckSelectedDialog}
                    setOpen={bool => this.setState({openUncheckSelectedDialog: bool})}
                    onConfirm={() => this.handleUncheckSelected()}
                >
                    <h4>Are you sure you want to set one or all selected assets that are checked into unchecked?</h4>
                </ConfirmDialog>
                <ConfirmDialog
                    title="Confirm uncheck all assets"
                    open={this.state.openUncheckAllDialog}
                    setOpen={bool => this.setState({openUncheckAllDialog: bool})}
                    onConfirm={() => this.handleUncheckAll()}
                >
                    <h4>Are you sure you want to set all checked assets into unchecked?</h4>
                </ConfirmDialog>
                <SplitPane split="vertical"
                           defaultSize={this.state.thumbnailSize.width}
                           minSize={100}
                           maxSize={300}
                           paneStyle={{
                               display: "flex",
                               position: "relative",
                               height: "calc(100vh - 64px)",
                           }}
                           onChange={this.onSideBarResize}>
                    <div className="editor-page-sidebar bg-lighter-1">
                        <EditorSideBar
                            assets={assets}
                            selectedAsset={selectedAsset ? selectedAsset.asset : null}
                            onBeforeAssetSelected={this.onBeforeAssetSelected}
                            onAssetSelected={this.selectAsset}
                            onCheckboxClicked={this.onCheckboxClicked}
                            thumbnailSize={this.state.thumbnailSize}
                            page={this.project.page}
                            assetsSize={this.project.assetsSize}
                            checked={this.project.checked}
                            checkboxes={this.project.checkBoxIds}
                        />
                    </div>
                    <div className="editor-page-content" onClick={this.onPageClick}>
                        <div className="editor-page-content-main">
                            <div className="editor-page-content-main-header">
                                <EditorToolbar project={this.project}
                                               items={this.toolbarItems}
                                               onToolbarItemSelected={this.onToolbarItemSelected}
                                />
                            </div>
                            <div className="editor-page-content-main-body">
                                {this.state.selectedImageLocked && !this.project.checked && this.project.shouldLock && <div className="editor-locker"></div>}
                                {selectedAsset &&
                                <Canvas
                                    ref={this.canvas}
                                    selectedAsset={this.state.selectedAsset}
                                    regionsEmpty={this.state.regionsEmpty}
                                    onAssetMetadataChanged={this.onAssetMetadataChanged}
                                    // onSaveMetadataClicked={this.onSaveClicked}
                                    onCanvasRendered={this.onCanvasRendered}
                                    onSelectedRegionsChanged={this.onSelectedRegionsChanged}
                                    editorMode={this.state.editorMode}
                                    selectionMode={this.state.selectionMode}
                                    project={this.project}
                                    lockedTags={this.state.lockedTags}
                                >
                                    <AssetPreview
                                        autoPlay={true}
                                        controlsEnabled={this.state.isValid}
                                        onBeforeAssetChanged={this.onBeforeAssetSelected}
                                        asset={this.state.selectedAsset.asset}
                                        // @ts-ignore
                                        agentId={this.props.match.params["agentId"]}
                                        thumbnailView={false}/>
                                </Canvas>
                                }
                            </div>
                        </div>
                        <div className="editor-page-right-sidebar">
                            <TagInput
                                tags={this.project.tags}
                                lockedTags={this.state.lockedTags}
                                selectedRegions={this.state.selectedRegions}
                                onLockedTagsChange={this.onLockedTagsChanged}
                                onTagClick={this.onTagClicked}
                                onCtrlTagClick={this.onCtrlTagClicked}
                            />
                            <FilterSliders
                                onChange={this.handleFilterChange}
                                onReset={this.resetFilters}>
                            </FilterSliders>
                        </div>
                    </div>
                </SplitPane>
            </div>
        );
    }

    private onPageClick = () => {
        this.setState({
            selectedRegions: [],
        });
    }
    private onChangeChecked = async () => {
        this.project.checked = !this.project.checked;
        this.setState({isLoading: true}, this.getImages);
    }
    private onSideBarResize = (newWidth: number) => {
        this.setState({
            thumbnailSize: {
                width: newWidth,
                height: newWidth / (4 / 3),
            },
        }, () => this.canvas.current.forceResize());
    }
    private loadProjectAssets = async (projectId: any): Promise<void> => {
        await AIAgentsClient().getAgentsTags(projectId)
            .then(response => {
                this.project = {
                    id: projectId,
                    name: "string",
                    version: "1",
                    useSecurityToken: false,
                    tags: response.data,
                    exportFormat: {providerType: "type"},
                    checked: false,
                    page: 0,
                    checkBoxIds: []
                };
            });
        await AIAgentsClient().getAgentsInfo(projectId)
            .then(response => {
                this.project.shouldLock = response.data.needLockBeforeUpdate
            })
        this.setState({isLoading: true}, this.getImages);
    }

    private getImages = async (): Promise<void> => {
        ImageClient().getImages(this.project.id, this.project.page, 50, this.project.checked).then(x => {
            this.project.assetsSize = x.data.counter;
            const rootAssets: IAsset[] = x.data.list.map(image => {
                let asset: IAsset = {
                    id: image.id,
                    type: 1,
                    state: image.checked ? 2 : 0,
                    name: image.uuid,
                    thumbnail: "https://img.vumo.ai/fastResize/image.jpg?path=" + image.fullPath + "@carscanner-trainer&height=600",
                    url: image.url,
                    format: "jpg"
                }
                if (image.imageValueDtos && image.imageValueDtos.length > 0) {
                    asset.imageValues = image.imageValueDtos
                }
                return asset
            })
            this.setState({
                isLoading: false,
                assets: rootAssets,
            }, async () => {
                if (rootAssets.length > 0) {
                    await this.selectAsset(rootAssets[0]);
                }
            });
            this.project.checkBoxIds = []
            this.toggleToolBarItems();
        })
    }
    /**
     * Raised when the selected asset has been changed.
     * This can either be a parent or child asset
     */
    private onAssetMetadataChanged = async (assetMetadata: IAssetMetadata, save = true): Promise<void> => {
        // If the asset contains any regions without tags, don't proceed.
        const regionsWithoutTags = assetMetadata.regions.filter((region) => region.tags.length === 0);

        if (regionsWithoutTags.length > 0) {
            this.setState({isValid: false});
            return;
        }
        if (!this.state.selectedAsset) {
            return;
        }

        // Only update asset metadata if state changes or is different
        const regions: IRegion[] = assetMetadata.regions
        if (save) {
            assetMetadata.asset.state = assetMetadata.asset.state === AssetState.Saved ? AssetState.Saved : assetMetadata.asset.state === AssetState.Locked ? AssetState.Locked : AssetState.Visited;
            const tags: ITag[] = this.project.tags
            assetMetadata.asset.imageValues = regions.map(region => {
                const type = region.type === RegionType.Rectangle ? ImageValueTypes.BoundingBoxRectangle : ImageValueTypes.BoundingBoxPolygon
                const boxInPercentage = this.pixelsToPercentage(assetMetadata.asset.size, region.boundingBox)
                return {
                    imageValueType: type,
                    objectId: Number(tags.find(x => x.name === region.tags[0]).id), //TODO obsłużyć listę objektów
                    id: region.id,
                    boundingBox: boxInPercentage,
                    points: region.points,
                    certainty: 1
                }
            });
            this.setState({isValid: true, regionsEmpty: assetMetadata.regions.length === 0});
        }
    }

    private async saveAsset(assetMetadata: IAssetMetadata) {
        const imageValues = assetMetadata.asset.imageValues.map(region => {
            delete region.id
            return region
        })
        await ImageClient().markImageValues(Number(assetMetadata.asset.id),
            imageValues).then(x => {
            assetMetadata.asset.imageValues = x.data
        })
        assetMetadata.asset.state = AssetState.Tagged;
        const assets = [...this.state.assets];
        const assetIndex = assets.findIndex((asset) => asset.id === assetMetadata.asset.id);
        if (assetIndex > -1) {
            assets[assetIndex] = {
                ...assetMetadata.asset,
            };
        }
        this.setState({assets, isValid: true});
    }

    private async saveAssetWithoutCheck(assetMetadata: IAssetMetadata) {
        const imageValues = assetMetadata.asset.imageValues.map(region => {
            delete region.id
            return region
        })
        await ImageClient().markImageValues(Number(assetMetadata.asset.id),
            imageValues, false).then(x => {
            assetMetadata.asset.imageValues = x.data;
        })
        assetMetadata.asset.state = AssetState.Saved;
        const assets = [...this.state.assets];
        const assetIndex = assets.findIndex((asset) => asset.id === assetMetadata.asset.id);
        if (assetIndex > -1) {
            assets[assetIndex] = {
                ...assetMetadata.asset,
            };
        }
        this.setState({assets, isValid: true});
    }

    private handleDeleteAssets = (assetMetadata: IAssetMetadata, deleteAll: boolean) => {
        const checkBoxIds = this.project.checkBoxIds
        if (checkBoxIds.length > 0) {
            ImageClient().deleteImages(checkBoxIds, deleteAll)
                .then(async () => {
                    this.state.assets = this.state.assets.filter(asset => {
                        return !checkBoxIds.includes(Number(asset.id));
                    });
                    this.project.assetsSize = this.project.assetsSize - checkBoxIds.length
                    this.project.checkBoxIds = []
                    this.setState({assets: this.state.assets})
                    await this.goToRootAsset(1);
                })
                .catch(err => console.log(err));
            return;
        }
        if (!assetMetadata) {
            return;
        }
        const selectedAssetId = Number(assetMetadata.asset.id);
        ImageClient().deleteImage(selectedAssetId, deleteAll)
            .then(async () => {
                await this.goToRootAsset(1);
                this.state.assets = this.state.assets.filter(x => x.id !== assetMetadata.asset.id);
                this.project.assetsSize = this.project.assetsSize - 1
                this.setState({assets: this.state.assets})
            })
            .catch(err => console.log(err));
    }

    private propagateImages = (assetMetadata: IAssetMetadata, agentsList: number[]) => {
        const imagesIds = this.project.checkBoxIds.length > 0 ? this.project.checkBoxIds : [Number(assetMetadata.asset.id)]
        if (imagesIds.length === 0 || imagesIds[0] === undefined) {
            return;
        }
        ImageClient().propagateImages(imagesIds, agentsList).then(
            async () => {
                this.project.checkBoxIds = []
                toast.info("Images propagated", { position: "bottom-right", delay: 5 });
            }
        ).catch(() => toast.error("Error while propagating images", { position: "bottom-right", delay: 5 }));
    }

    /**
     * Returns a value indicating whether the current asset is taggable
     */
    private isTaggableAssetType = (asset: IAsset): boolean => {
        return asset.type !== AssetType.Unknown;
    }

    private onLockedTagsChanged = (lockedTags: string[]) => {
        this.setState({lockedTags});
    }

    private onToolbarItemSelected = async (toolbarItem: ToolbarItem): Promise<void> => {
        switch (toolbarItem.props.name) {
            case ToolbarItemName.DrawRectangle:
                this.setState({
                    selectionMode: SelectionMode.RECT,
                    editorMode: EditorMode.Rectangle,
                });
                break;
            case ToolbarItemName.DrawPolygon:
                this.setState({
                    selectionMode: SelectionMode.POLYGON,
                    editorMode: EditorMode.Polygon,
                });
                break;
            // case ToolbarItemName.CopyRectangle:
            //     this.setState({
            //         selectionMode: SelectionMode.COPYRECT,
            //         editorMode: EditorMode.CopyRect,
            //     });
            //     break;
            case ToolbarItemName.SelectCanvas:
                this.setState({
                    selectionMode: SelectionMode.NONE,
                    editorMode: EditorMode.Select,
                });
                break;
            case ToolbarItemName.PreviousAsset:
                await this.goToRootAsset(-1);
                break;
            case ToolbarItemName.NextAsset:
                await this.goToRootAsset(1);
                break;
            case ToolbarItemName.NextPage:
                await this.goToPage(1);
                break;
            case ToolbarItemName.PreviousPage:
                await this.goToPage(-1);
                break;
            // case ToolbarItemName.CutRegions:
            //     this.canvas.current.cutRegions();
            //     break;
            // case ToolbarItemName.PasteRegions:
            //     this.canvas.current.pasteRegions();
            //     break;
            case ToolbarItemName.RemoveAllRegions:
                this.canvas.current.confirmRemoveAllRegions();
                break;
            case ToolbarItemName.Undo:
                this.canvas.current.undoChange();
                break;
            case ToolbarItemName.Redo:
                this.canvas.current.redoChange();
                break;
            case ToolbarItemName.Checked:
                await this.onChangeChecked();
                this.toggleToolBarItems();
                break;
            case ToolbarItemName.Save:
                const selectedAsset = this.state.selectedAsset;
                if (this.checkIfInValidAndDisplayWarning()) {
                    return;
                }
                await this.saveAsset(selectedAsset);
                await this.goToRootAsset(1);
                if (!this.project.checked) {
                    this.state.assets = this.state.assets.filter(x => x.id !== selectedAsset.asset.id);
                    this.project.assetsSize = this.project.assetsSize - 1;
                    this.setState({assets: this.state.assets})
                }
                break;
            case ToolbarItemName.SaveOnLater:
                if (this.project.checked) {
                    this.setState({openUncheckSelectedDialog: true});
                } else {
                    if (this.checkIfInValidAndDisplayWarning()) {
                        return;
                    }
                    const assetToSave = this.state.selectedAsset;
                    await this.saveAssetWithoutCheck(assetToSave);
                    await this.goToRootAsset(1);
                }
                break;
            case ToolbarItemName.UnCheckAll:
                this.setState({openUncheckAllDialog: true});
                break;
            case ToolbarItemName.Delete:
                this.setState({openDeleteDialog: true});
                break;
            case ToolbarItemName.Propagate:
                this.setState({openPropagateDialog: true});
                break;
            case ToolbarItemName.History:
                this.setState({openHistoryDialog: true});
                break;
            case ToolbarItemName.Lock:
                const imageId = this.state.selectedAsset.asset.id;
                await ImageClient().lockImage(Number(imageId))
                    .then(()=>{
                        this.toolbarItems = this.toolbarItems.filter(x => x.config.name !== ToolbarItemName.Lock)
                        const currentAsset = this.state.selectedAsset;
                        currentAsset.asset.state = 4;
                        this.state.selectedImageLocked = false;
                        this.lockedImages.push(currentAsset.asset.id)
                        this.setState({selectedAsset: currentAsset})
                })
                    .catch(()=>{
                        this.state.assets = this.state.assets.filter(x => x.id !== imageId)
                        this.project.assetsSize = this.project.assetsSize - 1;
                        this.state.selectedImageLocked = true;
                        this.setState({assets: this.state.assets})
                        this.goToRootAsset(1)
                    })
                break;
        }
    }

    private checkIfInValidAndDisplayWarning(): boolean {
        if (!this.state.isValid) {
            toast.error("Minimum one region doesn't have a tag assigned, add a tag or delete the area!", { position: "bottom-right", delay: 5 });
            const emptyRegion = this.canvas.current.state.currentAsset.regions.find(region => region.tags.length === 0);
            if (emptyRegion) {
                this.canvas.current.editor.RM.selectRegionById(emptyRegion.id);
            }
            return true;
        }

        return false;
    }

    /**
     * Navigates to the previous / next root asset on the sidebar
     * @param direction Number specifying asset navigation
     */
    private goToRootAsset = async (direction: number) => {
        const selectedRootAsset = this.state.selectedAsset.asset.parent || this.state.selectedAsset.asset;
        const currentIndex = this.state.assets
            .findIndex((asset) => asset.id === selectedRootAsset.id);

        if (direction > 0) {
            await this.selectAsset(this.state.assets[Math.min(this.state.assets.length - 1, currentIndex + 1)]);
        } else {
            await this.selectAsset(this.state.assets[Math.max(0, currentIndex - 1)]);
        }
    }
    /**
     * Raised when the asset binary has been painted onto the canvas tools rendering canvas
     */
    private onCanvasRendered = async (canvas: HTMLCanvasElement) => {
        this.canvasArea = canvas;
        const assetMetadata = this.state.selectedAsset;
        if (this.state.selectedAsset.asset.imageValues && this.state.selectedAsset.asset.imageValues.length > 0) {
            const imageSize = assetMetadata.asset.size;
            const tags = this.project.tags;
            assetMetadata.regions = assetMetadata.asset.imageValues.map(x => {
                let points: IPoint[], boundingBox: IBoundingBox, type: RegionType;
                if (x.imageValueType === ImageValueTypes.BoundingBoxRectangle) {
                    points = this.percentageToPoints(imageSize, (x as BoundingBoxRectangleImageValue).boundingBox);
                    boundingBox = this.percentageToPixels(imageSize, (x as BoundingBoxRectangleImageValue).boundingBox);
                    type = RegionType.Rectangle;
                } else {
                    points = (x as BoundingBoxPolygonImageValue).points;
                    boundingBox = this.pointsToBoundingBox(imageSize, (x as BoundingBoxPolygonImageValue).points);
                    type = RegionType.Polygon;
                }
                return {
                    id: x.id,
                    type: type,
                    tags: [tags.find(y => x.objectId === y.id).name],
                    points: points,
                    boundingBox: boundingBox,
                } as IRegion
            })
            this.setState({selectedAsset: assetMetadata});
        }
        await this.onAssetMetadataChanged(assetMetadata);
    }

    private onSelectedRegionsChanged = (selectedRegions: IRegion[]) => {
        this.setState({selectedRegions});
    }
    private onChildAssetSelected = async (childAsset: IAsset) => {
        if (this.state.selectedAsset && this.state.selectedAsset.asset.id !== childAsset.id) {
            await this.selectAsset(childAsset);
        }
    }
    private onBeforeAssetSelected = (): boolean => {
        if (!this.state.isValid) {
            this.setState({showInvalidRegionWarning: true});
        }

        return this.state.isValid;
    }

    private selectAsset = async (asset: IAsset): Promise<void> => {
        // Nothing to do if we are already on the same asset.
        if (this.state.selectedAsset && this.state.selectedAsset.asset.id === asset.id) {
            return;
        }
        if (!this.state.isValid) {
            this.setState({showInvalidRegionWarning: true});
            return;
        }
        const assetMetadata = {
            asset: asset,
            regions: [],
            version: "1"
        }
        try {
            if (!asset.size) {
                const assetProps = await HtmlFileReader.readAssetAttributes(asset);
                assetMetadata.asset.size = {width: assetProps.width, height: assetProps.height};
            }
        } catch (err) {
            console.warn("Error computing asset size");
        }

        this.setState({
            selectedAsset: {
                asset: asset,
                regions: assetMetadata.regions,
                version: "1"
            },
            selectedImageLocked: !this.lockedImages.includes(asset.id),
        }, async () => {
            await this.onAssetMetadataChanged(assetMetadata, false);
            this.toggleToolBarItems();
        });
    }

    //CONVERTERS
    private pixelsToPercentage = (imageSize: ISize, bounding: IBoundingBox): IBoundingBox => {
        return {
            left: bounding.left / imageSize.width,
            top: bounding.top / imageSize.height,
            width: bounding.width / imageSize.width,
            height: bounding.height / imageSize.height
        }
    }
    private percentageToPixels = (imageSize: ISize, bounding: IBoundingBox): IBoundingBox => {
        return {
            left: bounding.left * imageSize.width,
            top: bounding.top * imageSize.height,
            width: bounding.width * imageSize.width,
            height: bounding.height * imageSize.height
        }
    }
    private percentageToPoints = (imageSize: ISize, bounding: IBoundingBox): IPoint[] => {
        let boundingBox = {
            left: bounding.left * imageSize.width,
            top: bounding.top * imageSize.height,
            width: bounding.width * imageSize.width,
            height: bounding.height * imageSize.height
        }
        return [{x: boundingBox.left, y: boundingBox.top},
            {x: boundingBox.left, y: (boundingBox.top + boundingBox.height)},
            {x: (boundingBox.left + boundingBox.width), y: (boundingBox.top + boundingBox.height)},
            {x: (boundingBox.left + boundingBox.width), y: boundingBox.top}]
    }

    private pointsToBoundingBox = (imageSize: ISize, points: IPoint[]): IBoundingBox => {
        const xCoordinates = points.map(point => point.x);
        const yCoordinates = points.map(point => point.y);
        const left = Math.min(...xCoordinates);
        const right = Math.max(...xCoordinates);
        const bottom = Math.max(...yCoordinates);
        const top = Math.min(...yCoordinates);
        return {
            left: left,
            top: top,
            width: right - left,
            height: bottom - top
        }
    }

    /**
     * Listens for {number key} and calls `onTagClicked` with tag corresponding to that number
     * @param event KeyDown event
     */
    private handleTagHotKey = (event: KeyboardEvent): void => {
        const tag = this.getTagFromKeyboardEvent(event);
        if (tag) {
            this.onTagClicked(tag);
        }
    }

    private handleCtrlTagHotKey = (event: KeyboardEvent): void => {
        const tag = this.getTagFromKeyboardEvent(event);
        if (tag) {
            this.onCtrlTagClicked(tag);
        }
    }
    private onTagClicked = (tag: ITag): void => {
        this.setState({
            selectedTag: tag.name,
            lockedTags: [],
        }, () => this.canvas.current.applyTag(tag.name));
    }
    private onCtrlTagClicked = (tag: ITag): void => {
        const locked = this.state.lockedTags;
        this.setState({
            selectedTag: tag.name,
            lockedTags: CanvasHelpers.toggleTag(locked, tag.name),
        }, () => this.canvas.current.applyTag(tag.name));
    }
    private getTagFromKeyboardEvent = (event: KeyboardEvent): ITag => {
        let key = parseInt(event.key, 10);
        if (isNaN(key)) {
            try {
                key = parseInt(event.key.split("+")[1], 10);
            } catch (e) {
                return;
            }
        }
        let index: number;
        const tags = this.project.tags;
        if (key === 0 && tags.length >= 10) {
            index = 9;
        } else if (key < 10) {
            index = key - 1;
        }
        if (index < tags.length) {
            return tags[index];
        }
        return null;
    }

    private async goToPage(direction: number) {
        if ((this.project.page+1) * 50 >= this.project.assetsSize && direction === 1) {
            return;
        }
        if (this.project.page + direction >= 0) {
            this.project.page = this.project.page + direction
            this.setState({isLoading: true}, this.getImages);
        }
    }

    private toggleToolBarItems(): void {
        if (this.project.checked === undefined) {
            return;
        }
        ToolbarItemFactory.reset()
        registerToolbar();
        const tools = ToolbarItemFactory.getToolbarItems()
        ToolbarItemFactory.reset()

        if (this.project.checked === true) {
            tools.forEach(toolBar => {
                if (toolBar.config.name === ToolbarItemName.SaveOnLater) {
                    ToolbarItemFactory.register({
                        name: ToolbarItemName.SaveOnLater,
                        tooltip: strings.editorPage.toolbar.unCheckSelected,
                        icon: "far fa-reply",
                        group: ToolbarItemGroup.Asset,
                        type: ToolbarItemType.Action,
                        accelerators: ["Alt+Shift"]
                    });
                    ToolbarItemFactory.register({
                        name: ToolbarItemName.UnCheckAll,
                        tooltip: strings.editorPage.toolbar.unCheckAll,
                        icon: "far fa-reply-all",
                        group: ToolbarItemGroup.Asset,
                        type: ToolbarItemType.Action
                    });
                } else if (toolBar.config.name !== ToolbarItemName.Lock && toolBar.config.name !== ToolbarItemName.UnCheckAll) {
                    ToolbarItemFactory.register(toolBar.config)
                }
            })
            this.toolbarItems = ToolbarItemFactory.getToolbarItems()
        } else if (this.project.checked === false) {
            tools.forEach(toolBar => {
                if (toolBar.config.name === ToolbarItemName.SaveOnLater) {
                    ToolbarItemFactory.register({
                        name: ToolbarItemName.SaveOnLater,
                        tooltip: strings.editorPage.toolbar.saveOnLater,
                        icon: "far fa-bookmark",
                        group: ToolbarItemGroup.Asset,
                        type: ToolbarItemType.Action,
                        accelerators: ["Alt+Shift"]
                    });
                } else if (toolBar.config.name !== ToolbarItemName.UnCheckAll && toolBar.config.name !== ToolbarItemName.Lock) {
                    ToolbarItemFactory.register(toolBar.config)
                }
            })
            if (this.state.selectedImageLocked && this.project.shouldLock) {
                ToolbarItemFactory.register({
                    name: ToolbarItemName.Lock,
                    tooltip: strings.editorPage.toolbar.lock,
                    icon: "far fa-lock",
                    group: ToolbarItemGroup.Asset,
                    type: ToolbarItemType.Action
                });
            }
            this.toolbarItems = ToolbarItemFactory.getToolbarItems()
        }
    }

    private onCheckboxClicked = (imageId: number): void => {
        if (this.project.checkBoxIds.includes(imageId)) {
            this.project.checkBoxIds = this.project.checkBoxIds.filter(imgId => imgId !== imageId)
        } else {
            this.project.checkBoxIds.push(imageId)
        }

        this.setState({})
    }

    private handleUncheckAll = async (): Promise<void> => {
        const agentId = this.project.id;
        await ImageClient().unCheckImages({agentId: Number(agentId)}).then(()=>{
            this.state.assets = [];
            this.project.page = 0;
            this.project.assetsSize = 0;
            this.setState({assets: this.state.assets})
        })
    }

    private handleUncheckSelected = async (): Promise<void> => {
        if (this.project.checkBoxIds.length > 0) {
            await ImageClient().unCheckImages({imageIds: this.project.checkBoxIds}).then(()=>{
                this.state.assets = this.state.assets.filter(x => !this.project.checkBoxIds.includes(Number(x.id)))
                this.project.assetsSize = this.project.assetsSize - this.project.checkBoxIds.length;
                this.project.checkBoxIds = []
                this.setState({assets: this.state.assets})
                this.goToRootAsset(1)
            })
        } else {
            const assetToSave = this.state.selectedAsset;
            await ImageClient().unCheckImages({imageId: Number(assetToSave.asset.id)}).then(()=>{
                this.state.assets = this.state.assets.filter(x => x.id !== assetToSave.asset.id);
                this.project.assetsSize = this.project.assetsSize - 1;
                this.project.checkBoxIds = []
                this.setState({assets: this.state.assets})
                this.goToRootAsset(1)
            })
        }
    }
}
