import {Button, TextField, Typography} from "@material-ui/core";
import React, {useEffect, useState} from 'react';
import {LoginUserData} from "../../common/_dto/LoginUserData";
import './Login.css'
import validate from 'validate.js';
import {schema} from "./schema";
import {useAuth} from "../../context/AuthenticationContext";
import {Redirect, useHistory} from 'react-router-dom';
import {HOME_PATH} from "../../utils/path";
import jwtDecode from 'jwt-decode';
import {AuthClient} from "../../common/_clients/AuthClient";
import {LocalStorageHelper} from "../../context/LocalStorageHelper";
import {useLoading} from "../../context/LoadingContext";


export default function Login() {
    const {isAuthenticated, setAuthenticated, error, clearError} = useAuth();
    const {setLoading} = useLoading();
    const history = useHistory();
    const [formState, setFormState] = useState({
        isValid: false,
        touched: {},
        values: {username: '', password: ''} as LoginUserData,
        errors: {username: '', password: ''} as LoginUserData
    });

    useEffect(() => {
        return () => clearError()
    }, [])

    useEffect(() => {
        const errors = validate(formState.values, schema);
        setFormState(formState => ({
            ...formState,
            isValid: !errors,
            errors: errors || {}
        }));
    }, [formState.values]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();

        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]: event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            }
        }));
    };

    const hasError = (field: string): boolean => {
        // @ts-ignore
        return !!(formState.touched[field] && formState.errors[field]);
    };

    const handleSignIn = (event: React.FormEvent) => {
        event.preventDefault();
        login().catch(e => console.error(e.message));
    };

    const login = async (): Promise<void> => {
        setLoading(true);
        clearError()
        LocalStorageHelper().logout()
        await AuthClient().loginUser(formState.values)
            .then((res) => {
                    LocalStorageHelper().setToken(res.data.access_token);
                    LocalStorageHelper().setRefreshToken(res.data.refresh_token)
                    setAuthenticated(true);
                    const decode = jwtDecode(res.data.access_token);
                    // @ts-ignore
                    const username: string = decode.username;
                    // @ts-ignore
                    const role: string = decode.properties.role;
                    LocalStorageHelper().setUsername(username);
                    LocalStorageHelper().setRole(role);
                    // @ts-ignore
                    // const system: string = decode.system;
                    // if (system) {
                    //     LocalStorageHelper().setSystem(system)
                    // }
                    history.push(HOME_PATH);
                    window.location.reload();
                    setLoading(false);
                },
                (error) => {
                    const errors = validate(formState.values, schema) || {};
                    const {message} = error.response.data;
                    console.log(message)
                    if (error.response.status === 400) {
                        if (message.includes('password')) {
                            errors.password = [message];
                        } else {
                            errors.username = [message];
                        }
                        setFormState(formState => ({
                            ...formState,
                            isValid: !errors,
                            errors: errors || {}
                        }));
                    }
                    if (error.response.status === 404) {
                        if (message.includes('Username')) {
                            errors.username = [message];
                        } else {
                            errors.password = [message];
                        }
                        setFormState(formState => ({
                            ...formState,
                            isValid: !errors,
                            errors: errors || {}
                        }));
                    }
                    setLoading(false);
                });
    }
    return (
        isAuthenticated === true ? <Redirect to={'/home'}/> :
            <div className="signInContainer">
                <Typography className='title' variant="h5">
                    Login
                </Typography>
                <form className='form' onSubmit={handleSignIn}>
                    <TextField
                        className='textField'
                        error={hasError('username')}
                        fullWidth
                        helperText={hasError('username') && formState.errors.username[0]}
                        label="Username"
                        name="username"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.username || ''}
                        variant="outlined"
                    />
                    <TextField
                        className='textField'
                        error={hasError('password')}
                        fullWidth
                        helperText={hasError('password') && formState.errors.password[0]}
                        label="Hasło"
                        name="password"
                        onChange={handleChange}
                        type="password"
                        value={formState.values.password || ''}
                        variant="outlined"
                    />
                    {error !== '' && <p className={'error'}>{error}</p>}
                    <Button
                        className='signUpButton'
                        color="primary"
                        disabled={!formState.isValid}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained">
                        Login
                    </Button>
                </form>
            </div>
    );
}
