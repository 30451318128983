import React, {createContext, FC, useContext, useState} from "react";

export type LoadingContextState = {
    loading: boolean
    setLoading: (loading: boolean) => void
}
const LoadingContext = createContext<LoadingContextState | null>(null);

const LoadingProvider: FC = ({children}) => {
    const [loading, setLoading] = useState(false)

    return <LoadingContext.Provider value={{
        loading,
        setLoading
    }}>{children}</LoadingContext.Provider>
}

export const useLoading = () => {
    const data = useContext(LoadingContext)

    if (data === null) {
        throw new Error('Before use `useLoading` need add `LoadingProvider`')
    }

    return data;
};

export default LoadingProvider
