import {AssetType, IAsset, IAssetText, IFileInfo} from "../models/applicationState";
import Guard from "./guard";

/**
 * Helper class for reading HTML files
 */
export default class HtmlFileReader {

    /**
     * Reads the file and returns the string value contained
     * @param file HTML file to read
     */
    public static readAsText(file: File): Promise<IFileInfo> {
        Guard.null(file);
        let fileInfo: IFileInfo;

        return new Promise<IFileInfo>((resolve, reject) => {
            const reader = new FileReader();
            reader.onerror = reject;
            reader.onload = () => {
                if (reader.result) {
                    fileInfo = {
                        content: reader.result,
                        file,
                    };
                    resolve(fileInfo);
                } else {
                    reject();
                }
            };

            try {
                reader.readAsText(file);
            } catch (err) {
                reject(err);
            }
        });
    }

    /**
     * Reads attributes from asset depending on type (video or image)
     * @param asset Asset to read from
     */
    public static async readAssetAttributes(asset: IAsset | IAssetText)
        : Promise<{ width: number, height: number, duration?: number }> {
        Guard.null(asset);

        if (asset.type=== AssetType.Image) {
            return await this.readImageAttributes(asset.url);
        } else {
            throw new Error("Asset not supported");
        }

    }

    public static async readAssetAttributesWithBuffer(base64: string)
        : Promise<{ width: number, height: number, duration?: number }> {
        Guard.null(base64);

        return await this.readImageAttributes("data:image;base64," + base64);
    }

    private static readImageAttributes(url: string): Promise<{ width: number, height: number }> {
        return new Promise((resolve, reject) => {
            const image = document.createElement("img") as HTMLImageElement;
            image.onload = () => {
                resolve({
                    width: image.naturalWidth,
                    height: image.naturalHeight,
                });
            };
            image.onerror = reject;
            image.src = url;
        });
    }
}
