import React, { Fragment, ReactElement } from "react";
import * as shortid from "shortid";
import { CanvasTools } from "vott-ct";
import { RegionData } from "vott-ct/lib/js/CanvasTools/Core/RegionData";
import { EditorMode, IAssetMetadata, IProject, IRegion, RegionType, } from "../../../models/applicationState";
import CanvasHelpers from "./canvasHelpers";
import { AssetPreview, ContentSource } from "../../common/assetPreview/assetPreview";
import { Editor } from "vott-ct/lib/js/CanvasTools/CanvasTools.Editor";
import Clipboard from "../../../common/clipboard";
import { strings } from "../../utils/strings";
import { SelectionMode } from "vott-ct/lib/js/CanvasTools/Interface/ISelectorSettings";
import { Rect } from "vott-ct/lib/js/CanvasTools/Core/Rect";
import { createContentBoundingBox } from "../../../common/layout";
import ConfirmDialog from "../../common/confirmDialog/ConfirmDialog";
import "./canvas.scss";
import {cloneDeep, toInteger} from "lodash";
import { changeActions } from "../../utils/changeActions";

export interface ICanvasProps extends React.Props<Canvas> {
    selectedAsset: IAssetMetadata;
    editorMode: EditorMode;
    selectionMode: SelectionMode;
    project: IProject;
    lockedTags: string[];
    children?: ReactElement<AssetPreview>;
    onAssetMetadataChanged?: (assetMetadata: IAssetMetadata) => void;
    onSelectedRegionsChanged?: (regions: IRegion[]) => void;
    onCanvasRendered?: (canvas: HTMLCanvasElement) => void;
    regionsEmpty?: boolean;
}

export interface ICanvasState {
    currentAsset: IAssetMetadata;
    contentSource: ContentSource;
    enabled: boolean;
    openDialog: boolean;
    copiedRegions: any,
}

export default class Canvas extends React.Component<ICanvasProps, ICanvasState> {
    readonly ZOOM_MIN = 0;
    readonly ZOOM_MAX = 10;
    readonly ZOOM_AMOUNT = 1.1;
    public state: ICanvasState = {
        currentAsset: this.props.selectedAsset,
        contentSource: null,
        enabled: false,
        openDialog: false,
        copiedRegions: null,
    };
    public editor: Editor;
    private canvasZone: React.RefObject<HTMLDivElement> = React.createRef();
    private editorZone: React.RefObject<HTMLDivElement> = React.createRef();
    private template: Rect = new Rect(20, 20);
    private scale = 1;
    private prevTop = null;
    private prevLeft = null;
    private drag = false;
    private mouseX;
    private mouseY;
    private prevMouseX;
    private prevMouseY;
    private shiftPressed = false;
    private changeTransparencyKeyCode = 20;
    private ctrlPressed = false;
    private hideRegionsKeyCode = 192;
    private regionsDrawn = false;
    private regionsFreezed = false;
    // private moveObject = {
    //     selected: false,
    //     direction: null
    // }
    private actionHistory = []
    private redoHistory = []
    private pasteRegionsCounter = 0
    private resetRedoHistoryCounter = 0
    private isPolgyonAutoPointsPressed = false;
    private prevMousePosition = { x: 0, y: 0 };

    public componentDidMount = () => {
        const sz = document.getElementById("editor-zone") as HTMLDivElement;
        this.editor = new CanvasTools.Editor(sz);
        this.editor.autoResize = false;
        this.editor.onSelectionEnd = this.onSelectionEnd;
        this.editor.onRegionMoveEnd = this.onRegionMoveEnd;
        this.editor.onRegionDelete = this.onRegionDelete;
        this.editor.onRegionSelected = this.onRegionSelected;
        this.editor.AS.setSelectionMode({mode: this.props.selectionMode});
        window.addEventListener("resize", this.onWindowResize);
        window.addEventListener("keyup", this.handleControlKeyUp);
        window.addEventListener("keydown", this.handleControlKeyDown);
        this.canvasZone.current.addEventListener("mousemove", this.mouseOverControl);
        document.querySelector('svg.menuLayer').addEventListener('click', (event)=>event.stopPropagation())
    }

    public componentWillUnmount() {
        window.removeEventListener("resize", this.onWindowResize);
        window.removeEventListener("keyup", this.handleControlKeyUp);
        window.removeEventListener("keydown", this.handleControlKeyDown);
        this.canvasZone.current.removeEventListener("mousemove", this.mouseOverControl);
    }

    public componentDidUpdate = async (prevProps: Readonly<ICanvasProps>, prevState: Readonly<ICanvasState>) => {
        // Handle reset regions drawn flag and actions history
        if (this.state.currentAsset.asset.id !== prevState.currentAsset.asset.id) {
            this.actionHistory = []
            this.redoHistory = []
            this.regionsDrawn = false;
        }

        // Handles asset changing
        if (this.props.selectedAsset !== prevProps.selectedAsset) {
            this.regionsDrawn = false;
            this.prevTop = null;
            this.prevLeft = null;
            this.scale = 1;
            this.setState({currentAsset: this.props.selectedAsset});
            // this.refreshCanvasToolsRegions();
        }
        // Handle selection mode changes
        if (this.props.selectionMode !== prevProps.selectionMode) {
            const options: any = (this.props.selectionMode === SelectionMode.COPYRECT) ? this.template : null;

            this.editor.AS.setSelectionMode({mode: this.props.selectionMode, template: options});
        }

        const assetIdChanged = this.state.currentAsset.asset.id !== prevState.currentAsset.asset.id;

        //remove bug that selected asset dont shows regions
        if (this.editor.RM.getSelectedRegions().length === 0 && this.state.currentAsset.regions?.length > 0) {
            this.refreshCanvasToolsRegions();
        }
        // When the selected asset has changed but is still the same asset id
        if (!assetIdChanged && this.state.currentAsset !== prevState.currentAsset) {
            this.refreshCanvasToolsRegions();
        }
        // this.refreshCanvasToolsRegions();
        // When the project tags change re-apply tags to regions
        if (this.props.project.tags !== prevProps.project.tags) {
            this.updateCanvasToolsRegionTags();
        }

        // Handles when the canvas is enabled & disabled
        if (prevState.enabled !== this.state.enabled) {
            // When the canvas is ready to display
            if (this.state.enabled) {
                this.refreshCanvasToolsRegions();
                this.setContentSource(this.state.contentSource);
                this.editor.AS.setSelectionMode(this.props.selectionMode);
                this.editor.AS.enable();

                if (this.props.onSelectedRegionsChanged) {
                    this.props.onSelectedRegionsChanged(this.getSelectedRegions());
                }
            } else { // When the canvas has been disabled
                this.editor.AS.disable();
                this.clearAllRegions();
                this.editor.AS.setSelectionMode(SelectionMode.NONE);
            }
        }
    }

    public render = () => {
        const className = this.state.enabled ? "canvas-enabled" : "canvas-disabled";

        return (
            <Fragment>
                <ConfirmDialog
                    title={strings.editorPage.canvas.removeAllRegions.title}
                    open={this.state.openDialog}
                    setOpen={this.setOpenDialog}
                    onConfirm={this.removeAllRegions}
                >
                    {strings.editorPage.canvas.removeAllRegions.confirmation}
                </ConfirmDialog>
                <div id="ct-zone" ref={this.canvasZone}
                     className={className}
                     onClick={(e) => e.stopPropagation()}
                     onMouseMove={this.handleMouseEvent}
                     onMouseDown={this.handleMouseEvent}
                     onMouseUp={this.handleMouseEvent}
                     onMouseOut={this.handleMouseEvent}
                     onWheel={this.handleMouseWheelEvent}
                >
                    <div id="selection-zone">
                        <div ref={this.editorZone} id="editor-zone" className="full-size"/>
                    </div>
                </div>
                {this.renderChildren()}
            </Fragment>
        );
    }

    /**
     * Toggles tag on all selected regions
     * @param tag
     */
    public applyTag = (tag: string) => {
        const selectedRegions = this.getSelectedRegions();
        const lockedTags = this.props.lockedTags;
        const lockedTagsEmpty = !lockedTags || !lockedTags.length;
        const regionsEmpty = !selectedRegions || !selectedRegions.length;
        if ((!tag && lockedTagsEmpty) || regionsEmpty) {
            return;
        }
        let transformer: (tags: string[], tag: string) => string[];
        if (lockedTagsEmpty) {
            // Tag selected while region(s) selected
            transformer = CanvasHelpers.toggleTag;
        } else if (lockedTags.find((t) => t === tag)) {
            // Tag added to locked tags while region(s) selected
            transformer = CanvasHelpers.addIfMissing;
        } else {
            // Tag removed from locked tags while region(s) selected
            transformer = CanvasHelpers.removeIfContained;
        }
        for (const selectedRegion of selectedRegions) {
            selectedRegion.tags = transformer(selectedRegion.tags, tag);
        }
        this.updateRegions(selectedRegions);
        if (this.props.onSelectedRegionsChanged) {
            this.props.onSelectedRegionsChanged(selectedRegions);
        }
        this.addToUndoHistory({id: changeActions.APPLY_TAG, region: selectedRegions[0], tag: tag})
    }

    public copyRegions = async () => {
        await Clipboard.writeObject(this.getSelectedRegions());
    }

    public cutRegions = async () => {
        const selectedRegions = this.getSelectedRegions();
        await Clipboard.writeObject(selectedRegions);
        this.deleteRegions(selectedRegions);
    }

    public pasteRegions = async () => {
        const regionsToPaste: IRegion[] = await Clipboard.readObject();
        const asset = this.state.currentAsset;
        const duplicates = CanvasHelpers.duplicateRegionsAndMove(
            regionsToPaste,
            asset.regions,
            asset.asset.size.width,
            asset.asset.size.height,
        );
        this.addRegions(duplicates);
    }

    public confirmRemoveAllRegions = () => {
        this.setState({openDialog: true})
    }

    public getSelectedRegions = (): IRegion[] => {
        const selectedRegions = this.editor.RM.getSelectedRegions().map(rb => rb.id);
        return this.state.currentAsset.regions.filter(r => selectedRegions.find(id => r.id === id));
    }

    public getRegion = (id: string): IRegion[] => {
        return this.state.currentAsset.regions.filter(r => r.id === id);
    }

    public getRegionBounds = (id: string): { id: string, x: number, y: number, width: number, height: number } => {
        const selectedRegions = this.getRegion(id);
        if (selectedRegions && selectedRegions.length > 0) {
            const selectedRegion = selectedRegions[0];
            return {
                id: selectedRegion.id,
                x: selectedRegion.boundingBox.left,
                y: selectedRegion.boundingBox.top,
                width: selectedRegion.boundingBox.width,
                height: selectedRegion.boundingBox.height
            }
        }
        return null;
    }

    public updateCanvasToolsRegionTags = (): void => {
        for (const region of this.state.currentAsset.regions) {
            this.editor.RM.updateTagsById(
                region.id,
                CanvasHelpers.getTagsDescriptor(this.props.project.tags, region),
            );
        }
    }

    public forceResize = (): void => {
        this.onWindowResize();
    }

    /**
     * Method that handles mouse events
     * @param e {React.MouseEvent<HTMLDivElement>} Mouse event
     */
    private handleMouseEvent = (e: React.MouseEvent<HTMLElement>): void => {
        if ((this.props.editorMode !== EditorMode.Select && this.props.editorMode !== EditorMode.None && this.props.editorMode !== EditorMode.Polygon) || (e.target as HTMLElement).tagName !== "svg" || (e.button === 0 && e.type !== 'mousemove' && this.props.editorMode === EditorMode.Polygon)){
            return;
        }
        e.stopPropagation();
        this.prevMouseX = this.mouseX;
        this.prevMouseY = this.mouseY;
        this.mouseX = e.pageX;
        this.mouseY = e.pageY;
        if (e.type === "mousedown") {
            this.drag = true;
        } else if (e.type === "mouseup" || e.type === "mouseout") {
            this.drag = false;
        }
        if (this.drag) {
            this.moveCanvas(this.state.contentSource);
        }
    }

    /**
     * Undo change
     */
    public undoChange(): void {
        if (this.actionHistory.length === 0) {
            return;
        }
        const lastAction = this.actionHistory[this.actionHistory.length-1]

        switch (lastAction.id){

            case changeActions.ADD_REGION:
                this.resetRedoHistoryCounter = 1;
                this.editor.RM.deleteRegionById(lastAction.region.id)
                this.actionHistory.pop()
                this.actionHistory.pop()
                this.addToRedoHistory({id: changeActions.REMOVE_REGION, region: {...lastAction.region}})
                break;

            case changeActions.REMOVE_REGION:
                const loadedRegionData = CanvasHelpers.getRegionData(lastAction.region);
                this.editor.RM.addRegion(
                    lastAction.region.id,
                    this.editor.scaleRegionToFrameSize(
                        loadedRegionData,
                        this.state.currentAsset.asset.size.width,
                        this.state.currentAsset.asset.size.height,
                    ),
                    CanvasHelpers.getTagsDescriptor(this.props.project.tags, lastAction.region));
                this.updateAssetRegions([...this.state.currentAsset.regions, lastAction.region]);
                if (this.props.onSelectedRegionsChanged) {
                    this.props.onSelectedRegionsChanged([lastAction.region]);
                }
                this.actionHistory.pop()
                this.addToRedoHistory({id: changeActions.ADD_REGION, region: {...lastAction.region}})
                break;

            case changeActions.APPLY_TAG:
                this.resetRedoHistoryCounter = 1;
                this.editor.RM.selectRegionById(lastAction.region.id)
                this.applyTag(lastAction.tag)
                this.actionHistory.pop()
                this.actionHistory.pop()
                this.addToRedoHistory({id: changeActions.APPLY_TAG, region: lastAction.region, tag: lastAction.tag})
                break;

            case changeActions.MOVE_REGION:
                this.resetRedoHistoryCounter = 1;
                this.editor.RM.deleteRegionById(lastAction.regionId)
                this.editor.RM.addRegion(
                    lastAction.regionId,
                    this.editor.scaleRegionToFrameSize(
                        lastAction.oldRegionData,
                        this.state.currentAsset.asset.size.width,
                        this.state.currentAsset.asset.size.height,
                    ),
                    CanvasHelpers.getTagsDescriptor(this.props.project.tags, lastAction.oldRegion));
                this.updateAssetRegions([...this.state.currentAsset.regions, lastAction.oldRegion]);
                if (this.props.onSelectedRegionsChanged) {
                    this.props.onSelectedRegionsChanged([lastAction.oldRegion]);
                }
                this.setState({})
                this.actionHistory.pop()
                this.actionHistory.pop()
                this.addToRedoHistory({id: changeActions.MOVE_REGION, oldRegion: lastAction.newRegion, oldRegionData: lastAction.newRegionData, newRegion: lastAction.oldRegion, newRegionData: lastAction.oldRegionData, regionId: lastAction.regionId})
                break;

                case changeActions.PASTE_REGIONS:
                    this.resetRedoHistoryCounter = 1;
                    this.pasteRegionsCounter = lastAction.regions.length;
                    lastAction.regions.forEach((region) => {
                        this.editor.RM.deleteRegionById(region.id)
                    });

                    this.actionHistory.pop();
                    this.addToRedoHistory({id: changeActions.PASTE_REGIONS, regions: lastAction.regions})
                break;

            case changeActions.MOVE_REGIONS:
                this.resetRedoHistoryCounter = 1;
                this.actionHistory.pop();
                const idsToCopy = lastAction.regions.map((region) => region.id);
                const regionsToCopy = this.state.currentAsset.regions.filter((region) => idsToCopy.includes(region.id));
                this.addToRedoHistory({id: changeActions.MOVE_REGIONS, regions: cloneDeep(regionsToCopy)})
                lastAction.regions.forEach((region) => {
                    if(region.regionData != undefined) {
                        this.onRegionMoveEnd(region.id, region.regionData, false);
                    } else {
                        this.onRegionMoveEnd(region.id, CanvasHelpers.getRegionData(region), false, false);
                    }

                });
                this.clearAllRegions();
                this.regionsDrawn = false;
                this.refreshCanvasToolsRegions(true);
                break;

            default:
                break;
        }
    }

    /**
     * Redo change
     */

    public redoChange(): void {
        if (this.redoHistory.length === 0) {
            return;
        }
        const lastAction = this.redoHistory[this.redoHistory.length-1]

        switch (lastAction.id){

            case changeActions.ADD_REGION:
                this.resetRedoHistoryCounter = 2;
                this.editor.RM.deleteRegionById(lastAction.region.id)
                this.actionHistory.pop()
                this.redoHistory.pop()
                this.addToUndoHistory({id: changeActions.REMOVE_REGION, region: {...lastAction.region}})
                break;

            case changeActions.REMOVE_REGION:
                this.resetRedoHistoryCounter = 1;
                const loadedRegionData = CanvasHelpers.getRegionData(lastAction.region);
                this.editor.RM.addRegion(
                    lastAction.region.id,
                    this.editor.scaleRegionToFrameSize(
                        loadedRegionData,
                        this.state.currentAsset.asset.size.width,
                        this.state.currentAsset.asset.size.height,
                    ),
                    CanvasHelpers.getTagsDescriptor(this.props.project.tags, lastAction.region));
                this.updateAssetRegions([...this.state.currentAsset.regions, lastAction.region]);
                if (this.props.onSelectedRegionsChanged) {
                    this.props.onSelectedRegionsChanged([lastAction.region]);
                }
                this.redoHistory.pop()
                this.addToUndoHistory({id: changeActions.ADD_REGION, region: {...lastAction.region}})
                break;

            case changeActions.APPLY_TAG:
                this.resetRedoHistoryCounter = 2;
                this.editor.RM.selectRegionById(lastAction.region.id)
                this.applyTag(lastAction.tag)
                this.redoHistory.pop()
                this.actionHistory.pop()
                this.addToUndoHistory({id: changeActions.APPLY_TAG, region: lastAction.region, tag: lastAction.tag})
                break;

            case changeActions.MOVE_REGION:
                this.resetRedoHistoryCounter = 2;
                this.editor.RM.deleteRegionById(lastAction.regionId)
                this.editor.RM.addRegion(
                    lastAction.regionId,
                    this.editor.scaleRegionToFrameSize(
                        lastAction.oldRegionData,
                        this.state.currentAsset.asset.size.width,
                        this.state.currentAsset.asset.size.height,
                    ),
                    CanvasHelpers.getTagsDescriptor(this.props.project.tags, lastAction.oldRegion));
                this.updateAssetRegions([...this.state.currentAsset.regions, lastAction.oldRegion]);
                if (this.props.onSelectedRegionsChanged) {
                    this.props.onSelectedRegionsChanged([lastAction.oldRegion]);
                }
                this.actionHistory.pop()
                this.redoHistory.pop()
                this.addToUndoHistory({id: changeActions.MOVE_REGION, oldRegion: lastAction.newRegion, oldRegionData: lastAction.newRegionData, newRegion: lastAction.oldRegion, newRegionData: lastAction.oldRegionData, regionId: lastAction.regionId})
                break;

                case changeActions.PASTE_REGIONS:
                    this.resetRedoHistoryCounter = 2;
                    this.addRegions(lastAction.regions)
                    this.redoHistory.pop();
                    this.addToUndoHistory({id: changeActions.PASTE_REGIONS, regions: lastAction.regions})
                break;

            case changeActions.MOVE_REGIONS:
                this.resetRedoHistoryCounter = 2;
                const idsToCopy = lastAction.regions.map((region) => region.id);
                const regionsToCopy = this.state.currentAsset.regions.filter((region) => idsToCopy.includes(region.id));
                this.addToUndoHistory({id: changeActions.MOVE_REGIONS, regions: cloneDeep(regionsToCopy)})
                this.redoHistory.pop();
                lastAction.regions.forEach((region) => {
                    this.onRegionMoveEnd(region.id, CanvasHelpers.getRegionData(region), false, false);
                });
                this.clearAllRegions();
                this.regionsDrawn = false;
                this.refreshCanvasToolsRegions(true);
                break;

            default:
                break;
        }
    }

    private addToUndoHistory(object: any) {
        if (this.resetRedoHistoryCounter === 0) {
            this.redoHistory = []
        } else {
            this.resetRedoHistoryCounter--;
        }
        if (this.actionHistory.length === 20) {
            this.actionHistory.shift();
            this.actionHistory.push(object)
        } else {
            this.actionHistory.push(object)
        }
    }

    private addToRedoHistory(object: any) {
        if (this.redoHistory.length === 20) {
            this.redoHistory.shift();
            this.redoHistory.push(object)
        } else {
            this.redoHistory.push(object)
        }
    }

    /**
     * Zoom on mouse wheel
     */
    private handleMouseWheelEvent = (e: React.WheelEvent<HTMLDivElement>): void => {
        e.stopPropagation();
        if (this.drag && this.props.editorMode === EditorMode.Polygon) {
            return;
        }
        /*if (this.props.editorMode !== EditorMode.Select && this.props.editorMode !== EditorMode.None) {
            return;
        }*/
        // if (this.moveObject.selected) {
        //     let directionWheel = 0;
        //     if (e.deltaY === 0) {
        //         return;
        //     }
        //     if (e.deltaY > 0) {
        //         directionWheel = -1
        //     } else {
        //         directionWheel = 1
        //     }
        //
        //     this.moveCanvasArrow(this.state.contentSource, 20,directionWheel)
        //     return;
        // }

        let amount;
        if (e.deltaY === 0) {
            return;
        }
        if (e.deltaY > 0) {
            amount = 1 / this.ZOOM_AMOUNT;
        } else {
            amount = this.ZOOM_AMOUNT;
        }
        this.zoomCanvas(this.state.contentSource, amount, e.pageX, e.pageY);
    }

    private renderChildren = () => {
        return React.cloneElement(this.props.children, {
            onAssetChanged: this.onAssetChanged,
            onLoaded: this.onAssetLoaded,
            onError: this.onAssetError,
            onActivated: this.onAssetActivated,
            onDeactivated: this.onAssetDeactivated,
        });
    }

    private setOpenDialog = (state: boolean) => {
        this.setState({openDialog: state})
    }

    private removeAllRegions = () => {
        const ids = this.state.currentAsset.regions.map((r) => r.id);
        for (const id of ids) {
            this.editor.RM.deleteRegionById(id);
        }
        this.deleteRegionsFromAsset(this.state.currentAsset.regions);
        this.actionHistory = [];
        this.redoHistory = [];
    }

    private addRegions = (regions: IRegion[]) => {
        this.addRegionsToCanvasTools(regions);
        this.addRegionsToAsset(regions);
    }

    private addRegionsToAsset = (regions: IRegion[]) => {
        this.updateAssetRegions(
            this.state.currentAsset.regions.concat(regions),
        );
    }

    private addRegionsToCanvasTools = (regions: IRegion[]) => {
        for (const region of regions) {
            const regionData = CanvasHelpers.getRegionData(region);
            const scaledRegionData = this.editor.scaleRegionToFrameSize(
                regionData,
                this.state.currentAsset.asset.size.width,
                this.state.currentAsset.asset.size.height);
            this.editor.RM.addRegion(
                region.id,
                scaledRegionData,
                CanvasHelpers.getTagsDescriptor(this.props.project.tags, region),
            );
        }
    }

    private deleteRegions = (regions: IRegion[]) => {
        this.deleteRegionsFromCanvasTools(regions);
        this.deleteRegionsFromAsset(regions);
    }

    private deleteRegionsFromAsset = (regions: IRegion[]) => {
        const filteredRegions = this.state.currentAsset.regions.filter((assetRegion) => {
            return !regions.find((r) => r.id === assetRegion.id);
        });
        this.updateAssetRegions(filteredRegions);
    }

    private deleteRegionsFromCanvasTools = (regions: IRegion[]) => {
        for (const region of regions) {
            this.editor.RM.deleteRegionById(region.id);
        }
    }

    /**
     * Method that gets called when a new region is drawn
     * @param {RegionData} regionData the RegionData of created region
     * @returns {void}
     */
    private onSelectionEnd = (regionData: RegionData) => {
        if (this.editor.AS.getSelectorSettings().mode == 5) {
            this.editor.RM.unfreeze();
        }
        if (CanvasHelpers.isEmpty(regionData)) {
            return;
        }
        const id = shortid.generate();

        this.editor.RM.addRegion(id, regionData, null);

        this.template = new Rect(regionData.width, regionData.height);

        // RegionData not serializable so need to extract data
        const scaledRegionData = this.editor.scaleRegionToSourceSize(
            regionData,
            this.state.currentAsset.asset.size.width,
            this.state.currentAsset.asset.size.height,
        );
        const lockedTags = this.props.lockedTags;
        const newRegion = {
            id,
            type: this.editorModeToType(this.props.editorMode),
            tags: lockedTags || [],
            boundingBox: {
                height: scaledRegionData.height,
                width: scaledRegionData.width,
                left: scaledRegionData.x,
                top: scaledRegionData.y,
            },
            points: scaledRegionData.points,
        };
        if (lockedTags && lockedTags.length) {
            this.editor.RM.updateTagsById(id, CanvasHelpers.getTagsDescriptor(this.props.project.tags, newRegion));
        }
        this.updateAssetRegions([...this.state.currentAsset.regions, newRegion]);
        if (this.props.onSelectedRegionsChanged) {
            this.props.onSelectedRegionsChanged([newRegion]);
        }
        this.addToUndoHistory({id: changeActions.ADD_REGION, region: {...newRegion}, regionId: id})
    }

    /**
     * Update regions within the current asset
     * @param regions
     */
    private updateAssetRegions = (regions: IRegion[]) => {
        const currentAsset: IAssetMetadata = {
            ...this.state.currentAsset,
            regions,
        };
        this.setState({
            currentAsset,
        }, () => {
            this.props.onAssetMetadataChanged(currentAsset);
        });
    }

    /**
     * Method called when moving a region already in the editor
     * @param {string} id the id of the region that was moved
     * @param {RegionData} regionData the RegionData of moved region
     * @returns {void}
     */
    private onRegionMoveEnd = (id: string, regionData: RegionData, save = true, resize = true) => {
        const currentRegions = this.state.currentAsset.regions;
        const movedRegionIndex = currentRegions.findIndex((region) => region.id === id);
        const movedRegion = currentRegions[movedRegionIndex];
        const oldRegion = {...movedRegion}
        const oldRegionData = CanvasHelpers.getRegionData(oldRegion)

        const scaledRegionData = resize ? this.editor.scaleRegionToSourceSize(
            regionData,
            // @ts-ignore
            this.state.currentAsset.asset.size.width,
            // @ts-ignore
            this.state.currentAsset.asset.size.height,
        ) : regionData;

        if (movedRegion) {
            movedRegion.points = scaledRegionData.points;
            movedRegion.boundingBox = {
                height: scaledRegionData.height,
                width: scaledRegionData.width,
                left: scaledRegionData.x,
                top: scaledRegionData.y,
            };
        }

        currentRegions[movedRegionIndex] = movedRegion;
        const newRegion = {...movedRegion}
        const newRegionData = CanvasHelpers.getRegionData(movedRegion);
        this.updateAssetRegions(currentRegions);
        if (save) {
            this.addToUndoHistory({id: changeActions.MOVE_REGION, oldRegionData: oldRegionData, oldRegion: oldRegion, newRegion: newRegion, newRegionData: newRegionData, regionId: id})
        }
    }

    /**
     * Method called when deleting a region from the editor
     * @param {string} id the id of the deleted region
     * @returns {void}
     */
    private onRegionDelete = (id: string) => {
        // Remove from Canvas Tools
        this.editor.RM.deleteRegionById(id);

        // Remove from project
        const currentRegions = this.state.currentAsset.regions;
        const deletedRegionIndex = currentRegions.findIndex((region) => region.id === id);
        const deletedRegion = currentRegions[deletedRegionIndex]
        currentRegions.splice(deletedRegionIndex, 1);

        this.updateAssetRegions(currentRegions);
        if (this.props.onSelectedRegionsChanged) {
            this.props.onSelectedRegionsChanged([]);
        }
        if (this.pasteRegionsCounter > 0) {
            this.pasteRegionsCounter--;
            return;
        }
        this.addToUndoHistory({id: changeActions.REMOVE_REGION, region: {...deletedRegion}})
    }

    /**
     * Method called when a region is selected
     * @param {string} id the id of the selected region
     * @param {boolean} multiSelect boolean whether region was selected with multi selection
     * @returns {void}
     */
    private onRegionSelected = (id: string, multiSelect: boolean) => {
        if (id === null || id === undefined || id === "") {
            return;
        }
        // Gets the scaled region data
        const selectedRegionData = this.getRegionBounds(id);

        if (selectedRegionData) {
            this.template = new Rect(selectedRegionData.width, selectedRegionData.height);
        }

        const selectedRegions = this.getRegion(id);
        if (this.props.onSelectedRegionsChanged) {
            this.props.onSelectedRegionsChanged(selectedRegions);
        }
        if (this.props.lockedTags && this.props.lockedTags.length) {
            for (const selectedRegion of selectedRegions) {
                selectedRegion.tags = CanvasHelpers.addAllIfMissing(selectedRegion.tags, this.props.lockedTags);
            }
            this.updateRegions(selectedRegions);
        }
    }
    //
    // private renderChildren = () => {
    //     return React.cloneElement(this.props.children, {
    //         onAssetChanged: this.onAssetChanged,
    //         onLoaded: this.onAssetLoaded,
    //         onError: this.onAssetError,
    //         onActivated: this.onAssetActivated,
    //         onDeactivated: this.onAssetDeactivated,
    //     });
    // }

    /**
     * Raised when the asset bound to the asset preview has changed
     */
    private onAssetChanged = () => {
        this.setState({enabled: false});
    }

    /**
     * Raised when the underlying asset has completed loading
     */
    private onAssetLoaded = (contentSource: ContentSource) => {
        this.setState({contentSource});
        this.positionCanvas(contentSource);
    }

    private onAssetError = () => {
        this.setState({
            enabled: false,
        });
    }

    /**
     * Raised when the asset is taking control over the rendering
     */
    private onAssetActivated = () => {
        this.setState({enabled: false});
    }

    /**
     * Raise when the asset is handing off control of rendering
     */
    private onAssetDeactivated = (contentSource: ContentSource) => {
        this.setState({
            contentSource,
            enabled: true,
        });
    }

    /**
     * Set the loaded asset content source into the canvas tools canvas
     */
    private setContentSource = async (contentSource: ContentSource) => {
        try {
            await this.editor.addContentSource(contentSource as any);

            if (this.props.onCanvasRendered) {
                const canvas = this.canvasZone.current.querySelector("canvas");
                this.props.onCanvasRendered(canvas);
            }
        } catch (e) {
            console.warn(e);
        }
    }

    /**
     * Positions the canvas tools drawing surface to be exactly over the asset content
     */
    private positionCanvas = (contentSource: ContentSource) => {
        if (!contentSource) {
            return;
        }

        const canvas = this.canvasZone.current;
        if (canvas) {
            const boundingBox = createContentBoundingBox(contentSource);
            canvas.style.top = `${boundingBox.top}px`;
            canvas.style.left = `${boundingBox.left}px`;
            canvas.style.width = `${boundingBox.width}px`;
            canvas.style.height = `${boundingBox.height}px`;
            this.editor.resize(boundingBox.width, boundingBox.height);
        }
    }

    /**
     * Move canvas
     */
    private moveCanvas = (contentSource: ContentSource): void => {
        const canvas = this.canvasZone.current;
        if (!contentSource || !canvas) {
            return;
        }
        const top = canvas.offsetTop + (this.mouseY - this.prevMouseY);
        const left = canvas.offsetLeft + (this.mouseX - this.prevMouseX);
        canvas.style.top = `${top}px`;
        canvas.style.left = `${left}px`;
        this.prevTop = top;
        this.prevLeft = left;
    }

    // /**
    //  * Move canvas on arrow
    //  */
    // private moveCanvasArrow = (contentSource: ContentSource, offset: number, direction: number): void => {
    //     const canvas = this.canvasZone.current;
    //     if (!contentSource || !canvas) {
    //         return;
    //     }
    //
    //     if (this.moveObject.direction === 0) {
    //         const top = canvas.offsetTop + (direction === 1 ? offset : -offset);
    //         canvas.style.top = `${top}px`;
    //         this.prevTop = top;
    //     } else {
    //         const left = canvas.offsetLeft + (direction === 1 ? offset : -offset);
    //         canvas.style.left = `${left}px`;
    //         this.prevLeft = left;
    //     }
    //     return;
    // }

    /**
     * Zoom in/out on the canvas
     */
    private zoomCanvas = (contentSource: ContentSource, amount: number, mouseX: number, mouseY: number) => {
        const canvas = this.canvasZone.current;
        if (!contentSource || !canvas) {
            return;
        }

        let left: number, top: number, width: number, height: number;
        const editorZoneRect = document.querySelector(".editor-page-content-main-body").getBoundingClientRect();
        const boundingBox = createContentBoundingBox(contentSource);

        // Initial position
        if (!(this.prevTop && this.prevLeft)) {
            this.prevTop = boundingBox.top;
            this.prevLeft = boundingBox.left;
        }

        // Max zoom in
        if (this.scale * amount > this.ZOOM_MAX) {
            return;
        }
        // Max zoom out
        if (this.scale * amount < this.ZOOM_MIN) {
            this.scale = this.ZOOM_MIN;
            left = boundingBox.left;
            top = boundingBox.top;
            width = boundingBox.width;
            height = boundingBox.height;
        }
        // Zoom in/out
        else {
            this.scale = this.scale * amount;
            const mouseRelativeTop = (mouseY - editorZoneRect.top);
            const mouseRelativeLeft = (mouseX - editorZoneRect.left);
            top = mouseRelativeTop - (mouseRelativeTop - this.prevTop) * amount;
            left = mouseRelativeLeft - (mouseRelativeLeft - this.prevLeft) * amount;
            width = boundingBox.width * this.scale;
            height = boundingBox.height * this.scale;
        }

        // Transform
        canvas.style.top = `${top}px`;
        canvas.style.left = `${left}px`;
        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}px`;
        this.prevTop = top;
        this.prevLeft = left;
        this.editor.resize(width, height);
    }

    /**
     * Resizes and re-renders the canvas when the application window size changes
     */
    private onWindowResize = async () => {
        if (!this.state.contentSource) {
            return;
        }

        this.positionCanvas(this.state.contentSource);
    }

    /**
     * Updates regions in both Canvas Tools and the asset data store
     * @param updates Regions to be updated
     */
    private updateRegions = (updates: IRegion[]) => {
        const updatedRegions = CanvasHelpers.updateRegions(this.state.currentAsset.regions, updates);
        for (const update of updates) {
            this.editor.RM.updateTagsById(update.id, CanvasHelpers.getTagsDescriptor(this.props.project.tags, update));
        }
        this.updateAssetRegions(updatedRegions);
        this.updateCanvasToolsRegionTags();
    }

    /**
     * Updates the background of the canvas and draws the asset's regions
     */
    private clearAllRegions = () => {
        this.editor.RM.deleteAllRegions();
    }

    private refreshCanvasToolsRegions = (force = false) => {
        if (!force && !this.state.currentAsset.regions || this.state.currentAsset.regions.length === 0) {
            return;
        }

        if (!force && this.props.selectedAsset.regions.length=== 0){
            this.regionsDrawn = true;
        }

        if ((!this.regionsDrawn && !this.props.regionsEmpty) || this.ctrlPressed) {
            if (this.state.currentAsset.regions.length > 100) {
                for (let i = 0; i < toInteger(this.state.currentAsset.regions.length/40)+1; i++) {
                    setTimeout(() => {
                        const resztaTablicy = i != toInteger(this.state.currentAsset.regions.length/40) ? this.state.currentAsset.regions.slice(i * 40, (i + 1) * 40) : this.state.currentAsset.regions.slice(i * 40);
                        for (const region of resztaTablicy) {
                            const loadedRegionData = CanvasHelpers.getRegionData(region);
                            this.editor.RM.addRegion(
                                region.id,
                                this.editor.scaleRegionToFrameSize(
                                    loadedRegionData,
                                    this.state.currentAsset.asset.size.width,
                                    this.state.currentAsset.asset.size.height,
                                ),
                                CanvasHelpers.getTagsDescriptor(this.props.project.tags, region));
                        }
                    }, i * 200)
                }
            } else {
                this.state.currentAsset.regions.forEach((region: IRegion) => {
                    const loadedRegionData = CanvasHelpers.getRegionData(region);
                    this.editor.RM.addRegion(
                            region.id,
                        this.editor.scaleRegionToFrameSize(
                            loadedRegionData,
                            this.state.currentAsset.asset.size.width,
                            this.state.currentAsset.asset.size.height,
                        ),
                        CanvasHelpers.getTagsDescriptor(this.props.project.tags, region));
                    });
                }
            this.regionsDrawn = true;
        }
    }

    private editorModeToType = (editorMode: EditorMode) => {
        let type;
        switch (editorMode) {
            case EditorMode.CopyRect:
            case EditorMode.Rectangle:
                type = RegionType.Rectangle;
                break;
            case EditorMode.Polygon:
                type = RegionType.Polygon;
                break;
            case EditorMode.Point:
                type = RegionType.Point;
                break;
            case EditorMode.Polyline:
                type = RegionType.Polyline;
                break;
            default:
                break;
        }
        return type;
    }

    private handleControlKeyDown = (event) => {
        if (event.key.toLowerCase() === "shift" && this.editor.AS.getSelectorSettings().mode == 5) {
            this.isPolgyonAutoPointsPressed = true;
            this.editor.RM.freeze();
        }

        if (event.ctrlKey && event.key.includes("Arrow")) {
            const editorRegions = this.editor.RM.getSelectedRegions();
            if (editorRegions.length > 0) {
                this.addToUndoHistory({id: changeActions.MOVE_REGIONS, regions: cloneDeep(editorRegions)})
            }
        }

        if (event.keyCode === this.hideRegionsKeyCode && !this.ctrlPressed) {
            this.clearAllRegions()
            this.ctrlPressed = true;
            this.regionsDrawn = false;
        }
        if (event.keyCode === this.changeTransparencyKeyCode) {
            if (!this.shiftPressed) {
                this.editor.RM.freeze()
                this.regionsFreezed = true
                this.shiftPressed = true
            } else {
                this.editor.RM.unfreeze()
                this.regionsFreezed = false
                this.shiftPressed = false
            }
        }
        // if(event.keyCode === 188) {
        //     this.moveObject.selected = true
        //     this.moveObject.direction = 0
        // }
        // if(event.keyCode === 190) {
        //     this.moveObject.selected = true
        //     this.moveObject.direction = 1
        // }
        // if(event.keyCode === 191) {
        //     this.moveObject.selected = false
        //     this.moveObject.direction = null
        // }
    };

    private handleControlKeyUp = (event) => {
        if (event.key.toLowerCase() === "shift" && this.editor.AS.getSelectorSettings().mode == 5) {
            this.isPolgyonAutoPointsPressed = false;
            // this.editor.RM.unfreeze();
        }

        if (event.ctrlKey && event.key.includes("Arrow")) {
            const editorRegions = this.editor.RM.getSelectedRegions();
            editorRegions.forEach((region) => {
                this.onRegionMoveEnd(region.id, region.regionData, false);
            })
        }

        if (event.ctrlKey && event.key.toLowerCase() === "c") {
            this.setState({ copiedRegions: this.getSelectedRegions() });
        }

        if (event.ctrlKey && event.key.toLowerCase() === "v") {
            if (this.state.copiedRegions === null) {
                return;
            }
            const regionsToPaste: IRegion[] = this.state.copiedRegions;
            const asset = this.state.currentAsset;
            const duplicates = CanvasHelpers.duplicateRegionsAndMove(
                regionsToPaste,
                asset.regions,
                asset.asset.size.width,
                asset.asset.size.height,
            );

            this.addRegions(duplicates)
            this.addToUndoHistory({id: changeActions.PASTE_REGIONS, regions: [...duplicates]})
        }

        if (event.keyCode === this.hideRegionsKeyCode && this.ctrlPressed) {
            this.refreshCanvasToolsRegions();
            this.ctrlPressed = false;
            if (this.regionsFreezed) {
                this.editor.RM.freeze()
            }
        }
    };

    private mouseOverControl = (event) => {
        if (this.isPolgyonAutoPointsPressed) {
            const distance = Math.sqrt(Math.pow(event.clientX - this.prevMousePosition.x, 2) + Math.pow(event.clientY - this.prevMousePosition.y, 2));

            if (distance >= 10) {
                const x = event.clientX;
                const y = event.clientY;

                const element = document.elementFromPoint(x, y);

                const clickEvent = new MouseEvent('click', {
                    view: window,
                    bubbles: true,
                    cancelable: true
                });

                element.dispatchEvent(clickEvent);

                this.prevMousePosition = { x, y };
            }
        }
    }
}
