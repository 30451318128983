import React from "react";
import {Option} from "../../pages/editorPage/EditorPage";

export interface ISlidersProps {
    onChange: (id: Option, value: number) => void;
    onReset: () => void;
}

export interface ISlidersState {
    sliders: {name: string, id: string, value: number, min: number, max: number}[];
}

const DEFAULT_SLIDERS = [
    {name: "Brightness", id: "brightness", value: 100, min: 0, max: 800},
    {name: "Contrast", id: "contrast", value: 100, min: 0, max: 800},
    {name: "Saturation", id: "saturation", value: 100, min: 0, max: 800}];

export class FilterSliders extends React.Component<ISlidersProps, ISlidersState> {

    public state: ISlidersState = {
        sliders: DEFAULT_SLIDERS
    }

    public async componentDidMount() {
        this.reset();
    }

    public render() {
        return (
            <div className='filters'>
                {this.state.sliders.map((slider, index) => (
                    <div key={index}>
                        <label>{slider.name} {slider.value} %</label>
                        <input type="range" id={slider.id} min={slider.min} max={slider.max} value={slider.value}
                               onChange={(event) => this.handleChange(slider.id as Option, Number(event.target.value))}/>
                    </div>
                ))}
                <button name="Reset" onClick={this.reset}>Reset</button>
            </div>
        );
    }

    private reset = () => {
        this.setState( {
            sliders: DEFAULT_SLIDERS
        });
        this.props.onReset();
    }

    private handleChange = (id: Option, value: number) => {
        this.setState({
            sliders: this.state.sliders.map(slider => {
                if (slider.id === id) {
                    return {...slider, value};
                } else {
                    return slider;
                }
            })
        });
        this.props.onChange(id as Option, value);
    }
}