import {ToolbarItemFactory} from "./providers/toolbar/toolbarItemFactory";
// import { ExportProject } from "./react/components/toolbar/exportProject";
// import { SaveProject } from "./react/components/toolbar/saveProject";
import {ToolbarItemType} from "./components/toolbar/toolbarItem";
import {strings} from "./components/utils/strings";

export enum ToolbarItemName {
    SelectCanvas = "selectCanvas",
    DrawRectangle = "drawRectangle",
    DrawPolygon = "drawPolygon",
    // CopyRectangle = "copyRectangle",
    // CopyRegions = "copyRegions",
    // CutRegions = "cutRegions",
    // PasteRegions = "pasteRegions",
    RemoveAllRegions = "removeAllRegions",
    Undo = "undo",
    Redo = "redo",
    PreviousAsset = "navigatePreviousAsset",
    NextAsset = "navigateNextAsset",
    PreviousPage = "navigatePreviousPage",
    NextPage = "navigateNextPage",
    Checked = "checked",
    Save = "save",
    SaveOnLater = 'saveOnLater',
    UnCheckAll = 'unCheckAll',
    Delete = "delete",
    Propagate = "propagate",
    History = "history",
    Lock = "lock"
    // SaveProject = "saveProject",
    // ExportProject = "exportProject",
    // ActiveLearning = "activeLearning",
}

export enum ToolbarItemGroup {
    Canvas = "canvas",
    Navigation = "navigation",
    Asset = "asset"
}

/**
 * Registers items for toolbar
 */
export default function registerToolbar() {
    ToolbarItemFactory.register({
        name: ToolbarItemName.SelectCanvas,
        tooltip: strings.editorPage.toolbar.select,
        icon: "fa-mouse-pointer",
        group: ToolbarItemGroup.Canvas,
        type: ToolbarItemType.State,
        accelerators: ["V", "v"],
    });

    ToolbarItemFactory.register({
        name: ToolbarItemName.DrawRectangle,
        tooltip: strings.editorPage.toolbar.drawRectangle,
        icon: "fa-vector-square",
        group: ToolbarItemGroup.Canvas,
        type: ToolbarItemType.State,
        accelerators: ["R", "r"],
    });

    ToolbarItemFactory.register({
        name: ToolbarItemName.DrawPolygon,
        tooltip: strings.editorPage.toolbar.drawPolygon,
        icon: "fa-draw-polygon",
        group: ToolbarItemGroup.Canvas,
        type: ToolbarItemType.State,
        accelerators: ["P", "p", "B", "b"],
    });

    // ToolbarItemFactory.register({
    //     name: ToolbarItemName.CopyRectangle,
    //     tooltip: strings.editorPage.toolbar.copyRectangle,
    //     icon: "far fa-clone",
    //     group: ToolbarItemGroup.Canvas,
    //     type: ToolbarItemType.State,
    //     accelerators: ["CmdOrCtrl+W", "CmdOrCtrl+w"],
    // });
    //
    // ToolbarItemFactory.register({
    //     name: ToolbarItemName.CopyRegions,
    //     tooltip: strings.editorPage.toolbar.copy,
    //     icon: "fa-copy",
    //     group: ToolbarItemGroup.Regions,
    //     type: ToolbarItemType.Action,
    //     accelerators: ["CmdOrCtrl+C", "CmdOrCtrl+c"],
    // });
    //
    // ToolbarItemFactory.register({
    //     name: ToolbarItemName.CutRegions,
    //     tooltip: strings.editorPage.toolbar.cut,
    //     icon: "fa-cut",
    //     group: ToolbarItemGroup.Regions,
    //     type: ToolbarItemType.Action,
    //     accelerators: ["CmdOrCtrl+X", "CmdOrCtrl+x"],
    // });
    //
    // ToolbarItemFactory.register({
    //     name: ToolbarItemName.PasteRegions,
    //     tooltip: strings.editorPage.toolbar.paste,
    //     icon: "fa-paste",
    //     group: ToolbarItemGroup.Regions,
    //     type: ToolbarItemType.Action,
    //     accelerators: ["CmdOrCtrl+V", "CmdOrCtrl+v"],
    // });

    ToolbarItemFactory.register({
        name: ToolbarItemName.RemoveAllRegions,
        tooltip: strings.editorPage.toolbar.removeAllRegions,
        icon: "fa-ban",
        group: ToolbarItemGroup.Canvas,
        type: ToolbarItemType.Action,
        accelerators: ["Alt+Delete", "Alt+Backspace"],
    });

    ToolbarItemFactory.register({
        name: ToolbarItemName.Undo,
        tooltip: strings.editorPage.toolbar.undo,
        icon: "fa-undo",
        group: ToolbarItemGroup.Canvas,
        type: ToolbarItemType.Action,
        accelerators: ["Alt+z"],
    });

    ToolbarItemFactory.register({
        name: ToolbarItemName.Redo,
        tooltip: strings.editorPage.toolbar.redo,
        icon: "fa-redo",
        group: ToolbarItemGroup.Canvas,
        type: ToolbarItemType.Action,
        accelerators: ["Alt+y"],
    });

    ToolbarItemFactory.register({
        name: ToolbarItemName.PreviousAsset,
        tooltip: strings.editorPage.toolbar.previousAsset,
        icon: "fas fa-arrow-circle-up",
        group: ToolbarItemGroup.Navigation,
        type: ToolbarItemType.Action,
        accelerators: ["ArrowUp", "W", "w"],
    });

    ToolbarItemFactory.register({
        name: ToolbarItemName.NextAsset,
        tooltip: strings.editorPage.toolbar.nextAsset,
        icon: "fas fa-arrow-circle-down",
        group: ToolbarItemGroup.Navigation,
        type: ToolbarItemType.Action,
        accelerators: ["ArrowDown", "S", "s"],
    });

    ToolbarItemFactory.register({
        name: ToolbarItemName.PreviousPage,
        tooltip: strings.editorPage.toolbar.previousPage,
        icon: "fas fa-arrow-circle-left",
        group: ToolbarItemGroup.Navigation,
        type: ToolbarItemType.Action,
        accelerators: ["ArrowLeft", "A", "a"],
    });

    ToolbarItemFactory.register({
        name: ToolbarItemName.NextPage,
        tooltip: strings.editorPage.toolbar.nextPage,
        icon: "fas fa-arrow-circle-right",
        group: ToolbarItemGroup.Navigation,
        type: ToolbarItemType.Action,
        accelerators: ["ArrowRight", "D", "d"],
    });

    // ToolbarItemFactory.register({
    //     name: ToolbarItemName.SaveProject,
    //     tooltip: strings.editorPage.toolbar.saveProject,
    //     icon: "fa-save",
    //     group: ToolbarItemGroup.Project,
    //     type: ToolbarItemType.Action,
    //     accelerators: ["CmdOrCtrl+S", "CmdOrCtrl+s"],
    // }, SaveProject);

    // ToolbarItemFactory.register({
    //     name: ToolbarItemName.ExportProject,
    //     tooltip: strings.editorPage.toolbar.exportProject,
    //     icon: "fa-external-link-square-alt",
    //     group: ToolbarItemGroup.Project,
    //     type: ToolbarItemType.Action,
    //     accelerators: ["CmdOrCtrl+E", "CmdOrCtrl+e"],
    // }, ExportProject);

    ToolbarItemFactory.register({
        name: ToolbarItemName.Checked,
        tooltip: strings.editorPage.toolbar.checked,
        icon: "far fa-check-square",
        group: ToolbarItemGroup.Navigation,
        type: ToolbarItemType.Toggle,
        accelerators: ["Alt+c"]
    });

    ToolbarItemFactory.register({
        name: ToolbarItemName.Save,
        tooltip: strings.editorPage.toolbar.save,
        icon: "far fa-save",
        group: ToolbarItemGroup.Asset,
        type: ToolbarItemType.Action,
        accelerators: ["Alt+Enter"]
    });

    ToolbarItemFactory.register({
        name: ToolbarItemName.SaveOnLater,
        tooltip: strings.editorPage.toolbar.saveOnLater,
        icon: "far fa-bookmark",
        group: ToolbarItemGroup.Asset,
        type: ToolbarItemType.Action,
        accelerators: ["Alt+Shift"]
    });

    ToolbarItemFactory.register({
        name: ToolbarItemName.Delete,
        tooltip: strings.editorPage.toolbar.delete,
        icon: "far fa-trash",
        group: ToolbarItemGroup.Asset,
        type: ToolbarItemType.Action,
        accelerators: ["Delete"]
    });

    ToolbarItemFactory.register({
        name: ToolbarItemName.Propagate,
        tooltip: strings.editorPage.toolbar.propagate,
        icon: "far fa-copy",
        group: ToolbarItemGroup.Asset,
        type: ToolbarItemType.Action,
        accelerators: ["Propagate"]
    });

    ToolbarItemFactory.register({
        name: ToolbarItemName.History,
        tooltip: strings.editorPage.toolbar.history,
        icon: "far fa-clock",
        group: ToolbarItemGroup.Asset,
        type: ToolbarItemType.Action,
        accelerators: ["h", "H"]
    });
}
