import React from "react";
import {ImageData} from "../../common/_dto/ImageData";
import {ImageClient} from "../../common/_clients/ImageClient";
import {IMarkProps} from "./Mark";
import {ITag} from "../../../models/applicationState";
import {AIAgentsClient} from "../../common/_clients/AIAgentsClient";
import {MarkerImageValue} from "../../common/_dto/MarkerImageValue";

interface IMarkControllerProps {
    render(props: IMarkProps)
}

interface IMarkControllerState {
    isLoading: boolean,
    agentId: number
    checked: boolean,
    page: number,
    rowsPerPage: number,
    data: ImageData[],
    count: number,
    tags: ITag[],
    selectedTag: ITag,
}

class MarkController extends React.Component<IMarkControllerProps, IMarkControllerState> {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            agentId: Number(window.location.pathname.split('/')[2]),
            checked: false,
            page: 0,
            rowsPerPage: 10,
            data: [],
            count: 0,
            tags: [],
            selectedTag: null
        };
    }

    componentDidMount() {
        this.setState({isLoading: true}, this.fetchData);
        this.setState({isLoading: true}, this.fetchTags);
    }

    fetchData = async (): Promise<void> => {
        let response = await ImageClient().getImages([this.state.agentId],
            this.state.page,
            this.state.rowsPerPage,
            this.state.checked)
            .then(res => res.data)
            .catch(err => {
                console.log(err);
                return [];
            });
        this.setState({data: response.list, count: response.counter, isLoading: false});
    };

    fetchTags = async (): Promise<void> => {
        let tags: ITag[] = await AIAgentsClient().getAgentsTags(this.state.agentId)
            .then(res => res.data)
            .catch(err => {
                console.log(err);
                return [];
            });
        this.setState({tags, isLoading: false});
    }

    deleteImage = async (index: number): Promise<void> => {
        let imageId = this.state.data[index].id;
        await ImageClient().deleteImage(imageId)
            .then(() => {
                let data = this.state.data;
                data.splice(index, 1);
                this.setState({data});
            })
            .catch(err => console.log(err));
    };

    markImage = async (index: number): Promise<void> => {
        let imageValues: MarkerImageValue[] = [];
        if (!this.state.checked) {
            let imageValue = new MarkerImageValue();
            imageValue.certainty = 1;
            imageValue.objectId = this.state.selectedTag.id;
            imageValues.push(imageValue);
        }
        let image = this.state.data[index];
        await ImageClient().markImageValues(image.id, imageValues)
            .then(() => {
                ImageClient().setImageCheck(image.id, !image.checked)
                    .catch(err => console.log(err))

                let data = this.state.data;
                data.splice(index, 1);
                this.setState({data});
            })
            .catch(err => console.log(err));
    }

    handleSelectedTagChange = (selectedTag: ITag): void => {
        // TODO add tag highlight
        const alreadySelected = this.state.selectedTag && this.state.selectedTag.name === selectedTag.name;
        this.setState({selectedTag: alreadySelected ? null : selectedTag});
    }

    handleTagHotKey = (evt: KeyboardEvent): void => {
        const key = parseInt(evt.key, 10);
        if (isNaN(key)) {
            return;
        }
        const tag = this.state.tags[key - 1];
        this.handleSelectedTagChange(tag);
    }

    render() {
        return this.props.render({
            checked: this.state.checked,
            page: this.state.page,
            rowsPerPage: this.state.rowsPerPage,
            data: this.state.data,
            count: this.state.count,
            tags: this.state.tags,
            selectedTag: this.state.selectedTag,
            handleCheckedChange: () => this.setState({checked: !this.state.checked}, this.fetchData),
            handlePageChange: page => this.setState({page}, this.fetchData),
            handleRowsPerPageChange: rowsPerPage => this.setState({rowsPerPage}, this.fetchData),
            handleDeleteImage: this.deleteImage,
            handleMarkImage: this.markImage,
            handleSelectedTagChange: this.handleSelectedTagChange,
            handleTagHotKey: this.handleTagHotKey
        });
    }
}

export default MarkController;