import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ImageClient } from "../_clients/ImageClient";
import "./HistoryDialog.scss"

const HistoryDialog = (props) => {
    const { title, open, setOpen, imageId } = props;
    const [imageHistory, setImageHistory] = useState([]);

    useEffect(() => {
        if (open) {
            ImageClient().getImageHistory(imageId)
                .then(response => {
                    const sortedHistory = response.data.sort((a, b) => a.date - b.date);
                    setImageHistory(sortedHistory);
                })
                .catch(error => {
                    console.error("Error fetching image history:", error);
                });
        }
    }, [open, imageId]);

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog"
            className="confirmDialog"
        >
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent>
                {imageHistory.map((historyItem, index) => (
                    <div key={index}>
                        <p>Action: {historyItem.action}</p>
                        <p>Action by: {historyItem.actionBy}</p>
                        <p>Date: {new Date(historyItem.date).toLocaleString()}</p>
                        <hr />
                    </div>
                ))}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={() => setOpen(false)}
                    color="primary"
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default HistoryDialog;
