import axios, {AxiosResponse} from "axios";
import {OBJECT_REST_PATH} from "../../utils/path";
import {ObjectDto} from "../_dto/EditorTreeDto";

export const ObjectClient = () => {
    const deleteObject = (objectId: number): Promise<AxiosResponse> => {
        return axios.delete(`${OBJECT_REST_PATH + objectId}`)
    };
    const editObject = (object: ObjectDto): Promise<AxiosResponse> => {
        return axios.patch(`${OBJECT_REST_PATH}`, object)
    }
    const createObject = (object: ObjectDto, agentId:number): Promise<AxiosResponse> => {
        return axios.post(`${OBJECT_REST_PATH+agentId}`, object)
    }
    return {
        deleteObject,
        editObject,
        createObject
    }
}
