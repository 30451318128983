export const SERVER_PATH = 'https://trainer.carscanner.dev';

export const HOME_PATH = '/home'
export const SIGN_IN_ROUTER_PATH = '/auth/login'

export const SIGN_IN_REST_PATH = '/login';
export const REFRESH_TOKEN_REST_PATH = '/refresh';
export const GET_USER_DATA_REST_PATH= '/inner/user/user';
export const IMAGES_REST_PATH='/inner/image/'
export const GET_IMAGES_COUNT_REST_PATH='/inner/image/count'
export const GET_HISTORY_ACTIONS_REST_PATH='/inner/image/actions/'
export const PATCH_IMAGE_OBJECT_VALUE_REST_PATH='/inner/image/imageValues/'
export const POST_LOCK_IMAGE_PATH='/inner/image/lock/'
export const POST_UNCHECK_PATH='/inner/image/check'
export const GET_ALL_AGENTS_REST_PATH='/inner/agent/all'
export const GET_AGENTS_REST_PATH='/inner/agent/'
export const GET_AGENTS_TREE_REST_PATH='/inner/agent/tree/'
export const GET_ALL_AGENTS_TREE_REST_PATH='/inner/agent/tree/all'
export const SET_IMAGES_CHECK_REST_PATH='/inner/image/check'
export const GET_AGENTS_OBJECTS='/inner/agent/objects/'
export const GET_AGENTS_INFO='/inner/agent/id/'
export const CHECK_AGENT_FOR_TYPE_REST_PATH='/inner/agent/'
export const GET_AGENT_COUNTER_REST_PATH='/inner/agent/agentsCounter'
export const GET_SYSTEM_EDITOR_TREE_REST_PATH='/inner/system/editor'
export const GET_SYSTEMS_REST_PATH='/inner/system/all'
export const SYSTEMS_REST_PATH='/inner/system/'
export const OBJECT_REST_PATH='/inner/object/'
export const REMOVE_BG_UPLOAD_REST_PATH='/inner/removeBg/upload/'
export const GET_USERS_REST_PATH='/inner/users/'
export const GET_CREATE_USER_REST_PATH = '/inner/users/'
export const GET_DELETE_USER_REST_PATH = '/inner/users/'
export const GET_EDIT_USER_REST_PATH = '/inner/users/user/'
