import React from "react";
import "./ImageCardMark.scss"
import Button from "@material-ui/core/Button";
import {MarkerImageValue} from "../../common/_dto/MarkerImageValue";
import ConfirmDialog from "../../common/confirmDialog/ConfirmDialog";


export const ImageCardMark = ({
                                  image,
                                  index,
                                  tags,
                                  selectedTag,
                                  markAsChecked,
                                  deleteImage
                              }) => {
    const [openDialog, setOpenDialog] = React.useState(false);
    const imageTags = image.imageValueDtos.map((imageValue: MarkerImageValue) => {
        return {
            tag: tags.find(tag => tag.id === imageValue.objectId),
            certainty: imageValue.certainty
        }
    });

    return (<div className="marker-card">
        <div className="properties">
            <h5>Uuid: {image.uuid}</h5>
            <h5>FileType: {image.fileType}</h5>
            <h5>Checked: {image.checked ? "True" : "False"}</h5>
            {imageTags.map(({tag, certainty}) =>
                    tag && tag.id && tag.name && tag.color && (
                        <div key={tag.id}>
                            <h5 className="image-tag">
                                Marker: {tag.name}
                                <div className="image-tag-color" style={{backgroundColor: tag.color}}/>
                                {certainty}
                            </h5>
                        </div>
                    )
            )}
            <Button variant="outlined" color="secondary" onClick={() => markAsChecked(index)}
                    disabled={!(selectedTag || image.checked)}
            >{image.checked ? "Delete marker" : "Mark"}</Button>
            <Button variant="outlined" color="secondary" onClick={() => setOpenDialog(true)}
            >Delete</Button>
            <ConfirmDialog
                title="Delete Image?"
                open={openDialog}
                setOpen={setOpenDialog}
                onConfirm={() => deleteImage(index)}
            >
                Are you sure you want to delete this image?
            </ConfirmDialog>
        </div>
        <div className="image-container">
            <img src={image.url} alt="Marker" className="image"/>
        </div>
    </div>)
}
