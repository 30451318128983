import { Route, Redirect } from 'react-router-dom';
import React from "react";


export const ProtectedRoute = (props) => {
    return props.hasPermissions()
        ?
        <Route exact path={props.path} component={props.component}>
            {props.children}
        </Route>
        :
        <Redirect to={'/auth/login'} />
}
