import {GET_SYSTEM_EDITOR_TREE_REST_PATH, GET_SYSTEMS_REST_PATH, SYSTEMS_REST_PATH} from "../../utils/path";
import axios, {AxiosResponse} from "axios";
import {SystemDto} from "../_dto/SystemDto";

export const SystemClient = () => {
    const getSystemEditorTree = (): Promise<AxiosResponse> => {
        return axios.get(`${GET_SYSTEM_EDITOR_TREE_REST_PATH}`)
    };
    const getSystems = (): Promise<AxiosResponse> => {
        return axios.get(`${GET_SYSTEMS_REST_PATH}`)
    }
    const createSystem = (system: SystemDto): Promise<AxiosResponse> => {
        return axios.post(`${SYSTEMS_REST_PATH}`, system)
    }
    return {
        getSystemEditorTree,
        getSystems,
        createSystem
    }
}
