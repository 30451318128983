import React, {useEffect, useRef, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {useAuth} from "../context/AuthenticationContext";
import {SIGN_IN_ROUTER_PATH} from "../utils/path";
import {LocalStorageHelper} from "../context/LocalStorageHelper";
import {NavLink, useHistory} from "react-router-dom";
import {
    Backdrop,
    CircularProgress,
    createStyles,
    CssBaseline,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Theme
} from "@material-ui/core";
import clsx from 'clsx';
import Button from "@material-ui/core/Button";
import {AIAgentsClient} from "../common/_clients/AIAgentsClient";
import {AiAgentCounter} from "../common/_dto/AiAgentCounter";
import {AgentType} from "../common/_dto/AgentType";
import {useLoading} from "../context/LoadingContext";
import "./NavigationBar.scss";
import {isAdmin} from "../utils/userRoleUtils";
import AgentStore from "../../common/agentStore";


const drawerWidth = 400;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            height: "60px"
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        pages: {
            paddingBottom: '30px'
        },
        pagesButton: {
            fontSize: '15px',
            width: '100%',
            justifyContent: "flex-start"
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        userNav: {
            textDecoration: "none",
            textDecorationLine: "none",
            color: "white",
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            padding: "10px",
            width: drawerWidth,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(0),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        iconLabel: {
            marginLeft: "10px"
        }
    }),
);

export type Auth = {
    path: string
    text: string
}

export default function NavigationBar(props) {
    const {isAuthenticated, setAuthenticated} = useAuth();
    const classes = useStyles();
    const [aiAgents, setAiAgent] = React.useState<AiAgentCounter[]>([]);
    const [open, setOpen] = React.useState(false);
    const history = useHistory();
    const [buttonBehaviour, setButtonBehaviour] = useState({
        path: SIGN_IN_ROUTER_PATH,
        text: 'Login'
    } as Auth);
    const {loading} = useLoading();
    const node = useRef(null);
    useEffect(() => {
        setButtonBehaviour({
            path: !isAuthenticated ? SIGN_IN_ROUTER_PATH : SIGN_IN_ROUTER_PATH,
            text: !isAuthenticated ? ' Login' : LocalStorageHelper().getUsername() + ' - Logout'
        });
        if (isAuthenticated) {
            getAiAgents();
            // history.listen(getAiAgents); //TODO MACIEK zdecyduj czy usunąć
            //    pobiera listE agentów z counterem za każdą zmianą strony może
            //    generować opóźnienia jak baza będzie zapchana
        } else {
            setAiAgent([]);
        }
    }, [isAuthenticated]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => document.removeEventListener("mousedown", handleClick);
    }, []);

    const handleClick = (e: MouseEvent): void => {
        if (node.current && !node.current.contains(e.target)) {
            handleDrawerClose();
        }
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const getAiAgents = async (): Promise<void> => {
        AIAgentsClient().getAgentsCounter().then(x => {
            setAiAgent(x.data)
            AgentStore.saveObjects(x.data)
        })
    }
    const handleLogin = async (path: string): Promise<void> => {
        if (isAuthenticated) {
            setAuthenticated(false);
            LocalStorageHelper().logout();
        }
        history.replace(path);
    };

    const downloadLearningJson = async (agent: AiAgentCounter): Promise<void> => {
        console.info("download json " + agent);
        window.open("/inner/agent/json/" + agent.id + "/learning", "_blank")
    }
    const downloadImageJson
        = async (agent: AiAgentCounter): Promise<void> => {
        console.info("download image " + agent);

        window.open("/inner/agent/json/" + agent.id + "/objects", "_blank")
    }

    const navLinks: { title: string, link: string, agents: AiAgentCounter[] }[] = [
        {
            title: "Remove Background",
            link: "removeBG",
            agents: aiAgents.filter(x => x.agentType === AgentType.REMOVE_BG)
        },
        {
            title: "Bounding Box Rectangle",
            link: "boundingBox",
            agents: aiAgents.filter(x => x.agentType === AgentType.BOUNDING_BOX_RECTANGLE)
        },
        {
            title: "Bounding Box Polygon",
            link: "boundingBox",
            agents: aiAgents.filter(x => x.agentType === AgentType.BOUNDING_BOX_POLYGON)
        },
        {
            title: "Marker",
            link: "mark",
            agents: aiAgents.filter(x => x.agentType === AgentType.MARKER)
        },
        {
            title: "Text",
            link: "text",
            agents: aiAgents.filter(x => x.agentType === AgentType.TEXT)
        }
    ]


    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    {isAuthenticated && <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <i className="fas fa-bars"/>
                    </IconButton>}
                    <Typography variant="h4" className={classes.title} noWrap>
                        trAIner
                    </Typography>
                    <div>
                        <Button
                            color="inherit"
                            className={classes.userNav}
                            onClick={() => handleLogin(buttonBehaviour.path)}
                        >
                            <i className="fas fa-user"/>
                            <span className="iconLabel">{buttonBehaviour.text}</span>
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                ref={node}
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        <i className="fas fa-chevron-left"/>
                    </IconButton>
                </div>
                <Divider/>
                <div className={`${classes.pages}, linkGroup`}>
                    <NavLink to="/systemManager" exact
                             style={{textDecoration: 'none'}}
                             activeClassName="selected" onClick={handleDrawerClose}>
                        {isAdmin() && <Button className={classes.pagesButton}>
                            <i className="fas fa-solar-system"/>
                            <span className="iconLabel">Systems</span>
                        </Button>}
                    </NavLink>
                    <NavLink to="/userManager" exact
                             style={{textDecoration: 'none'}}
                             activeClassName="selected" onClick={handleDrawerClose}>
                        {isAdmin() && <Button className={classes.pagesButton}>
                            <i className="fas fa-users"/>
                            <span className="iconLabel">Users Manager</span>
                        </Button>}
                    </NavLink>
                    <NavLink to="/objects" exact
                             style={{textDecoration: 'none'}}
                             activeClassName="selected" onClick={handleDrawerClose}>
                        {isAdmin() && <Button className={classes.pagesButton}>
                            <i className="fal fa-cogs"/>
                            <span className="iconLabel">Models</span>
                        </Button>}
                    </NavLink>
                    <NavLink to="/scan" exact
                             style={{textDecoration: 'none'}}
                             activeClassName="selected" onClick={handleDrawerClose}>
                        {isAdmin() && <Button className={classes.pagesButton}>
                            <i className="icon fal fa-camera"/>
                            <span className="iconLabel">Scan</span>
                        </Button>}
                    </NavLink>
                    <NavLink to="/files" exact
                             style={{textDecoration: 'none'}}
                             activeClassName="selected" onClick={handleDrawerClose}>
                        {isAdmin() && <Button className={classes.pagesButton}>
                            <i className="icon fal fa-file-download"/>
                            <span className="iconLabel">Files</span>
                        </Button>}
                    </NavLink>
                </div>
                <Divider/>
                <List>
                    <Typography variant="h5" noWrap>
                        Agents:
                    </Typography>
                    {navLinks.map(section => (
                        (section.agents.length > 0) && (
                            <div key={section.title}>
                                <Typography variant="h6" noWrap>
                                    {section.title}
                                </Typography>
                                {section.agents.map(agent => (
                                        <div className="agentBlock" key={section.link + '/' + agent.id}>
                                            <NavLink to={`/${section.link}/` + agent.id}
                                                     style={{textDecoration: 'none'}}
                                                     exact
                                                     activeClassName="selected"
                                                     onClick={handleDrawerClose}>
                                                <ListItem className="linkGroup" button>
                                                    <ListItemText primary={"Agent: " + agent.name}/>
                                                    <ListItemText secondary={"System: " + agent.systemName}/>
                                                    <ListItemText secondary={"To check: " + agent.toCheckCount}/>
                                                </ListItem>
                                            </NavLink>
                                            <Button onClick={() => downloadLearningJson(agent)}>
                                                <i className="fal fa-download"/>
                                                <span className="iconLabel">learning json</span>
                                            </Button>
                                            <Button onClick={() => downloadImageJson(agent)}>
                                                <i className="fal fa-images"/>
                                                <span className="iconLabel">images json</span>
                                            </Button>
                                            <Divider/>
                                        </div>
                                    )
                                )}
                            </div>
                        )
                    ))}
                </List>
            </Drawer>
            <main
                className={classes.content}
            >
                {loading &&
                <Backdrop open={loading} className={classes.backdrop}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                }
                <div className={classes.drawerHeader}/>
                {props.children}
            </main>
        </div>
    );
}
