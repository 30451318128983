import {
    GET_AGENT_COUNTER_REST_PATH, GET_AGENTS_INFO,
    GET_AGENTS_OBJECTS,
    GET_AGENTS_REST_PATH,
    GET_AGENTS_TREE_REST_PATH,
    GET_ALL_AGENTS_REST_PATH,
    GET_ALL_AGENTS_TREE_REST_PATH
} from "../../utils/path";
import axios, {AxiosResponse} from "axios";
import {AgentType} from "../_dto/AgentType";
import {AiAgent} from "../_dto/AiAgent";

export const AIAgentsClient = () => {
    const getAgents = (): Promise<AxiosResponse> => {
        return axios.get(`${GET_ALL_AGENTS_REST_PATH}`)
    };
    const getAgentsByType = (agentType: AgentType): Promise<AxiosResponse> => {
        return axios.get(`${GET_AGENTS_REST_PATH}${agentType}`)
    };
    const getAgentsTree = () => {
        return axios.get(`${GET_ALL_AGENTS_TREE_REST_PATH}`)
    }
    const getAgentsTreeByType = (agentType: AgentType) => {
        return axios.get(`${GET_AGENTS_TREE_REST_PATH}${agentType}`)
    }
    const getAgentsTags = (agentId: number) => {
        return axios.get(`${GET_AGENTS_OBJECTS}${agentId}`)
    }
    const getAgentsInfo = (agentId: number) => {
        return axios.get(`${GET_AGENTS_INFO}${agentId}`)
    }
    const getAgentsCounter = () => {
        return axios.get(`${GET_AGENT_COUNTER_REST_PATH}`)
    }
    const createAgent = (aiAgent: AiAgent, systemName: string) => {
        return axios.post(`${GET_AGENTS_REST_PATH}${systemName}`, aiAgent)
    }
    const getAgentsFiles = (): Promise<AxiosResponse> => {
        return axios.get(`${GET_AGENTS_REST_PATH}files/all`)
    }

    const deleteAgentFile = (agentId:number, filename:string): Promise<AxiosResponse> => {
        return axios.delete(`${GET_AGENTS_REST_PATH}files/${agentId}/${filename}`)
    }
    return {
        getAgents,
        getAgentsByType,
        getAgentsTree,
        getAgentsTreeByType,
        getAgentsTags,
        getAgentsInfo,
        getAgentsCounter,
        createAgent,
        getAgentsFiles,
        deleteAgentFile
    }
}
