import React, {useEffect} from "react"
import {
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import {SystemClient} from "../../common/_clients/SystemClient";
import {SystemDto} from "../../common/_dto/SystemDto";
import {copyToClipboard} from "../../utils/utils";
import Button from "@material-ui/core/Button";
import {CreateSystemDialog} from "./CreateSystemDialog";

const useStyles = makeStyles({
    wrapper: {
        margin: "10px"
    },
    table: {
        minWidth: 650,
    },
});


export const SystemManager = () => {
    const [systems, setSystems] = React.useState<SystemDto[]>([]);
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);

    useEffect(() => {
        SystemClient().getSystems().then(x => {
            setSystems(x.data)
        })
    }, [])

    const createSystem = (systemDto:SystemDto) => {
        SystemClient().createSystem(systemDto).then(x=>{
            setSystems(prevState => {
                prevState.push(x.data)
                return prevState;
            })
            setOpenDialog(false);
        }, (error) => {
            alert(error.response.data.message)
        })
    }
    const classes = useStyles();
    return (<div className={classes.wrapper}>
            <Button variant="contained" color="primary"
            onClick={()=> setOpenDialog(true)}>Create new system</Button>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>System Name</TableCell>
                            <TableCell align="right">Notify Rest</TableCell>
                            <TableCell align="right">Token</TableCell>
                            <TableCell align="right">Edit</TableCell>
                            <TableCell align="right">Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {systems.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{row.restNotify}</TableCell>
                                <TableCell align="right">{row.token}
                                <IconButton onClick={()=> copyToClipboard(row.token)}>
                                    <i className="fal fa-copy"/></IconButton>
                                </TableCell>
                                <TableCell align="right"><IconButton onClick={()=> alert("Not implemented")}>
                                    <i className="fal fa-edit"/></IconButton>
                                </TableCell>
                                <TableCell align="right"><IconButton onClick={()=> alert("Not implemented")}>
                                    <i className="fal fa-trash-alt"/></IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <CreateSystemDialog
                open={openDialog}
                setOpen={setOpenDialog}
                handleClose={createSystem}/>
        </div>

    );
}
