import React from "react";
import {agentsWithPreventResize, IAssetProps} from "./assetPreview";

/**
 * ImageAsset component used to render all image assets
 */

interface Agent {
    agentId?: number;
}

export class ImageAsset extends React.Component<IAssetProps & Agent> {
    private image: React.RefObject<HTMLImageElement> = React.createRef();

    public render() {
        return (
            <img ref={this.image}
                 className={agentsWithPreventResize.includes(Number(this.props.agentId)) && !this.props.thumbnailView ? "prevent-resize" : ""}
                 src={this.props.thumbnailView ? this.props.asset.thumbnail : this.props.asset.url}
                 onLoad={this.onLoad}
                 onError={this.props.onError}/>);
    }

    private onLoad = () => {
        if (this.props.onLoaded) {
            this.props.onLoaded(this.image.current);
        }
        if (this.props.onActivated) {
            this.props.onActivated(this.image.current);
        }
        if (this.props.onDeactivated) {
            this.props.onDeactivated(this.image.current);
        }
    }
}
