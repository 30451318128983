import {Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import {AgentTreeDto, ObjectDto} from "../../common/_dto/EditorTreeDto";
import ColorPicker from 'material-ui-color-picker'
import './EditObjectDialog.scss'

interface CreateObjectDialogProps {
    open: boolean,
    setOpen,
    handleClose,
    agent: AgentTreeDto
}

export const CreateObjectDialog = (props: CreateObjectDialogProps) => {
    const [formState, setFormState] = useState({
        name: '',
        color: '#0036FF'
    } as ObjectDto);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setFormState(formState => ({
            ...formState,
            [event.target.id]: event.target.value
        }));
    };
    const handleSelectChange = (event) => {
        setFormState(formState => ({
            ...formState,
            type: event.target.value
        }));
    };
    return (<Dialog open={props.open} onClose={() => props.setOpen(false)}>
        <div>
            <DialogTitle id="form-dialog-title">Create Object</DialogTitle>
            <DialogContent>
                Paste data and save.
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    value={formState?.name}
                    type="text"
                    onChange={handleChange}
                    fullWidth
                />
                <ColorPicker
                    fullWidth
                    style={{background: formState.color, cursor: "pointer"}}
                    name='color'
                    value={formState.color}
                    onChange={color => {
                        setFormState(prevState => ({
                            ...prevState,
                            color: color
                        }))
                    }
                    }
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setOpen(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => props.handleClose(formState, props.agent.id)} color="primary">
                    Save
                </Button>
            </DialogActions>
        </div>
    </Dialog>)
}
