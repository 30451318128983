import React from "react";
import "./ImageCardRemoveBG.scss"
import Button from "@material-ui/core/Button";
import ConfirmDialog from "../../common/confirmDialog/ConfirmDialog";
import {saveAs} from 'file-saver';
import Dropzone, {FileWithPath} from "react-dropzone";
import {Row} from "react-bootstrap";
import {trackPromise} from "react-promise-tracker";
import {ImageClient} from "../../common/_clients/ImageClient";

export const ImageCardRemoveBG = ({
                                      image,
                                      index,
                                      markAsChecked,
                                      deleteImage
                                  }) => {
    const [openDialog, setOpenDialog] = React.useState(false);
    const [file, setFile] = React.useState<FileWithPath>(undefined)

    function downloadImage() {
        saveAs(image.url, image.uuid + ".jpg");
    }

    function downloadMask() {
        saveAs(image.imageValueDtos[0].url, image.uuid + ".jpg");
    }

    function deletePhoto(photo: FileWithPath) {
        if (!window.confirm("Are you sure you want to delete this photo?"))
            return;
        setFile(undefined)
    }

    function uploadMask(){
        trackPromise(
            ImageClient().uploadMaskAsPng(image.uuid, file).then(() => {
                // If uploaded successfully delete photos
                setFile(undefined)
                markAsChecked(index)
            }).catch(err => {
                alert("An error occurred during uploading!");
                console.log(err);
            }));
    }

    return (<div className="removeBG-card">
        <div className="properties">
            <h6>Uuid: <span>{image.uuid}</span></h6>
            <h6>FileType: {image.fileType}</h6>
            <h6>Checked: {image.checked ? "True" : "False"}</h6>
            {image.properties && <div className="jsonProperties"><h6>Properties:</h6> <pre>{JSON.stringify(image.properties, null, 2)}</pre></div>}
            <Button variant="outlined" color="secondary" onClick={() => markAsChecked(index)}
            >{image.checked ? "Set unchecked" : "Set checked"}</Button>
            <Button variant="outlined" color="secondary" onClick={() => setOpenDialog(true)}
            >Delete</Button>
            <Button variant="outlined" color="secondary" onClick={downloadImage}
            >Download Image</Button>
            {image.imageValueDtos.length > 0 &&
            <Button variant="outlined" color="secondary" onClick={downloadMask}
            >Download Mask</Button>}
            <ConfirmDialog
                title="Delete Image?"
                open={openDialog}
                setOpen={setOpenDialog}
                onConfirm={() => deleteImage(index)}
            >
                Are you sure you want to delete this image?
            </ConfirmDialog>
        </div>
        <div className="image-container">
            <img src={"https://img.vumo.ai/fastResize/image.jpg?path="+image.fullPath+'&height=600'} alt="Car" className="image"/>
            {image.imageValueDtos.length > 0 &&
            <img src={image.imageValueDtos[0].url} alt="Mask" className="image mask"/>}
        </div>
        <div className="upload-container">
            <Dropzone
                onDrop={(photos: FileWithPath[]) => setFile(photos[0])}
                multiple={false}
                accept="image/*"
            >
                {({getRootProps, getInputProps, isDragActive}) => (
                    <div {...getRootProps({className: isDragActive ? "dropzone-active" : "dropzone"})}>
                        <input {...getInputProps()} />
                        {isDragActive ?
                            <p>Drop file here</p> :
                            <p>Drag cropped png, or click to select</p>
                        }
                    </div>
                )}
            </Dropzone>
            <aside>
                <Row>
                    {file && <div key={index} className="photo-container">
                        <Button variant="outlined" color="secondary" onClick={uploadMask}>Upload</Button>
                        <div className="photo-overlay" onClick={() => deletePhoto(file)}>&#10006;</div>
                        <img src={URL.createObjectURL(file)} alt={file.name} className="p-3 photo"/>
                    </div>}
                </Row>
            </aside>
        </div>
    </div>)
}
