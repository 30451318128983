import React, {useEffect} from "react";
import {ImageClient} from "../../common/_clients/ImageClient";
import {ImageData} from "../../common/_dto/ImageData";
import {Checkbox, FormControlLabel, TablePagination, TextField} from "@material-ui/core";
import './RemoveBG.scss'
import {ImageCardRemoveBG} from "./ImageCardRemoveBG";
import {useLoading} from "../../context/LoadingContext";
import Button from "@material-ui/core/Button";

export const RemoveBG = (props) => {
    const [imageData, setImageData] = React.useState<ImageData[] | undefined>()
    const [page, setPage] = React.useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
    const [count, setCount] = React.useState<number>(0);
    const [onlyChecked, setOnlyChecked] = React.useState(false);
    const [agentId, setAgentId] = React.useState(null);
    const {setLoading} = useLoading();
    const [filter, setFilter] = React.useState<string>("{}");


    const agentIdRouter = props.match.params["agentId"]
    useEffect(() => {
        if (agentIdRouter) {
            setAgentId(agentIdRouter)
        }
    }, [agentIdRouter]);

    useEffect(() => {
        if (agentId) {
            getImages();
        }
    }, [rowsPerPage, page, onlyChecked, agentId])
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getImages = async (): Promise<void> => {
        if (filter !== '') {
            deepSearch()
        } else {
            await ImageClient().getImages([agentId], page, rowsPerPage, onlyChecked)
                .then((res) => {
                        const images = res.data.list
                        setImageData(images)
                        setCount(res.data.counter)
                    },
                    (error) => {
                        console.error(error)
                    });
        }
        setLoading(false);
    }
    const markAsChecked = async (indexObj: number) => {
        let image = imageData.filter(x => x.id === indexObj);
        if (image.length > 0) {
            await ImageClient().setImageCheck(image[0].id, !image[0].checked)
                .then((res) => {
                        if (res) {
                            setImageData(imageData.filter(x => x.id !== indexObj))
                        }
                    },
                    (error) => {
                        alert(error.response.data.message)
                    });
        }

    }
    const deleteImage = async (indexObj: number) => {
        let image = imageData.filter(x => x.id === indexObj);
        if (image.length > 0) {
            await ImageClient().deleteImage(image[0].id)
                .then((res) => {
                        if (res) {
                            setImageData(imageData.filter(x => x.id !== indexObj))
                        }
                    },
                    (error) => {
                        console.error(error)
                    });
        }
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOnlyChecked(event.target.checked);
    };

    const onFilterKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.value)
    }


    const isValidFilter = () => {
        return isJsonString(filter);
    }

    function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            alert("Invalid JSON filter")
            return false;
        }
        return true;
    }

    async function deepSearch() {
        await ImageClient().getImagesWithFilter([agentId], page, rowsPerPage, onlyChecked, filter)
            .then((res) => {
                    const images = res.data.list
                    setImageData(images)
                    setCount(res.data.counter)
                },
                (error) => {
                    console.error(error)
                });
    }

    return (
        <div className="wrapper">
            <div className="configuration-list">
                <h3>Configuration:</h3>
                <FormControlLabel
                    control={<Checkbox
                        checked={onlyChecked}
                        onChange={handleChange}
                        inputProps={{'aria-label': 'primary checkbox'}}
                    />}
                    label="Checked"
                />
                <div>
                    <h5>Filter:</h5>
                    <TextField id="filled-basic" label="Key" onChange={onFilterKeyChange} value={filter} multiline/>
                    <Button variant="outlined" color="secondary" onClick={getImages}
                    >Deep search</Button>
                </div>
            </div>
            <div className="content-list">
                <TablePagination
                    className="paginationBar"
                    component="div"
                    count={count}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <div className="imageList">
                    {imageData &&
                        imageData.map((image, index) =>
                            <ImageCardRemoveBG
                                key={image.id}
                                image={image}
                                index={image.id}
                                markAsChecked={markAsChecked}
                                deleteImage={deleteImage}
                            />)
                    }
                </div>
            </div>
        </div>
    );
}
